export const constants = {
  rowsPerPage: 1000,
  download: {
    columns: {
      headers: {
        department: {
          displayName: 'Department',
        },
        class: {
          displayName: 'Class',
        },
        subClass: {
          displayName: 'Sub Class',
        },
        pullByDate: {
          displayName: 'Pull By Date (days)',
        },
        markdownDays: {
          displayName: 'Bevmo Markdown (days)',
        },
        captureExpiry: {
          displayName: 'Capture Expiry',
        },
        acceptableShelfLife: {
          displayName: 'Acceptable Shelf Life (ASL)',
        },
        packingConstraint: {
          displayName: 'Packing Constraint',
        },
      },
    },
  },
};
