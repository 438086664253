import { ItemExtraSubClassDefaultRow, ItemSubClassRow, MappedRow } from '../types';

export const mapDataToRows = (
  itemExtraSubClassDefaults: ItemExtraSubClassDefaultRow[],
  itemSubClasses: ItemSubClassRow[],
): MappedRow[] => {
  if (!itemExtraSubClassDefaults.length || !itemSubClasses.length) return [];

  const itemSubClassesByName: Record<string, ItemSubClassRow> = itemSubClasses.reduce(
    (acc, itemSubClass) => {
      acc[itemSubClass.name] = itemSubClass;
      return acc;
    },
    {} as Record<string, ItemSubClassRow>,
  );
  const mappedRows = itemExtraSubClassDefaults.map(itemExtraSubClassDefault => {
    const mappedData: MappedRow = {
      id: itemExtraSubClassDefault.id,
      subClass: itemExtraSubClassDefault.subClass,
      captureExpiry: itemExtraSubClassDefault.captureExpiry,
      markdownDays: itemExtraSubClassDefault.markdownDays,
      pullByDate: itemExtraSubClassDefault.pullByDate,
      acceptableShelfLife: itemExtraSubClassDefault.acceptableShelfLife,
      packingConstraint: itemExtraSubClassDefault.packingConstraint,
      class: itemSubClassesByName[itemExtraSubClassDefault.subClass]?.itemClass?.name ?? '',
      department:
        itemSubClassesByName[itemExtraSubClassDefault.subClass]?.itemClass?.itemDepartment?.name ??
        '',
    };

    return mappedData;
  });

  mappedRows.sort((a, b) => {
    if (a.department < b.department) return -1;
    if (a.department > b.department) return 1;
    if (a.class < b.class) return -1;
    if (a.class > b.class) return 1;
    if (a.subClass < b.subClass) return -1;
    if (a.subClass > b.subClass) return 1;
    return 0;
  });

  return mappedRows;
};
