import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  NonNegativeFloat: any;
  Upload: any;
};

export type AlcoholType = {
  __typename?: 'AlcoholType';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
  volumes: Array<AlcoholVolume>;
};

export type AlcoholTypeInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  volumes: Array<AlcoholVolumeInput>;
};

export type AlcoholTypeResult = {
  __typename?: 'AlcoholTypeResult';
  types: Maybe<Array<AlcoholType>>;
};

export type AlcoholTypesInput = {
  types: Array<AlcoholTypeInput>;
};

export type AlcoholVolume = {
  __typename?: 'AlcoholVolume';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  volume: Scalars['Float'];
};

export type AlcoholVolumeInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  volume: Scalars['Float'];
};

export type AllocationPrioritiesInput = {
  priorities: Array<AllocationPriorityInput>;
};

export type AllocationPriority = {
  __typename?: 'AllocationPriority';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AllocationPriorityInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type AllocationPriorityResult = {
  __typename?: 'AllocationPriorityResult';
  priorities: Maybe<Array<AllocationPriority>>;
};

export enum AssortmentOverrideState {
  Active = 'Active',
  Inactive = 'Inactive',
  Primary = 'Primary',
}

export type BomItem = {
  __typename?: 'BomItem';
  id: Scalars['ID'];
  item: Item;
  itemsInBomQty: Maybe<Scalars['Int']>;
};

/** Exports the Go runtime/debug.BuildInfo for testing and confirming build information */
export type BuildInfo = {
  __typename?: 'BuildInfo';
  deps: Array<Module>;
  goVersion: Scalars['String'];
  main: Module;
  path: Scalars['String'];
  settings: Array<BuildSetting>;
};

/** A Go build setting */
export type BuildSetting = {
  __typename?: 'BuildSetting';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Buyer = {
  __typename?: 'Buyer';
  email: Scalars['String'];
};

export type CapabilitiesInput = {
  capabilities: Array<CapabilityInput>;
};

export type Capability = {
  __typename?: 'Capability';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type CapabilityInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CapabilityResult = {
  __typename?: 'CapabilityResult';
  capabilities: Maybe<Array<Capability>>;
};

export enum CaptureExpiryMethod {
  AutoAssignGsl = 'AutoAssignGSL',
  Manual = 'Manual',
  No = 'No',
}

export type ContainerCapacity = {
  __typename?: 'ContainerCapacity';
  containerMax: Maybe<Scalars['Int']>;
  containerMin: Maybe<Scalars['Int']>;
  containerUom: ContainerUom;
  id: Scalars['ID'];
};

export type ContainerCapacityInput = {
  containerMax?: Maybe<Scalars['Int']>;
  containerMin?: Maybe<Scalars['Int']>;
  containerUom: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
};

export type ContainerUom = {
  __typename?: 'ContainerUom';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type Cost = {
  __typename?: 'Cost';
  currencyId: Scalars['ID'];
  packageTypeID: Scalars['ID'];
  value: Scalars['Float'];
};

export type CostAutoApproveFilter = {
  costManagementIds?: Maybe<Array<Scalars['ID']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type CostAutoApproveRule = {
  __typename?: 'CostAutoApproveRule';
  class: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['Int']>;
  createdBy: Maybe<Scalars['String']>;
  decreaseValue: Maybe<Scalars['NonNegativeFloat']>;
  department: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  increaseValue: Maybe<Scalars['NonNegativeFloat']>;
  isActive: Maybe<Scalars['Boolean']>;
  isDeleted: Maybe<Scalars['Boolean']>;
  item: Maybe<Item>;
  periodType: Maybe<PeriodType>;
  supplier: Maybe<Supplier>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type CostAutoApproveRuleFilter = {
  classes?: Maybe<Array<Scalars['String']>>;
  departments?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemIds?: Maybe<Array<Scalars['ID']>>;
  periodTypeIds?: Maybe<Array<Scalars['ID']>>;
  supplierIds?: Maybe<Array<Scalars['ID']>>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CostAutoApproveRuleInput = {
  class?: Maybe<Scalars['String']>;
  decreaseValue?: Maybe<Scalars['NonNegativeFloat']>;
  department?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  increaseValue?: Maybe<Scalars['NonNegativeFloat']>;
  isActive?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['ID']>;
  periodTypeId: Scalars['ID'];
  supplierId: Scalars['ID'];
};

export type CostAutoApproveRuleResult = {
  __typename?: 'CostAutoApproveRuleResult';
  records: Maybe<Array<CostAutoApproveRule>>;
  totalCount: Scalars['Int'];
};

export type CostDate = {
  __typename?: 'CostDate';
  canAutoApprove: Scalars['Boolean'];
  commentedBy: Maybe<Scalars['String']>;
  commentedByDisplayName: Maybe<Scalars['String']>;
  comments: Maybe<Scalars['String']>;
  costManagementId: Scalars['ID'];
  costPackagings: Maybe<Array<CostPackaging>>;
  currency: Maybe<Currency>;
  effectiveDate: Scalars['Int'];
  endDate: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  status: CostDateStatus;
  type: CostDateType;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
  updatedByDisplayName: Maybe<Scalars['String']>;
};

export enum CostDateApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type CostDateApprovalStatusInput = {
  approvalStatus: CostDateApprovalStatus;
  comments?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

export type CostDateFilter = {
  canAutoApprove?: Maybe<Scalars['Boolean']>;
  costManagementIds?: Maybe<Array<Scalars['ID']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<CostDateStatusFilter>;
  types?: Maybe<Array<CostDateType>>;
};

export type CostDateInput = {
  costManagementId: Scalars['ID'];
  costPackagings?: Maybe<Array<CostPackagingInput>>;
  currencyId?: Maybe<Scalars['ID']>;
  effectiveDate: Scalars['Int'];
  endDate?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<CostDateType>;
};

export type CostDateResult = {
  __typename?: 'CostDateResult';
  records: Maybe<Array<CostDate>>;
  totalCount: Scalars['Int'];
};

export enum CostDateStatus {
  Current = 'CURRENT',
  Draft = 'DRAFT',
  Empty = 'EMPTY',
  Future = 'FUTURE',
  Past = 'PAST',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Undefined = 'UNDEFINED',
}

export enum CostDateStatusFilter {
  Past = 'PAST',
  Pending = 'PENDING',
}

export enum CostDateType {
  Empty = 'EMPTY',
  Promo = 'PROMO',
  Regular = 'REGULAR',
}

export type CostDownloadResult = {
  __typename?: 'CostDownloadResult';
  file: File;
};

export type CostManagement = {
  __typename?: 'CostManagement';
  costDates: Maybe<Array<CostDate>>;
  id: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  item: Item;
  itemSources: Maybe<Array<ItemSource>>;
  location: Maybe<Location>;
  opco: Maybe<Opco>;
  supplier: Supplier;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type CostManagementFileRow = {
  __typename?: 'CostManagementFileRow';
  canAutoApprove: Maybe<Scalars['String']>;
  caseCost: Maybe<Scalars['String']>;
  caseCostValidation: Maybe<Scalars['String']>;
  caseCount: Maybe<Scalars['String']>;
  caseUpc: Maybe<Scalars['String']>;
  caseVin: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  eachCost: Maybe<Scalars['String']>;
  eachCostValidation: Maybe<Scalars['String']>;
  eachUpc: Maybe<Scalars['String']>;
  eachVin: Maybe<Scalars['String']>;
  effectiveDate: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['String']>;
  error: Maybe<Scalars['String']>;
  isDisabled: Maybe<Scalars['String']>;
  itemClass: Maybe<Scalars['String']>;
  itemDepartment: Maybe<Scalars['String']>;
  itemExtID: Maybe<Scalars['String']>;
  itemName: Maybe<Scalars['String']>;
  locationAddress: Maybe<Scalars['String']>;
  locationCity: Maybe<Scalars['String']>;
  locationID: Maybe<Scalars['String']>;
  locationName: Maybe<Scalars['String']>;
  locationPostalCode: Maybe<Scalars['String']>;
  locationState: Maybe<Scalars['String']>;
  notes: Maybe<Scalars['String']>;
  opcoID: Maybe<Scalars['String']>;
  opcoName: Maybe<Scalars['String']>;
  opcoState: Maybe<Scalars['String']>;
  parentCompany: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  supplierExtID: Maybe<Scalars['String']>;
  supplierName: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type CostManagementFilter = {
  canAutoApprove?: Maybe<Scalars['Boolean']>;
  caseUPCs?: Maybe<Array<Scalars['String']>>;
  caseVINs?: Maybe<Array<Scalars['String']>>;
  class?: Maybe<Scalars['String']>;
  costDateStatuses?: Maybe<Array<CostDateStatus>>;
  costDateTypes?: Maybe<Array<CostDateType>>;
  country?: Maybe<Scalars['String']>;
  currencyIDs?: Maybe<Array<Scalars['ID']>>;
  eachUPCs?: Maybe<Array<Scalars['String']>>;
  eachVINs?: Maybe<Array<Scalars['String']>>;
  effectiveDate?: Maybe<TimeRangeFilter>;
  id?: Maybe<Array<Scalars['ID']>>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  itemClass?: Maybe<Scalars['String']>;
  itemDepartment?: Maybe<Scalars['String']>;
  itemExtIDs?: Maybe<Array<Scalars['String']>>;
  itemName?: Maybe<Scalars['String']>;
  itemSourceIDs?: Maybe<Array<Scalars['ID']>>;
  locationExtIDs?: Maybe<Array<Scalars['String']>>;
  locationName?: Maybe<Scalars['String']>;
  opcoExtIDs?: Maybe<Array<Scalars['String']>>;
  opcoIDs?: Maybe<Array<Scalars['ID']>>;
  opcoName?: Maybe<Scalars['String']>;
  opcoStateProvinceRegion?: Maybe<Scalars['String']>;
  parentCompany?: Maybe<Scalars['String']>;
  supplierAssortment?: Maybe<SupplierAssortmentCostFilter>;
  supplierExtIDs?: Maybe<Array<Scalars['String']>>;
  supplierName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CostManagementInput = {
  isDisabled: Scalars['Boolean'];
  itemId: Scalars['ID'];
  locationId?: Maybe<Scalars['ID']>;
  opcoId?: Maybe<Scalars['ID']>;
  supplierId: Scalars['ID'];
};

export type CostManagementMassEditInput = {
  remove?: Maybe<Array<Scalars['ID']>>;
  upsert?: Maybe<Array<CostManagementInput>>;
};

export type CostManagementResult = {
  __typename?: 'CostManagementResult';
  records: Maybe<Array<CostManagement>>;
  totalCount: Scalars['Int'];
};

export type CostManagementUploadInput = {
  file: Scalars['Upload'];
};

export type CostManagementUploadResult = {
  __typename?: 'CostManagementUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  rows: Maybe<Array<CostManagementFileRow>>;
  skips: Scalars['Int'];
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type CostPackaging = {
  __typename?: 'CostPackaging';
  costDateId: Scalars['ID'];
  packageTypeId: Scalars['ID'];
  percentageChange: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
};

export type CostPackagingInput = {
  costDateId?: Maybe<Scalars['ID']>;
  packageTypeId: Scalars['Int'];
  value: Scalars['Float'];
};

export type Currency = {
  __typename?: 'Currency';
  code: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type CustomAssortment = {
  __typename?: 'CustomAssortment';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type CustomAssortmentInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CustomAssortmentResult = {
  __typename?: 'CustomAssortmentResult';
  assortments: Maybe<Array<CustomAssortment>>;
};

export type CustomAssortmentsInput = {
  assortments: Array<CustomAssortmentInput>;
};

export type Deal = {
  __typename?: 'Deal';
  approvedAt: Maybe<Scalars['Int']>;
  approvedBy: Maybe<User>;
  country: Maybe<Scalars['String']>;
  createdAt: Scalars['Int'];
  createdBy: User;
  currency: Maybe<Currency>;
  description: Maybe<Scalars['String']>;
  endTime: Scalars['Int'];
  endTimeZone: Scalars['String'];
  id: Scalars['ID'];
  lines: Array<DealLine>;
  locationGroups: Maybe<Array<LocationGroup>>;
  locations: Maybe<Array<Location>>;
  note: Maybe<Scalars['String']>;
  notedBy: Maybe<User>;
  offerNumber: Scalars['String'];
  parentCompany: ParentCompany;
  pricingChannels: Maybe<Array<PricingChannel>>;
  rescindStatus: Maybe<DealRescindStatus>;
  startTime: Scalars['Int'];
  startTimeZone: Scalars['String'];
  status: DealStatus;
  type: DealType;
  updatedAt: Scalars['Int'];
  updatedBy: User;
};

export enum DealApprovalStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Rescinded = 'RESCINDED',
}

export type DealApprovalStatusInput = {
  approvalStatus: DealApprovalStatus;
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type DealFilter = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type DealLine = {
  __typename?: 'DealLine';
  id: Scalars['ID'];
  item: Item;
  scanDeal: Maybe<DealLineScanDeal>;
};

export type DealLineInput = {
  dealId?: Maybe<Scalars['ID']>;
  eachUpc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  scanDeal?: Maybe<DealLineScanDealInput>;
};

export type DealLineResult = {
  __typename?: 'DealLineResult';
  records: Array<DealLine>;
};

export type DealLineScanDeal = {
  __typename?: 'DealLineScanDeal';
  gopuffDollarFundingPerUnit: Maybe<Scalars['Float']>;
  gopuffPercentageFundingPerUnit: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  vendorFundingPerUnit: Scalars['Float'];
};

export type DealLineScanDealInput = {
  gopuffDollarFundingPerUnit?: Maybe<Scalars['Float']>;
  gopuffPercentageFundingPerUnit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  vendorFundingPerUnit?: Maybe<Scalars['Float']>;
};

export type DealLineScanDealResult = {
  __typename?: 'DealLineScanDealResult';
  gopuffDollarFundingPerUnit: Maybe<Scalars['Float']>;
  gopuffPercentageFundingPerUnit: Maybe<Scalars['Float']>;
};

export type DealLocation = {
  __typename?: 'DealLocation';
  dealId: Scalars['ID'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type DealLocationGroup = {
  __typename?: 'DealLocationGroup';
  dealId: Scalars['ID'];
  id: Scalars['ID'];
  locationGroupId: Scalars['ID'];
};

export type DealLocationGroupInput = {
  dealId: Scalars['ID'];
  locationGroupId: Scalars['ID'];
};

export type DealLocationGroupResult = {
  __typename?: 'DealLocationGroupResult';
  records: Array<DealLocationGroup>;
};

export type DealLocationInput = {
  dealId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type DealLocationResult = {
  __typename?: 'DealLocationResult';
  records: Array<DealLocation>;
};

export enum DealRescindStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type DealResult = {
  __typename?: 'DealResult';
  records: Array<Deal>;
  totalCount: Scalars['Int'];
};

export enum DealStatus {
  Current = 'CURRENT',
  Future = 'FUTURE',
  Invalid = 'INVALID',
  Past = 'PAST',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Rescinded = 'RESCINDED',
  RescindPending = 'RESCIND_PENDING',
}

export type DealType = {
  __typename?: 'DealType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DealUploadHeader = {
  __typename?: 'DealUploadHeader';
  currency: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['String']>;
  endTimeZone: Maybe<Scalars['String']>;
  error: Scalars['String'];
  locations: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['String']>;
  startTimeZone: Maybe<Scalars['String']>;
  supplierNumber: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type DealUploadInput = {
  file: Scalars['Upload'];
};

export type DealUploadLine = {
  __typename?: 'DealUploadLine';
  eachUpc: Maybe<Scalars['String']>;
  error: Scalars['String'];
  scanDeal: Maybe<DealUploadLineScanDeal>;
};

export type DealUploadLineScanDeal = {
  __typename?: 'DealUploadLineScanDeal';
  vendorFundingPerUnit: Maybe<Scalars['String']>;
};

export type DealUploadResult = {
  __typename?: 'DealUploadResult';
  header: DealUploadHeader;
  lines: Array<DealUploadLine>;
  summary: UploadSummary;
};

export type DestinationAssortment = {
  __typename?: 'DestinationAssortment';
  assortmentOverrideState: Maybe<AssortmentOverrideState>;
  costs: Maybe<Array<Maybe<Cost>>>;
  id: Scalars['ID'];
  item: Maybe<Item>;
  itemOpcoID: Scalars['ID'];
  localCost: Maybe<Scalars['Float']>;
  localCostCurrency: Maybe<Currency>;
  location: Maybe<Location>;
  managedBy: Maybe<User>;
  maxAllocationQuantity: Maybe<Scalars['Int']>;
  minAllocationQuantity: Maybe<Scalars['Int']>;
  nationalCost: Maybe<Scalars['Float']>;
  nationalCostCurrency: Maybe<Currency>;
  opco: Maybe<Opco>;
  packaging: Maybe<Array<Packaging>>;
  selectedPackagingID: Maybe<Scalars['ID']>;
  source: Maybe<Supplier>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
  velocityCategory: Maybe<VelocityCategory>;
};

export type DestinationAssortmentFilter = {
  assorOverrideState?: Maybe<Array<AssortmentOverrideState>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemExtIds?: Maybe<Array<Scalars['String']>>;
  itemIds?: Maybe<Array<Scalars['ID']>>;
  itemName?: Maybe<Scalars['String']>;
  locCostCurrencyIds?: Maybe<Array<Scalars['ID']>>;
  localCost?: Maybe<Scalars['Float']>;
  locationExtIds?: Maybe<Array<Scalars['String']>>;
  locationIds?: Maybe<Array<Scalars['ID']>>;
  locationName?: Maybe<Scalars['String']>;
  managedByIds?: Maybe<Array<Scalars['String']>>;
  natCostCurrencyIds?: Maybe<Array<Scalars['ID']>>;
  nationalCost?: Maybe<Scalars['Float']>;
  opcoIds?: Maybe<Array<Scalars['ID']>>;
  opcoName?: Maybe<Scalars['String']>;
  opcoStateProvinceRegion?: Maybe<Scalars['String']>;
  pkgTypeIds?: Maybe<Array<Scalars['ID']>>;
  sourceIds?: Maybe<Array<Scalars['ID']>>;
  sourceName?: Maybe<Scalars['String']>;
  supplierExtIds?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
  velocityCategoryIds?: Maybe<Array<Scalars['ID']>>;
};

export type DestinationAssortmentInput = {
  assortmentOverrideState?: Maybe<AssortmentOverrideState>;
  id: Scalars['ID'];
  localCost?: Maybe<Scalars['Float']>;
  localCostCurrency?: Maybe<Scalars['ID']>;
  maxAllocationQuantity?: Maybe<Scalars['Int']>;
  minAllocationQuantity?: Maybe<Scalars['Int']>;
  nationalCost?: Maybe<Scalars['Float']>;
  nationalCostCurrency?: Maybe<Scalars['ID']>;
  packagingID?: Maybe<Scalars['ID']>;
  velocityCategory?: Maybe<Scalars['ID']>;
};

export type DestinationAssortmentResult = {
  __typename?: 'DestinationAssortmentResult';
  assortments: Maybe<Array<DestinationAssortment>>;
  totalCount: Scalars['Int'];
};

export type DestinationAssortmentSort = {
  field: DestinationAssortmentSortField;
  order: SortOrder;
};

export enum DestinationAssortmentSortField {
  Item = 'ITEM',
  Location = 'LOCATION',
}

export type DestinationAssortmentsDownloadFilter = {
  IDs?: Maybe<Array<Scalars['ID']>>;
};

export type DestinationAssortmentsDownloadResult = {
  __typename?: 'DestinationAssortmentsDownloadResult';
  file: File;
};

export type DestinationAssortmentsInput = {
  assortments?: Maybe<Array<DestinationAssortmentInput>>;
};

export type DestinationAssortmentsUploadInput = {
  file: Scalars['Upload'];
};

export type DestinationAssortmentsUploadResult = {
  __typename?: 'DestinationAssortmentsUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  content: Scalars['String'];
  name: Scalars['String'];
};

export type FullOpco = {
  __typename?: 'FullOpco';
  address: Maybe<Scalars['String']>;
  buyer: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  ext_id: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  packageTypeId: Maybe<Scalars['ID']>;
  postalCode: Maybe<Scalars['String']>;
  sendToTraverse: Scalars['Boolean'];
  stateProvinceRegion: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type FullOpcoResult = {
  __typename?: 'FullOpcoResult';
  opcos: Maybe<Array<FullOpco>>;
  totalCount: Scalars['Int'];
};

export type GraphJinCustomAssortment = {
  __typename?: 'GraphJinCustomAssortment';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type GraphJinCustomAssortmentFilter = {
  descriptionLike?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
};

export type GraphJinCustomAssortmentInsertInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GraphJinItemClass = {
  __typename?: 'GraphJinItemClass';
  itemDepartment: Maybe<GraphJinItemDepartment>;
  name: Scalars['String'];
};

export type GraphJinItemDepartment = {
  __typename?: 'GraphJinItemDepartment';
  name: Scalars['String'];
};

export type GraphJinItemExtraSubClassDefault = {
  __typename?: 'GraphJinItemExtraSubClassDefault';
  acceptableShelfLife: Maybe<Scalars['Int']>;
  captureExpiry: Scalars['Boolean'];
  id: Scalars['ID'];
  markdownDays: Maybe<Scalars['Int']>;
  packingConstraint: Scalars['String'];
  pullByDate: Maybe<Scalars['Int']>;
  subClass: Scalars['String'];
};

export type GraphJinItemExtraSubClassDefaultFilter = {
  subClass?: Maybe<Scalars['String']>;
};

export type GraphJinItemExtraSubClassDefaultInsertInput = {
  acceptableShelfLife?: Maybe<Scalars['Int']>;
  captureExpiry: Scalars['Boolean'];
  markdownDays?: Maybe<Scalars['Int']>;
  packingConstraint: Scalars['String'];
  pullByDate?: Maybe<Scalars['Int']>;
};

export type GraphJinItemSubClass = {
  __typename?: 'GraphJinItemSubClass';
  itemClass: Maybe<GraphJinItemClass>;
  name: Scalars['String'];
};

export type GraphJinItemSubClassFilter = {
  name?: Maybe<Scalars['String']>;
};

export type GraphJinLeadTimeOverride = {
  __typename?: 'GraphJinLeadTimeOverride';
  createdAt: Scalars['Int'];
  createdBy: Scalars['String'];
  friLeadTime: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  monLeadTime: Maybe<Scalars['Int']>;
  satLeadTime: Maybe<Scalars['Int']>;
  sunLeadTime: Maybe<Scalars['Int']>;
  thuLeadTime: Maybe<Scalars['Int']>;
  transportationLaneId: Scalars['ID'];
  tueLeadTime: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Int'];
  updatedBy: Scalars['String'];
  wedLeadTime: Maybe<Scalars['Int']>;
  weekStartDate: Scalars['String'];
};

export type GraphJinLeadTimeOverrideFilter = {
  transportationLaneId?: Maybe<Array<Scalars['ID']>>;
};

export type GraphJinLeadTimeOverrideInsertInput = {
  friLeadTime?: Maybe<Scalars['Int']>;
  monLeadTime?: Maybe<Scalars['Int']>;
  satLeadTime?: Maybe<Scalars['Int']>;
  sunLeadTime?: Maybe<Scalars['Int']>;
  thuLeadTime?: Maybe<Scalars['Int']>;
  transportationLaneId: Scalars['ID'];
  tueLeadTime?: Maybe<Scalars['Int']>;
  wedLeadTime?: Maybe<Scalars['Int']>;
  weekStartDate: Scalars['String'];
};

export type I18n = {
  __typename?: 'I18n';
  deDE: Maybe<Scalars['String']>;
  enCA: Maybe<Scalars['String']>;
  enGB: Maybe<Scalars['String']>;
  enUS: Maybe<Scalars['String']>;
  esES: Maybe<Scalars['String']>;
  frCA: Maybe<Scalars['String']>;
  frFR: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  acceptableShelfLife: Maybe<Scalars['Int']>;
  alcoholByVolume: Maybe<Scalars['Float']>;
  alcoholType: Maybe<AlcoholType>;
  baggableAtPack: Maybe<Scalars['Boolean']>;
  binnableAtPack: Maybe<Scalars['Boolean']>;
  brand: Maybe<Scalars['String']>;
  capabilities: Maybe<Array<Capability>>;
  captureExpiry: Maybe<Scalars['Boolean']>;
  captureExpiryMethod: Maybe<CaptureExpiryMethod>;
  /** @deprecated use subClass and productType instead of category. */
  category: Maybe<Scalars['String']>;
  class: Maybe<Scalars['String']>;
  customAssortments: Maybe<Array<CustomAssortment>>;
  department: Maybe<Scalars['String']>;
  extId: Scalars['String'];
  family: Maybe<Scalars['String']>;
  guaranteedShelfLife: Maybe<Scalars['Int']>;
  i18n: Maybe<ItemI18n>;
  id: Scalars['ID'];
  ignoreBlockOverReceipt: Maybe<Scalars['Boolean']>;
  iihCategoryName: Maybe<Scalars['String']>;
  isAlcohol: Maybe<Scalars['Boolean']>;
  isBom: Maybe<Scalars['Boolean']>;
  isConfirmed: Maybe<Scalars['Boolean']>;
  isDiscontinued: Maybe<Scalars['Boolean']>;
  isTobacco: Maybe<Scalars['Boolean']>;
  itemCapabilityUpdatedAt: Maybe<Scalars['Int']>;
  itemCapabilityUpdatedBy: Maybe<Scalars['String']>;
  itemCustomAssortmentUpdatedAt: Maybe<Scalars['Int']>;
  itemCustomAssortmentUpdatedBy: Maybe<Scalars['String']>;
  manufacturingType: Maybe<ManufacturingType>;
  markdownDays: Maybe<Scalars['Int']>;
  markdownItem: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  nationalAssortment: Maybe<NationalAssortment>;
  oracleSyncStatus: Maybe<Array<Scalars['String']>>;
  packingConstraint: Maybe<PackingConstraintType>;
  parentCompany: Maybe<Scalars['String']>;
  percentageOverageAllowed: Maybe<Scalars['Int']>;
  productType: Maybe<Scalars['String']>;
  pullByDate: Maybe<Scalars['Int']>;
  requireProduceBag: Maybe<Scalars['Boolean']>;
  sellableTemperature: Maybe<TemperatureType>;
  stopBuyingFlag: Maybe<Scalars['Boolean']>;
  storageTemperature: Maybe<TemperatureType>;
  subClass: Maybe<Scalars['String']>;
  thawExpiryDays: Maybe<Scalars['Int']>;
  thawReplenishmentMaxQty: Maybe<Scalars['Int']>;
  thawReplenishmentMinQty: Maybe<Scalars['Int']>;
  thawTimeHours: Maybe<Scalars['Int']>;
  thawToSellEnabled: Scalars['Boolean'];
  tobaccoType: Maybe<TobaccoType>;
  upc: Maybe<Array<Scalars['String']>>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
  usCategoryName: Maybe<Scalars['String']>;
};

export type ItemDownloadFilter = {
  IDs?: Maybe<Array<Scalars['ID']>>;
};

export type ItemDownloadResult = {
  __typename?: 'ItemDownloadResult';
  file: File;
};

export type ItemFilter = {
  acceptableShelfLife?: Maybe<Scalars['Int']>;
  alcoholABV?: Maybe<Scalars['Float']>;
  alcoholTypeIds?: Maybe<Array<Scalars['ID']>>;
  baggableAtPack?: Maybe<Scalars['Boolean']>;
  binnableAtPack?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  capabilityIds?: Maybe<Array<Scalars['ID']>>;
  captureExpiry?: Maybe<Scalars['Boolean']>;
  captureExpiryMethod?: Maybe<CaptureExpiryMethod>;
  category?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  customAssortmentIds?: Maybe<Array<Scalars['ID']>>;
  department?: Maybe<Scalars['String']>;
  eachUPCs?: Maybe<Array<Scalars['String']>>;
  extIds?: Maybe<Array<Scalars['String']>>;
  guaranteedShelfLife?: Maybe<Scalars['Int']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  ignoreBlockOverReceipt?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
  isBom?: Maybe<Scalars['Boolean']>;
  isTobacco?: Maybe<Scalars['Boolean']>;
  languageCode?: Maybe<Scalars['String']>;
  manufacturingTypeId?: Maybe<Scalars['Int']>;
  markdownDays?: Maybe<Scalars['Int']>;
  markdownItem?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nationalAssortmentIds?: Maybe<Array<Scalars['ID']>>;
  packingConstraint?: Maybe<Scalars['String']>;
  parentCompany?: Maybe<Scalars['String']>;
  percentageOverageAllowed?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
  pullByDate?: Maybe<Scalars['Int']>;
  requireProduceBag?: Maybe<Scalars['Boolean']>;
  stopBuyingFlag?: Maybe<Scalars['Boolean']>;
  subClass?: Maybe<Scalars['String']>;
  thawToSellEnabled?: Maybe<Scalars['Boolean']>;
  tobaccoTypeIds?: Maybe<Array<Scalars['ID']>>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type ItemI18n = {
  __typename?: 'ItemI18n';
  name: Maybe<I18n>;
};

export type ItemInput = {
  acceptableShelfLife?: Maybe<Scalars['Int']>;
  alcoholByVolume?: Maybe<Scalars['Float']>;
  alcoholType?: Maybe<Scalars['ID']>;
  baggableAtPack?: Maybe<Scalars['Boolean']>;
  binnableAtPack?: Maybe<Scalars['Boolean']>;
  capabilities?: Maybe<Array<Scalars['ID']>>;
  captureExpiryMethod?: Maybe<CaptureExpiryMethod>;
  customAssortments?: Maybe<Array<Scalars['ID']>>;
  guaranteedShelfLife?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  ignoreBlockOverReceipt?: Maybe<Scalars['Boolean']>;
  manufacturingType?: Maybe<Scalars['ID']>;
  markdownDays?: Maybe<Scalars['Int']>;
  markdownItem?: Maybe<Scalars['Boolean']>;
  nationalAssortment?: Maybe<Scalars['ID']>;
  packingConstraint?: Maybe<PackingConstraintType>;
  percentageOverageAllowed?: Maybe<Scalars['Int']>;
  pullByDate?: Maybe<Scalars['Int']>;
  requireProduceBag?: Maybe<Scalars['Boolean']>;
  sellableTemperature?: Maybe<Scalars['String']>;
  stopBuyingFlag?: Maybe<Scalars['Boolean']>;
  storageTemperature?: Maybe<Scalars['String']>;
  thawExpiryDays?: Maybe<Scalars['Int']>;
  thawReplenishmentMaxQty?: Maybe<Scalars['Int']>;
  thawReplenishmentMinQty?: Maybe<Scalars['Int']>;
  thawTimeHours?: Maybe<Scalars['Int']>;
  thawToSellEnabled?: Maybe<Scalars['Boolean']>;
  tobaccoType?: Maybe<Scalars['ID']>;
};

export type ItemResult = {
  __typename?: 'ItemResult';
  items: Maybe<Array<Item>>;
  totalCount: Scalars['Int'];
};

export type ItemSource = {
  __typename?: 'ItemSource';
  breakingFromPackageTypeID: Maybe<Scalars['ID']>;
  breakingThreshold: Maybe<Scalars['Float']>;
  breakingToPackageTypeID: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isBreakable: Maybe<Scalars['Boolean']>;
  isLotSizeEnabled: Maybe<Scalars['Boolean']>;
  item: Item;
  lotSizeIncrement: Maybe<Scalars['Int']>;
  lotSizeMin: Maybe<Scalars['Int']>;
  opco: Opco;
  packaging: Maybe<Array<Packaging>>;
  /** @deprecated No longer supported */
  source: Maybe<Supplier>;
};

export type ItemSourceDownloadFilter = {
  IDs?: Maybe<Array<Scalars['ID']>>;
};

export type ItemSourceDownloadResult = {
  __typename?: 'ItemSourceDownloadResult';
  file: File;
};

export type ItemSourceFilter = {
  UPCs?: Maybe<Array<Scalars['String']>>;
  itemExtIds?: Maybe<Array<Scalars['String']>>;
  itemIDs?: Maybe<Array<Scalars['ID']>>;
  itemName?: Maybe<Scalars['String']>;
  itemOpcoIDs?: Maybe<Array<Scalars['ID']>>;
  opcoExtIDs?: Maybe<Array<Scalars['String']>>;
  opcoStateProvinceRegion?: Maybe<Scalars['String']>;
  sourceName?: Maybe<Scalars['String']>;
  supplierItemNumbers?: Maybe<Array<Scalars['String']>>;
  supplierNumbers?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type ItemSourceInput = {
  breakingFromPackageTypeID?: Maybe<Scalars['ID']>;
  breakingThreshold?: Maybe<Scalars['Float']>;
  breakingToPackageTypeID?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isBreakable: Scalars['Boolean'];
  isLotSizeEnabled?: Maybe<Scalars['Boolean']>;
  itemID: Scalars['ID'];
  lotSizeIncrement?: Maybe<Scalars['Int']>;
  lotSizeMin?: Maybe<Scalars['Int']>;
  opcoID: Scalars['ID'];
  packaging: Array<ItemSourcePackagingInput>;
};

export type ItemSourcePackagingInput = {
  declaredHeight?: Maybe<Scalars['Float']>;
  declaredHi?: Maybe<Scalars['Int']>;
  declaredLength?: Maybe<Scalars['Float']>;
  declaredLengthUnits?: Maybe<Scalars['String']>;
  declaredTi?: Maybe<Scalars['Int']>;
  declaredWeight?: Maybe<Scalars['Float']>;
  declaredWeightUnits?: Maybe<Scalars['String']>;
  declaredWidth?: Maybe<Scalars['Float']>;
  maxAllocationQuantity?: Maybe<Scalars['Int']>;
  measuredHeight?: Maybe<Scalars['Float']>;
  measuredHi?: Maybe<Scalars['Int']>;
  measuredLength?: Maybe<Scalars['Float']>;
  measuredLengthUnits?: Maybe<Scalars['String']>;
  measuredTi?: Maybe<Scalars['Int']>;
  measuredWeight?: Maybe<Scalars['Float']>;
  measuredWeightUnits?: Maybe<Scalars['String']>;
  measuredWidth?: Maybe<Scalars['Float']>;
  packageType: Scalars['ID'];
  perChildPackageQty?: Maybe<Scalars['Int']>;
  roundingThreshold?: Maybe<Scalars['Float']>;
  supplierNumber?: Maybe<Scalars['String']>;
  upcCode?: Maybe<Scalars['String']>;
};

export type ItemSourceResult = {
  __typename?: 'ItemSourceResult';
  items: Maybe<Array<ItemSource>>;
  totalCount: Scalars['Int'];
};

export type ItemSourceUploadInput = {
  file: Scalars['Upload'];
};

export type ItemSourceUploadResult = {
  __typename?: 'ItemSourceUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type ItemSourcesInput = {
  items: Array<ItemSourceInput>;
};

export type ItemUploadInput = {
  file: Scalars['Upload'];
};

export type ItemUploadResult = {
  __typename?: 'ItemUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type ItemsInput = {
  items: Array<ItemInput>;
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  activeStatus: Scalars['String'];
  country: Scalars['String'];
  effectiveEndDate: Scalars['Int'];
  effectiveStartDate: Scalars['Int'];
  id: Scalars['ID'];
  internalLocId: Maybe<Scalars['ID']>;
  lastUpdateDate: Scalars['Int'];
  legalEntityID: Scalars['String'];
  legalEntityIdentifier: Scalars['String'];
  locationId: Scalars['String'];
  locationName: Scalars['String'];
  locationSubInv: Scalars['String'];
  name: Scalars['String'];
  organizationCode: Scalars['String'];
  organizationID: Scalars['String'];
  poCategory: Scalars['String'];
  state: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  address: Maybe<Scalars['String']>;
  ageOutThreshold: Maybe<Scalars['Int']>;
  allocationPriority: Maybe<AllocationPriority>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  districtId: Maybe<Scalars['Int']>;
  extId: Scalars['String'];
  id: Scalars['ID'];
  isServicePaused: Maybe<Scalars['Boolean']>;
  isThirdParty: Maybe<Scalars['Boolean']>;
  latitude: Maybe<Scalars['String']>;
  legalEntities: Maybe<Array<LegalEntity>>;
  locationAlcoholCapabilities: Maybe<Array<AlcoholType>>;
  locationAlcoholCapabilitiesUpdatedAt: Maybe<Scalars['Int']>;
  locationAlcoholCapabilitiesUpdatedBy: Maybe<Scalars['String']>;
  locationCapabilities: Maybe<Array<Capability>>;
  locationCapabilitiesUpdatedAt: Maybe<Scalars['Int']>;
  locationCapabilitiesUpdatedBy: Maybe<Scalars['String']>;
  locationCustomAssortments: Maybe<Array<CustomAssortment>>;
  locationCustomAssortmentsUpdatedAt: Maybe<Scalars['Int']>;
  locationCustomAssortmentsUpdatedBy: Maybe<Scalars['String']>;
  locationExtraUpdatedAt: Maybe<Scalars['Int']>;
  locationExtraUpdatedBy: Maybe<Scalars['String']>;
  locationTobaccoCapabilities: Maybe<Array<TobaccoType>>;
  locationTobaccoCapabilitiesUpdatedAt: Maybe<Scalars['Int']>;
  locationTobaccoCapabilitiesUpdatedBy: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
  mappedOpcoId: Maybe<Scalars['Int']>;
  metroRegion: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  nationalAssortment: Maybe<NationalAssortment>;
  openFrom: Maybe<Scalars['String']>;
  openTo: Maybe<Scalars['String']>;
  orgDetails: Maybe<OrgDetails>;
  postalCode: Maybe<Scalars['String']>;
  regionId: Maybe<Scalars['Int']>;
  stateProvinceRegion: Maybe<Scalars['String']>;
  stopBuying: Maybe<Scalars['Boolean']>;
  timezone: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type LocationDownloadFilter = {
  IDs?: Maybe<Array<Scalars['ID']>>;
};

export type LocationDownloadResult = {
  __typename?: 'LocationDownloadResult';
  file: File;
};

export type LocationFilter = {
  address?: Maybe<Scalars['String']>;
  ageOutThreshold?: Maybe<Scalars['Int']>;
  alcoholTypeIds?: Maybe<Array<Scalars['ID']>>;
  allocationPriorityIds?: Maybe<Array<Scalars['ID']>>;
  capabilityIds?: Maybe<Array<Scalars['ID']>>;
  city?: Maybe<Scalars['String']>;
  customAssortmentIds?: Maybe<Array<Scalars['ID']>>;
  districtIds?: Maybe<Array<Scalars['ID']>>;
  extIds?: Maybe<Array<Scalars['String']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
  isServicePaused?: Maybe<Scalars['Boolean']>;
  isThirdParty?: Maybe<Scalars['Boolean']>;
  metroRegion?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nationalAssortmentIds?: Maybe<Array<Scalars['ID']>>;
  openFrom?: Maybe<Scalars['String']>;
  openTo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionIds?: Maybe<Array<Scalars['ID']>>;
  stateProvinceRegion?: Maybe<Scalars['String']>;
  stopBuying?: Maybe<Scalars['Boolean']>;
  tobaccoTypeIds?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type LocationGroup = {
  __typename?: 'LocationGroup';
  id: Scalars['ID'];
  locations: Array<Location>;
  name: Scalars['String'];
};

export type LocationGroupResult = {
  __typename?: 'LocationGroupResult';
  records: Array<LocationGroup>;
};

export type LocationInput = {
  ageOutThreshold?: Maybe<Scalars['Int']>;
  alcoholVolumes?: Maybe<Array<Scalars['ID']>>;
  allocationPriority?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isThirdParty?: Maybe<Scalars['Boolean']>;
  locationCapabilities?: Maybe<Array<Scalars['ID']>>;
  locationCustomAssortments?: Maybe<Array<Scalars['ID']>>;
  locationTobaccoCapabilities?: Maybe<Array<Scalars['ID']>>;
  nationalAssortment?: Maybe<Scalars['ID']>;
  stopBuying?: Maybe<Scalars['Boolean']>;
};

export type LocationResult = {
  __typename?: 'LocationResult';
  locations: Maybe<Array<Location>>;
  totalCount: Scalars['Int'];
};

export type LocationUploadInput = {
  file: Scalars['Upload'];
};

export type LocationUploadResult = {
  __typename?: 'LocationUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type LocationsInput = {
  locations: Array<LocationInput>;
};

export type ManufacturingType = {
  __typename?: 'ManufacturingType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ManufacturingTypeInput = {
  name: Scalars['String'];
};

/** A Go module/package */
export type Module = {
  __typename?: 'Module';
  path: Scalars['String'];
  replace: Maybe<Module>;
  sum: Scalars['String'];
  version: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  autoApproveCosts: Maybe<Array<CostDate>>;
  costManagementActionsUpload: CostManagementUploadResult;
  costManagementUpload: CostManagementUploadResult;
  dealUpload: DealUploadResult;
  deleteAlcoholABV: Scalars['Boolean'];
  deleteAlcoholTypes: Scalars['Boolean'];
  deleteAllocationPriorities: Scalars['Boolean'];
  deleteCapabilities: Scalars['Boolean'];
  deleteCostDates: Maybe<StandardMutationResponse>;
  deleteCustomAssortments: Scalars['Boolean'];
  deleteDealLines: Scalars['Boolean'];
  deleteDealLocationGroups: Scalars['Boolean'];
  deleteDealLocations: Scalars['Boolean'];
  deleteDeals: Scalars['Boolean'];
  deleteNationalAssortments: Scalars['Boolean'];
  deleteTobaccoTypes: Scalars['Boolean'];
  destinationAssortmentsUpload: DestinationAssortmentsUploadResult;
  graphJinCustomAssortment: Array<GraphJinCustomAssortment>;
  graphJinItemExtraSubClassDefault: Array<GraphJinItemExtraSubClassDefault>;
  graphJinLeadTimeOverride: Array<GraphJinLeadTimeOverride>;
  insertDealLocationGroups: DealLocationGroupResult;
  insertDealLocations: DealLocationResult;
  itemSourceUpload: ItemSourceUploadResult;
  itemUpload: ItemUploadResult;
  locationUpload: LocationUploadResult;
  massEditCostManagement: Maybe<Array<CostManagement>>;
  massEditSupplierAssortments: Maybe<SupplierAssortmentResult>;
  massEditTransportationLanes: Maybe<TransportationLaneResult>;
  rescindDeals: Scalars['Boolean'];
  sendPendingCostNotification: Maybe<NotificationRun>;
  sourceDestinationUpload: SourceDestinationUploadResult;
  supplierAssortmentUpload: SupplierAssortmentUploadResult;
  transportationLaneUpload: TransportationLaneUploadResult;
  updateCostDates: Maybe<Array<CostDate>>;
  updateCostDatesApprovalStatus: Maybe<StandardMutationResponse>;
  updateDealLineScanDeal: DealLineScanDealResult;
  updateDeals: DealResult;
  updateDealsApprovalStatus: DealResult;
  updateDestinationAssortments: Maybe<DestinationAssortmentResult>;
  updateItemSources: Maybe<ItemSourceResult>;
  updateItems: Maybe<ItemResult>;
  updateLocations: Maybe<LocationResult>;
  updateOpcos: Maybe<FullOpcoResult>;
  updateSourceDestinations: Maybe<SourceDestinationResult>;
  /**
   * updateSupplierAssortments, updateTransportationLanesAccountId  use update logic to update fields of existed records
   * not only AccountId field
   */
  updateSupplierAssortments: Maybe<Array<SupplierAssortment>>;
  updateTransportationLanesAccountId: Maybe<Array<TransportationLane>>;
  upsertAlcoholTypes: Maybe<AlcoholTypeResult>;
  upsertAllocationPriorities: Maybe<AllocationPriorityResult>;
  upsertCapabilities: Maybe<CapabilityResult>;
  upsertCostAutoApproveRules: Maybe<Array<CostAutoApproveRule>>;
  upsertCustomAssortments: Maybe<CustomAssortmentResult>;
  upsertDealLines: DealLineResult;
  upsertNationalAssortments: Maybe<NationalAssortmentResult>;
  upsertTobaccoTypes: Maybe<TobaccoTypeResult>;
  /**
   * rechecks the connections are valid according to the validation logic
   * e.g. If location no more supports the alcohol,
   * the relation between this locations and all its product should be checked.
   * Processes in the background.
   */
  validateDestinationAssortments: Scalars['Boolean'];
  vendorCostManagementUpload: CostManagementUploadResult;
};

export type MutationAutoApproveCostsArgs = {
  filter?: Maybe<CostAutoApproveFilter>;
};

export type MutationCostManagementActionsUploadArgs = {
  input: CostManagementUploadInput;
};

export type MutationCostManagementUploadArgs = {
  input: CostManagementUploadInput;
};

export type MutationDealUploadArgs = {
  input: DealUploadInput;
};

export type MutationDeleteAlcoholAbvArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteAlcoholTypesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteAllocationPrioritiesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteCapabilitiesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteCostDatesArgs = {
  input: Array<Scalars['ID']>;
};

export type MutationDeleteCustomAssortmentsArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteDealLinesArgs = {
  input: Array<Scalars['ID']>;
};

export type MutationDeleteDealLocationGroupsArgs = {
  input: Array<Scalars['ID']>;
};

export type MutationDeleteDealLocationsArgs = {
  input: Array<Scalars['ID']>;
};

export type MutationDeleteDealsArgs = {
  input: Array<Scalars['ID']>;
};

export type MutationDeleteNationalAssortmentsArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteTobaccoTypesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDestinationAssortmentsUploadArgs = {
  input: DestinationAssortmentsUploadInput;
};

export type MutationGraphJinCustomAssortmentArgs = {
  deleting?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  insert?: Maybe<Array<GraphJinCustomAssortmentInsertInput>>;
  update?: Maybe<GraphJinCustomAssortmentInsertInput>;
};

export type MutationGraphJinItemExtraSubClassDefaultArgs = {
  id?: Maybe<Scalars['ID']>;
  update?: Maybe<GraphJinItemExtraSubClassDefaultInsertInput>;
};

export type MutationGraphJinLeadTimeOverrideArgs = {
  deleting?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  insert?: Maybe<Array<GraphJinLeadTimeOverrideInsertInput>>;
  update?: Maybe<GraphJinLeadTimeOverrideInsertInput>;
};

export type MutationInsertDealLocationGroupsArgs = {
  input: Array<DealLocationGroupInput>;
};

export type MutationInsertDealLocationsArgs = {
  input: Array<DealLocationInput>;
};

export type MutationItemSourceUploadArgs = {
  input: ItemSourceUploadInput;
};

export type MutationItemUploadArgs = {
  input: ItemUploadInput;
};

export type MutationLocationUploadArgs = {
  input: LocationUploadInput;
};

export type MutationMassEditCostManagementArgs = {
  input: CostManagementMassEditInput;
};

export type MutationMassEditSupplierAssortmentsArgs = {
  input: SupplierAssortmentsMassEditInput;
};

export type MutationMassEditTransportationLanesArgs = {
  input: TransportationLanesMassEditInput;
};

export type MutationRescindDealsArgs = {
  input: Array<Scalars['ID']>;
};

export type MutationSendPendingCostNotificationArgs = {
  input: SendPendingCostNotificationRequest;
};

export type MutationSourceDestinationUploadArgs = {
  input: SourceDestinationUploadInput;
};

export type MutationSupplierAssortmentUploadArgs = {
  input: SupplierAssortmentUploadInput;
};

export type MutationTransportationLaneUploadArgs = {
  input: TransportationLaneUploadInput;
};

export type MutationUpdateCostDatesArgs = {
  input?: Maybe<Array<CostDateInput>>;
};

export type MutationUpdateCostDatesApprovalStatusArgs = {
  input: CostDateApprovalStatusInput;
};

export type MutationUpdateDealLineScanDealArgs = {
  input: DealLineScanDealInput;
};

export type MutationUpdateDealsArgs = {
  input: Array<UpdateDealInput>;
};

export type MutationUpdateDealsApprovalStatusArgs = {
  input: DealApprovalStatusInput;
};

export type MutationUpdateDestinationAssortmentsArgs = {
  input: DestinationAssortmentsInput;
};

export type MutationUpdateItemSourcesArgs = {
  input?: Maybe<ItemSourcesInput>;
};

export type MutationUpdateItemsArgs = {
  input: ItemsInput;
};

export type MutationUpdateLocationsArgs = {
  input?: Maybe<LocationsInput>;
};

export type MutationUpdateOpcosArgs = {
  input: OpcosInput;
};

export type MutationUpdateSourceDestinationsArgs = {
  input: SourceDestinationsInput;
};

export type MutationUpdateSupplierAssortmentsArgs = {
  input: Array<SupplierAssortmentUpdateInput>;
};

export type MutationUpdateTransportationLanesAccountIdArgs = {
  input: Array<TransportationLaneUpdateInput>;
};

export type MutationUpsertAlcoholTypesArgs = {
  input: AlcoholTypesInput;
};

export type MutationUpsertAllocationPrioritiesArgs = {
  input: AllocationPrioritiesInput;
};

export type MutationUpsertCapabilitiesArgs = {
  input: CapabilitiesInput;
};

export type MutationUpsertCostAutoApproveRulesArgs = {
  input: Array<CostAutoApproveRuleInput>;
};

export type MutationUpsertCustomAssortmentsArgs = {
  input: CustomAssortmentsInput;
};

export type MutationUpsertDealLinesArgs = {
  input: Array<DealLineInput>;
};

export type MutationUpsertNationalAssortmentsArgs = {
  input: NationalAssortmentsInput;
};

export type MutationUpsertTobaccoTypesArgs = {
  input: TobaccoTypesInput;
};

export type MutationVendorCostManagementUploadArgs = {
  input: CostManagementUploadInput;
};

export type NationalAssortment = {
  __typename?: 'NationalAssortment';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NationalAssortmentInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type NationalAssortmentResult = {
  __typename?: 'NationalAssortmentResult';
  assortments: Maybe<Array<NationalAssortment>>;
};

export type NationalAssortmentsInput = {
  assortments: Array<NationalAssortmentInput>;
};

export type NotificationRun = {
  __typename?: 'NotificationRun';
  id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
};

export type Opco = {
  __typename?: 'Opco';
  buyer: Maybe<Scalars['String']>;
  ext_id: Scalars['String'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  pureName: Maybe<Scalars['String']>;
  stateProvinceRegion: Maybe<Scalars['String']>;
  supplier: Maybe<Supplier>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type OpcoFilter = {
  buyer?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  sendToTraverse?: Maybe<Scalars['Boolean']>;
  stateProvinceRegion?: Maybe<Scalars['String']>;
};

export type OpcoInput = {
  buyer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  packageTypeId?: Maybe<Scalars['ID']>;
  sendToTraverse?: Maybe<Scalars['Boolean']>;
};

export type OpcoResult = {
  __typename?: 'OpcoResult';
  opcos: Maybe<Array<Opco>>;
  totalCount: Scalars['Int'];
};

export type OpcosInput = {
  opcos: Array<OpcoInput>;
};

export enum OrderFrequencyType {
  Biweekly_1_3 = 'BIWEEKLY_1_3',
  Biweekly_2_4 = 'BIWEEKLY_2_4',
  Monthly_1 = 'MONTHLY_1',
  Monthly_2 = 'MONTHLY_2',
  Monthly_3 = 'MONTHLY_3',
  Monthly_4 = 'MONTHLY_4',
  Weekly = 'WEEKLY',
}

export type OrgDetails = {
  __typename?: 'OrgDetails';
  BillToBU: Maybe<Scalars['String']>;
  BillToLocation: Maybe<Scalars['String']>;
  ShipToOrganizationCode: Maybe<Scalars['String']>;
  ShipToOrganizationName: Maybe<Scalars['String']>;
};

export type PackageType = {
  __typename?: 'PackageType';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type Packaging = {
  __typename?: 'Packaging';
  declaredHeight: Maybe<Scalars['Float']>;
  declaredHi: Maybe<Scalars['Int']>;
  declaredLength: Maybe<Scalars['Float']>;
  declaredLengthUnits: Maybe<Scalars['String']>;
  declaredTi: Maybe<Scalars['Int']>;
  declaredVolume: Maybe<Scalars['Float']>;
  declaredWeight: Maybe<Scalars['Float']>;
  declaredWeightUnits: Maybe<Scalars['String']>;
  declaredWidth: Maybe<Scalars['Float']>;
  eachUPCs: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  itemID: Scalars['ID'];
  maxAllocationQuantity: Maybe<Scalars['Int']>;
  measuredHeight: Maybe<Scalars['Float']>;
  measuredHi: Maybe<Scalars['Int']>;
  measuredLength: Maybe<Scalars['Float']>;
  measuredLengthUnits: Maybe<Scalars['String']>;
  measuredTi: Maybe<Scalars['Int']>;
  measuredVolume: Maybe<Scalars['Float']>;
  measuredWeight: Maybe<Scalars['Float']>;
  measuredWeightUnits: Maybe<Scalars['String']>;
  measuredWidth: Maybe<Scalars['Float']>;
  packageType: PackageType;
  perChildPackageQty: Maybe<Scalars['Int']>;
  roundingThreshold: Maybe<Scalars['Float']>;
  supplierItemPackagingNumber: Maybe<Scalars['String']>;
  /** @deprecated use supplierItemPackagingNumber. supplierNumber isn't descriptive enough */
  supplierNumber: Maybe<Scalars['String']>;
  totalEachQuantity: Maybe<Scalars['Int']>;
  /** @deprecated upc is deprecated. Use upcCode instead. Only Each Pfg don't have upcCode, use eachUPCs for retrieving all each upcs */
  upc: Maybe<Array<Upc>>;
  upcCode: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export enum PackingConstraintType {
  Alone = 'ALONE',
  None = 'NONE',
  SameClass = 'SAME_CLASS',
  SameDepartment = 'SAME_DEPARTMENT',
  SameSubclass = 'SAME_SUBCLASS',
}

export type Pagination = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ParentCompany = {
  __typename?: 'ParentCompany';
  extId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PeriodType = {
  __typename?: 'PeriodType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PricingChannel = {
  __typename?: 'PricingChannel';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PricingChannelResult = {
  __typename?: 'PricingChannelResult';
  records: Array<PricingChannel>;
};

export type Query = {
  __typename?: 'Query';
  alcoholTypes: Array<AlcoholType>;
  allocationPriorities: Array<AllocationPriority>;
  bomItems: Maybe<Array<BomItem>>;
  buildInfo: BuildInfo;
  buyers: Array<Buyer>;
  capabilities: Array<Capability>;
  containerUom: Array<ContainerUom>;
  costAutoApproveRules: Maybe<CostAutoApproveRuleResult>;
  costDates: Maybe<CostDateResult>;
  costManagementDownload: Maybe<CostDownloadResult>;
  costManagementRecords: Maybe<CostManagementResult>;
  currencies: Array<Currency>;
  customAssortments: Array<CustomAssortment>;
  deals: Maybe<DealResult>;
  destinationAssortments: Maybe<DestinationAssortmentResult>;
  destinationAssortmentsDownload: Maybe<DestinationAssortmentsDownloadResult>;
  getSystemStatus: SystemStatus;
  graphJinCustomAssortment: Array<GraphJinCustomAssortment>;
  graphJinItemExtraSubClassDefault: Array<GraphJinItemExtraSubClassDefault>;
  graphJinItemSubClass: Array<GraphJinItemSubClass>;
  graphJinLeadTimeOverride: Array<GraphJinLeadTimeOverride>;
  itemDownload: Maybe<ItemDownloadResult>;
  itemSourceDownload: Maybe<ItemSourceDownloadResult>;
  itemSources: Maybe<ItemSourceResult>;
  items: Maybe<ItemResult>;
  lastDestinationAssortmentTime: Scalars['String'];
  locationDownload: Maybe<LocationDownloadResult>;
  locationGroups: Maybe<LocationGroupResult>;
  locations: Maybe<LocationResult>;
  manufacturingTypes: Array<ManufacturingType>;
  nationalAssortments: Array<NationalAssortment>;
  opcos: Maybe<OpcoResult>;
  packageTypes: Array<PackageType>;
  pricingChannels: Maybe<PricingChannelResult>;
  sourceDestinationDownload: Maybe<SourceDestinationDownloadResult>;
  sourceDestinations: Maybe<SourceDestinationResult>;
  supplierAssortmentDownload: Maybe<SupplierAssortmentDownloadResult>;
  /**
   * supplierAssortments returns assortments in predifined order NULLS FIRST for SupplierAssortment.id field
   * Client could provide the second fild for sorting:
   * Example: SupplierAssortmentSort{field: "ITEM", order: "DESC"}
   * the results wiil be return in the order: 'SupplierAssortment.id NULLS FIRST ITEM DESC'
   */
  supplierAssortments: Maybe<SupplierAssortmentResult>;
  suppliers: Maybe<SupplierResult>;
  systemOfMeasures: Array<SystemOfMeasure>;
  tobaccoTypes: Array<TobaccoType>;
  transportationLaneDownload: Maybe<TransportationLaneDownloadResult>;
  transportationLanes: Maybe<TransportationLaneResult>;
  unprocessedRecords: UnprocessedRecordsResult;
  users: Array<User>;
  velocityCategories: Array<VelocityCategory>;
};

export type QueryBomItemsArgs = {
  bom: Scalars['ID'];
};

export type QueryCostAutoApproveRulesArgs = {
  filter?: Maybe<CostAutoApproveRuleFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryCostDatesArgs = {
  filter?: Maybe<CostDateFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryCostManagementDownloadArgs = {
  filter?: Maybe<CostManagementFilter>;
};

export type QueryCostManagementRecordsArgs = {
  filter?: Maybe<CostManagementFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryDealsArgs = {
  filter?: Maybe<DealFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryDestinationAssortmentsArgs = {
  filter?: Maybe<DestinationAssortmentFilter>;
  pagination?: Maybe<Pagination>;
  sorting?: Maybe<DestinationAssortmentSort>;
};

export type QueryDestinationAssortmentsDownloadArgs = {
  filter?: Maybe<DestinationAssortmentsDownloadFilter>;
};

export type QueryGraphJinCustomAssortmentArgs = {
  filter: GraphJinCustomAssortmentFilter;
  pagination?: Maybe<Pagination>;
};

export type QueryGraphJinItemExtraSubClassDefaultArgs = {
  filter: GraphJinItemExtraSubClassDefaultFilter;
  pagination?: Maybe<Pagination>;
};

export type QueryGraphJinItemSubClassArgs = {
  filter: GraphJinItemSubClassFilter;
};

export type QueryGraphJinLeadTimeOverrideArgs = {
  filter: GraphJinLeadTimeOverrideFilter;
};

export type QueryItemDownloadArgs = {
  filter?: Maybe<ItemDownloadFilter>;
};

export type QueryItemSourceDownloadArgs = {
  filter?: Maybe<ItemSourceDownloadFilter>;
};

export type QueryItemSourcesArgs = {
  filter?: Maybe<ItemSourceFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryItemsArgs = {
  filter?: Maybe<ItemFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryLocationDownloadArgs = {
  filter?: Maybe<LocationDownloadFilter>;
};

export type QueryLocationsArgs = {
  filter?: Maybe<LocationFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryOpcosArgs = {
  filter?: Maybe<OpcoFilter>;
  pagination?: Maybe<Pagination>;
};

export type QuerySourceDestinationDownloadArgs = {
  filter?: Maybe<SourceDestinationDownloadFilter>;
};

export type QuerySourceDestinationsArgs = {
  filter?: Maybe<SourceDestinationFilter>;
  pagination?: Maybe<Pagination>;
};

export type QuerySupplierAssortmentDownloadArgs = {
  filter?: Maybe<SupplierAssortmentDownloadFilter>;
  view?: Maybe<SupplierAssortmentDownloadView>;
};

export type QuerySupplierAssortmentsArgs = {
  filter?: Maybe<SupplierAssortmentFilter>;
  pagination?: Maybe<Pagination>;
  sorting?: Maybe<SupplierAssortmentSort>;
};

export type QuerySuppliersArgs = {
  filter?: Maybe<SupplierFilter>;
  pagination?: Maybe<Pagination>;
};

export type QueryTransportationLaneDownloadArgs = {
  filter?: Maybe<TransportationLaneDownloadFilter>;
  view?: Maybe<TransportationLaneDownloadView>;
};

export type QueryTransportationLanesArgs = {
  filter?: Maybe<TransportationLaneFilter>;
  pagination?: Maybe<Pagination>;
  sorting?: Maybe<TransportationLaneSort>;
};

export type RescindDealsInput = {
  ids: Array<Scalars['ID']>;
};

export type SendPendingCostNotificationRequest = {
  key: Scalars['String'];
  toEmails?: Maybe<Array<Scalars['String']>>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SourceDestination = {
  __typename?: 'SourceDestination';
  accountNumber: Maybe<Scalars['String']>;
  autoOrder: Maybe<Scalars['Boolean']>;
  autoRelease: Maybe<Scalars['Boolean']>;
  capacities: Maybe<Array<ContainerCapacity>>;
  createdAt: Maybe<Scalars['Int']>;
  deliveryWindowFrom: Maybe<Scalars['String']>;
  deliveryWindowTo: Maybe<Scalars['String']>;
  fillKillThreshold: Maybe<Scalars['Int']>;
  friLeadTime: Maybe<Scalars['Int']>;
  gopuffTransport: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isActive: Maybe<Scalars['Boolean']>;
  location: Maybe<Location>;
  minimumOrderQuantity: Maybe<Scalars['Int']>;
  minimumOrderValue: Maybe<Scalars['Float']>;
  minimumOrderWeight: Maybe<Scalars['Float']>;
  monLeadTime: Maybe<Scalars['Int']>;
  moqOrderingUomId: Maybe<Scalars['Int']>;
  movCurrencyId: Maybe<Scalars['Int']>;
  mowSystemOfMeasureId: Maybe<Scalars['Int']>;
  notActiveSince: Maybe<Scalars['Int']>;
  orderFrequency: Maybe<OrderFrequencyType>;
  quantityOverageCount: Maybe<Scalars['Int']>;
  quantityOveragePercent: Maybe<Scalars['Float']>;
  quantityUnderageCount: Maybe<Scalars['Int']>;
  quantityUnderagePercent: Maybe<Scalars['Float']>;
  satLeadTime: Maybe<Scalars['Int']>;
  source: Maybe<TransportationLaneSource>;
  sunLeadTime: Maybe<Scalars['Int']>;
  thuLeadTime: Maybe<Scalars['Int']>;
  transitType: Maybe<Scalars['String']>;
  tueLeadTime: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
  valueOveragePercent: Maybe<Scalars['Float']>;
  valueOverageValue: Maybe<Scalars['Float']>;
  valueUnderagePercent: Maybe<Scalars['Float']>;
  valueUnderageValue: Maybe<Scalars['Float']>;
  wedLeadTime: Maybe<Scalars['Int']>;
};

export type SourceDestinationDownloadFilter = {
  IDs?: Maybe<Array<Scalars['ID']>>;
};

export type SourceDestinationDownloadResult = {
  __typename?: 'SourceDestinationDownloadResult';
  file: File;
};

export type SourceDestinationFilter = {
  autoOrder?: Maybe<Scalars['Boolean']>;
  containerMax?: Maybe<Scalars['Int']>;
  containerMin?: Maybe<Scalars['Int']>;
  containerUom?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<TimeRangeFilter>;
  deliveryWindowFrom?: Maybe<Scalars['String']>;
  deliveryWindowTo?: Maybe<Scalars['String']>;
  destinationExtIds?: Maybe<Array<Scalars['String']>>;
  destinationIds?: Maybe<Array<Scalars['ID']>>;
  destinationName?: Maybe<Scalars['String']>;
  fillKillThreshold?: Maybe<Scalars['Int']>;
  gopuffTransport?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  minimumOrderValue?: Maybe<Scalars['Float']>;
  minimumOrderWeight?: Maybe<Scalars['Float']>;
  moqOrderingUomIds?: Maybe<Array<Scalars['Int']>>;
  movCurrencyIds?: Maybe<Array<Scalars['Int']>>;
  mowSystemOfMeasureIds?: Maybe<Array<Scalars['Int']>>;
  notActiveSince?: Maybe<TimeRangeFilter>;
  opcoStateProvinceRegion?: Maybe<Scalars['String']>;
  orderFrequency?: Maybe<Array<OrderFrequencyType>>;
  quantityOverageCount?: Maybe<Scalars['Int']>;
  quantityOveragePercent?: Maybe<Scalars['Float']>;
  quantityUnderageCount?: Maybe<Scalars['Int']>;
  quantityUnderagePercent?: Maybe<Scalars['Float']>;
  sourceIds?: Maybe<Array<Scalars['ID']>>;
  sourceName?: Maybe<Scalars['String']>;
  supplierExtIds?: Maybe<Array<Scalars['String']>>;
  transitType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
  valueOveragePercent?: Maybe<Scalars['Float']>;
  valueOverageValue?: Maybe<Scalars['Float']>;
  valueUnderagePercent?: Maybe<Scalars['Float']>;
  valueUnderageValue?: Maybe<Scalars['Float']>;
};

export type SourceDestinationInput = {
  autoOrder?: Maybe<Scalars['Boolean']>;
  autoRelease?: Maybe<Scalars['Boolean']>;
  capacities?: Maybe<Array<ContainerCapacityInput>>;
  deliveryWindowFrom?: Maybe<Scalars['String']>;
  deliveryWindowTo?: Maybe<Scalars['String']>;
  fillKillThreshold?: Maybe<Scalars['Int']>;
  friLeadTime?: Maybe<Scalars['Int']>;
  gopuffTransport?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  minimumOrderValue?: Maybe<Scalars['Float']>;
  minimumOrderWeight?: Maybe<Scalars['Float']>;
  monLeadTime?: Maybe<Scalars['Int']>;
  moqOrderingUomId?: Maybe<Scalars['Int']>;
  movCurrencyId?: Maybe<Scalars['Int']>;
  mowSystemOfMeasureId?: Maybe<Scalars['Int']>;
  orderFrequency?: Maybe<OrderFrequencyType>;
  quantityOverageCount?: Maybe<Scalars['Int']>;
  quantityOveragePercent?: Maybe<Scalars['Float']>;
  quantityUnderageCount?: Maybe<Scalars['Int']>;
  quantityUnderagePercent?: Maybe<Scalars['Float']>;
  satLeadTime?: Maybe<Scalars['Int']>;
  sunLeadTime?: Maybe<Scalars['Int']>;
  thuLeadTime?: Maybe<Scalars['Int']>;
  transitType?: Maybe<Scalars['String']>;
  tueLeadTime?: Maybe<Scalars['Int']>;
  valueOveragePercent?: Maybe<Scalars['Float']>;
  valueOverageValue?: Maybe<Scalars['Float']>;
  valueUnderagePercent?: Maybe<Scalars['Float']>;
  valueUnderageValue?: Maybe<Scalars['Float']>;
  wedLeadTime?: Maybe<Scalars['Int']>;
};

export type SourceDestinationResult = {
  __typename?: 'SourceDestinationResult';
  destinations: Maybe<Array<SourceDestination>>;
  totalCount: Scalars['Int'];
};

export type SourceDestinationUploadInput = {
  file: Scalars['Upload'];
};

export type SourceDestinationUploadResult = {
  __typename?: 'SourceDestinationUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type SourceDestinationsInput = {
  destinations: Array<SourceDestinationInput>;
};

export type StandardMutationResponse = {
  __typename?: 'StandardMutationResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Supplier = {
  __typename?: 'Supplier';
  costAutoApproveRules: Maybe<Array<CostAutoApproveRule>>;
  extID: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  opcos: Array<FullOpco>;
};

export type SupplierAssortment = {
  __typename?: 'SupplierAssortment';
  id: Maybe<Scalars['ID']>;
  item: Maybe<Item>;
  managedBy: Maybe<User>;
  opco: Maybe<Opco>;
};

export enum SupplierAssortmentCostFilter {
  All = 'ALL',
  Existing = 'EXISTING',
  Missing = 'MISSING',
}

export type SupplierAssortmentDownloadFilter = {
  IDs?: Maybe<Array<Scalars['ID']>>;
  itemIDs?: Maybe<Array<Scalars['ID']>>;
  opcoIDs?: Maybe<Array<Scalars['ID']>>;
};

export type SupplierAssortmentDownloadResult = {
  __typename?: 'SupplierAssortmentDownloadResult';
  file: File;
};

export enum SupplierAssortmentDownloadView {
  Item = 'ITEM',
  Opco = 'OPCO',
}

export type SupplierAssortmentFilter = {
  eachUPCs?: Maybe<Array<Scalars['String']>>;
  itemExtIds?: Maybe<Array<Scalars['String']>>;
  itemIds?: Maybe<Array<Scalars['ID']>>;
  itemNames?: Maybe<Scalars['String']>;
  managedByIds?: Maybe<Array<Scalars['String']>>;
  opcoIds?: Maybe<Array<Scalars['ID']>>;
  opcoNames?: Maybe<Scalars['String']>;
  opcoStateProvinceRegion?: Maybe<Scalars['String']>;
  supplierExtIds?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type SupplierAssortmentInput = {
  itemID: Scalars['ID'];
  managerID?: Maybe<Scalars['String']>;
  opcoID: Scalars['ID'];
};

export type SupplierAssortmentResult = {
  __typename?: 'SupplierAssortmentResult';
  assortments: Maybe<Array<SupplierAssortment>>;
  totalCount: Scalars['Int'];
};

export type SupplierAssortmentSort = {
  field: SupplierAssortmentSortField;
  order: SortOrder;
};

export enum SupplierAssortmentSortField {
  Item = 'ITEM',
  Opco = 'OPCO',
}

/**
 * * All the DB records for the ids' list for SupplierAssortmentType will be soft_deleted first
 * * All the provided models will be inserted to the DB on the second step
 */
export enum SupplierAssortmentType {
  Item = 'ITEM',
  Opco = 'OPCO',
}

export type SupplierAssortmentUpdateInput = {
  id: Scalars['ID'];
  managerID?: Maybe<Scalars['String']>;
};

export type SupplierAssortmentUploadInput = {
  file: Scalars['Upload'];
};

export type SupplierAssortmentUploadResult = {
  __typename?: 'SupplierAssortmentUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type SupplierAssortmentsInput = {
  assortmentType: SupplierAssortmentType;
  assortments: Array<SupplierAssortmentInput>;
};

export type SupplierAssortmentsMassEditInput = {
  removeAssortments?: Maybe<Array<SupplierAssortmentInput>>;
  upsertAssortments?: Maybe<Array<SupplierAssortmentInput>>;
};

export type SupplierFilter = {
  extIds?: Maybe<Array<Scalars['String']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  opcoAddress?: Maybe<Scalars['String']>;
  opcoBuyer?: Maybe<Scalars['String']>;
  opcoCity?: Maybe<Scalars['String']>;
  opcoCountry?: Maybe<Scalars['String']>;
  opcoIds?: Maybe<Array<Scalars['ID']>>;
  opcoMixedIsActive?: Maybe<Scalars['Boolean']>;
  opcoMixedPkgTypes?: Maybe<Scalars['Boolean']>;
  opcoPkgTypes?: Maybe<Array<Scalars['ID']>>;
  opcoPostalCode?: Maybe<Scalars['String']>;
  opcoSendToTraverse?: Maybe<Scalars['Boolean']>;
  opcoState?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type SupplierResult = {
  __typename?: 'SupplierResult';
  suppliers: Maybe<Array<Supplier>>;
  totalCount: Scalars['Int'];
};

export type SystemOfMeasure = {
  __typename?: 'SystemOfMeasure';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

/**
 * SystemStatus represents the system status according to the ValidateDestinationAssortments mutation
 * LOCKED represents that masterdata locks write operations for destinationAssortment, transportationLane, supplierAssortment
 */
export enum SystemStatus {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED',
}

export enum TemperatureType {
  Ambient = 'Ambient',
  Cool = 'Cool',
  Frozen = 'Frozen',
}

export type TimeRangeFilter = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type TobaccoType = {
  __typename?: 'TobaccoType';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  restrictionCode: Scalars['String'];
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type TobaccoTypeInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  restrictionCode: Scalars['String'];
};

export type TobaccoTypeResult = {
  __typename?: 'TobaccoTypeResult';
  types: Maybe<Array<TobaccoType>>;
};

export type TobaccoTypesInput = {
  types: Array<TobaccoTypeInput>;
};

export type TransportationLane = {
  __typename?: 'TransportationLane';
  accountNumber: Maybe<Scalars['String']>;
  destination: Maybe<Location>;
  id: Maybe<Scalars['ID']>;
  source: Maybe<TransportationLaneSource>;
  updatedAt: Maybe<Scalars['Int']>;
  updatedBy: Maybe<Scalars['String']>;
};

export type TransportationLaneDownloadFilter = {
  IDs?: Maybe<Array<Scalars['ID']>>;
  destinationIDs?: Maybe<Array<Scalars['ID']>>;
  sourceIDs?: Maybe<Array<Scalars['ID']>>;
};

export type TransportationLaneDownloadResult = {
  __typename?: 'TransportationLaneDownloadResult';
  file: File;
};

export enum TransportationLaneDownloadView {
  Destination = 'DESTINATION',
  Source = 'SOURCE',
}

export type TransportationLaneFilter = {
  accountNumbers?: Maybe<Array<Scalars['String']>>;
  destinationExtIds?: Maybe<Array<Scalars['String']>>;
  destinationIds?: Maybe<Array<Scalars['ID']>>;
  destinationName?: Maybe<Scalars['String']>;
  opcoStateProvinceRegion?: Maybe<Scalars['String']>;
  sourceIds?: Maybe<Array<Scalars['ID']>>;
  sourceName?: Maybe<Scalars['String']>;
  supplierExtIds?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<TimeRangeFilter>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type TransportationLaneInput = {
  accountNumber?: Maybe<Scalars['String']>;
  destination: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  sourceLocation?: Maybe<Scalars['ID']>;
  sourceOpco: Scalars['ID'];
};

export type TransportationLaneResult = {
  __typename?: 'TransportationLaneResult';
  totalCount: Scalars['Int'];
  transportationLanes: Maybe<Array<TransportationLane>>;
};

export type TransportationLaneSort = {
  field: TransportationLaneSortField;
  order: SortOrder;
};

export enum TransportationLaneSortField {
  Destination = 'DESTINATION',
  Source = 'SOURCE',
}

export type TransportationLaneSource = Location | Opco;

export type TransportationLaneUpdateInput = {
  accountNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type TransportationLaneUploadInput = {
  file: Scalars['Upload'];
};

export type TransportationLaneUploadResult = {
  __typename?: 'TransportationLaneUploadResult';
  errors: Scalars['Int'];
  file: Maybe<File>;
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type TransportationLanesInput = {
  lanes: Array<TransportationLaneInput>;
  type: TransportationLanesUpdateType;
};

export type TransportationLanesMassEditInput = {
  removeTLanes?: Maybe<Array<TransportationLaneInput>>;
  upsertTLanes?: Maybe<Array<TransportationLaneInput>>;
};

export enum TransportationLanesUpdateType {
  Destinationlocation = 'DESTINATIONLOCATION',
  Opco = 'OPCO',
  Sourcelocation = 'SOURCELOCATION',
}

export type UnprocessedRecordsResult = {
  __typename?: 'UnprocessedRecordsResult';
  destinationAssortmentsCount: Maybe<Scalars['Int']>;
  itemSourcesCount: Maybe<Scalars['Int']>;
  itemsCount: Maybe<Scalars['Int']>;
  locationsCount: Maybe<Scalars['Int']>;
  sourceDestinationsCount: Maybe<Scalars['Int']>;
  supplierAssortmentItemsCount: Maybe<Scalars['Int']>;
  supplierAssortmentsOpcosCount: Maybe<Scalars['Int']>;
  transportationLaneDestinationsCount: Maybe<Scalars['Int']>;
  transportationLaneSourcesCount: Maybe<Scalars['Int']>;
};

export type Upc = {
  __typename?: 'Upc';
  id: Scalars['ID'];
  isPurchasingUpc: Maybe<Scalars['Boolean']>;
  supplierNumber: Maybe<Scalars['String']>;
  upcCode: Maybe<Scalars['String']>;
};

export type UpdateDealInput = {
  endTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  pricingChannelIDs?: Maybe<Array<Scalars['ID']>>;
};

export type UploadSummary = {
  __typename?: 'UploadSummary';
  errors: Scalars['Int'];
  success: Scalars['Int'];
  total: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  displayName: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
};

export type VelocityCategory = {
  __typename?: 'VelocityCategory';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  serviceLevel: Maybe<Scalars['Int']>;
};

export type BomQueryVariables = Exact<{
  bom: Scalars['ID'];
}>;

export type BomQuery = { __typename?: 'Query' } & {
  bomItems: Maybe<
    Array<
      { __typename?: 'BomItem' } & Pick<BomItem, 'id'> & {
          item: { __typename?: 'Item' } & Pick<
            Item,
            'extId' | 'name' | 'category' | 'class' | 'brand'
          >;
        }
    >
  >;
};

export type ParentItemQueryVariables = Exact<{
  pagination: Pagination;
  filter: ItemFilter;
}>;

export type ParentItemQuery = { __typename?: 'Query' } & {
  items: Maybe<
    { __typename?: 'ItemResult' } & {
      items: Maybe<Array<{ __typename?: 'Item' } & Pick<Item, 'name'>>>;
    }
  >;
};

export type CostManagementDownloadQueryVariables = Exact<{
  filter: CostManagementFilter;
}>;

export type CostManagementDownloadQuery = { __typename?: 'Query' } & {
  costManagementDownload: Maybe<
    { __typename?: 'CostDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type MassEditCostManagementMutationVariables = Exact<{
  input: CostManagementMassEditInput;
}>;

export type MassEditCostManagementMutation = { __typename?: 'Mutation' } & {
  massEditCostManagement: Maybe<
    Array<
      { __typename?: 'CostManagement' } & Pick<CostManagement, 'id' | 'isDisabled'> & {
          item: { __typename?: 'Item' } & Pick<Item, 'name'> & {
              id: Item['extId'];
              internalId: Item['id'];
            };
          supplier: { __typename?: 'Supplier' } & Pick<Supplier, 'name'> & {
              id: Supplier['extID'];
              internalId: Supplier['id'];
            };
          location: Maybe<
            { __typename?: 'Location' } & Pick<Location, 'name'> & {
                id: Location['extId'];
                internalId: Location['id'];
              }
          >;
          opco: Maybe<
            { __typename?: 'Opco' } & Pick<Opco, 'id' | 'stateProvinceRegion'> & {
                name: Opco['pureName'];
              }
          >;
          costDates: Maybe<
            Array<
              { __typename?: 'CostDate' } & Pick<
                CostDate,
                'id' | 'costManagementId' | 'effectiveDate' | 'updatedAt' | 'updatedBy' | 'status'
              > & {
                  costPackagings: Maybe<
                    Array<
                      { __typename?: 'CostPackaging' } & Pick<
                        CostPackaging,
                        'costDateId' | 'packageTypeId' | 'value'
                      >
                    >
                  >;
                  currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
                }
            >
          >;
        }
    >
  >;
};

export type CostManagementUploadMutationVariables = Exact<{
  input: CostManagementUploadInput;
}>;

export type CostManagementUploadMutation = { __typename?: 'Mutation' } & {
  costManagementUpload: { __typename?: 'CostManagementUploadResult' } & Pick<
    CostManagementUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type CostManagementsQueryVariables = Exact<{
  pagination?: Maybe<Pagination>;
  filter: CostManagementFilter;
}>;

export type CostManagementsQuery = { __typename?: 'Query' } & {
  costManagementRecords: Maybe<
    { __typename?: 'CostManagementResult' } & Pick<CostManagementResult, 'totalCount'> & {
        records: Maybe<
          Array<
            { __typename?: 'CostManagement' } & Pick<
              CostManagement,
              'id' | 'isDisabled' | 'updatedBy' | 'updatedAt'
            > & {
                item: { __typename?: 'Item' } & Pick<Item, 'name' | 'class' | 'department'> & {
                    id: Item['extId'];
                    internalId: Item['id'];
                  } & {
                    i18n: Maybe<
                      { __typename?: 'ItemI18n' } & {
                        name: Maybe<
                          { __typename?: 'I18n' } & Pick<
                            I18n,
                            'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                          >
                        >;
                      }
                    >;
                  };
                supplier: { __typename?: 'Supplier' } & Pick<Supplier, 'name'> & {
                    id: Supplier['extID'];
                    internalId: Supplier['id'];
                  };
                location: Maybe<
                  { __typename?: 'Location' } & Pick<Location, 'name'> & {
                      id: Location['extId'];
                      internalId: Location['id'];
                    }
                >;
                opco: Maybe<
                  { __typename?: 'Opco' } & Pick<Opco, 'id' | 'stateProvinceRegion'> & {
                      name: Opco['pureName'];
                    }
                >;
                costDates: Maybe<
                  Array<
                    { __typename?: 'CostDate' } & Pick<
                      CostDate,
                      | 'id'
                      | 'costManagementId'
                      | 'effectiveDate'
                      | 'updatedAt'
                      | 'updatedBy'
                      | 'status'
                    > & {
                        costPackagings: Maybe<
                          Array<
                            { __typename?: 'CostPackaging' } & Pick<
                              CostPackaging,
                              'costDateId' | 'packageTypeId' | 'value'
                            >
                          >
                        >;
                        currency: Maybe<
                          { __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>
                        >;
                      }
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type CostDateFragmentFragment = { __typename?: 'CostManagement' } & {
  costDates: Maybe<
    Array<
      { __typename?: 'CostDate' } & Pick<
        CostDate,
        'id' | 'costManagementId' | 'effectiveDate' | 'updatedAt' | 'updatedBy' | 'status'
      > & {
          costPackagings: Maybe<
            Array<
              { __typename?: 'CostPackaging' } & Pick<
                CostPackaging,
                'costDateId' | 'packageTypeId' | 'value'
              >
            >
          >;
          currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
        }
    >
  >;
};

export type UpdateCostDatesMutationVariables = Exact<{
  input?: Maybe<Array<CostDateInput> | CostDateInput>;
}>;

export type UpdateCostDatesMutation = { __typename?: 'Mutation' } & {
  updateCostDates: Maybe<
    Array<
      { __typename?: 'CostDate' } & Pick<
        CostDate,
        'id' | 'effectiveDate' | 'costManagementId' | 'updatedAt' | 'updatedBy' | 'status'
      > & {
          costPackagings: Maybe<
            Array<
              { __typename?: 'CostPackaging' } & Pick<
                CostPackaging,
                'costDateId' | 'packageTypeId' | 'value'
              >
            >
          >;
          currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
        }
    >
  >;
};

export type CostManagementActionsUploadMutationVariables = Exact<{
  input: CostManagementUploadInput;
}>;

export type CostManagementActionsUploadMutation = { __typename?: 'Mutation' } & {
  costManagementActionsUpload: { __typename?: 'CostManagementUploadResult' } & Pick<
    CostManagementUploadResult,
    'total' | 'errors' | 'success'
  > & {
      rows: Maybe<
        Array<
          { __typename?: 'CostManagementFileRow' } & Pick<
            CostManagementFileRow,
            | 'itemDepartment'
            | 'itemClass'
            | 'itemExtID'
            | 'itemName'
            | 'caseCount'
            | 'eachUpc'
            | 'caseUpc'
            | 'eachVin'
            | 'caseVin'
            | 'status'
            | 'eachCostValidation'
            | 'caseCostValidation'
            | 'canAutoApprove'
            | 'notes'
            | 'isDisabled'
            | 'effectiveDate'
            | 'eachCost'
            | 'caseCost'
            | 'currency'
            | 'supplierName'
            | 'supplierExtID'
            | 'opcoName'
            | 'opcoID'
            | 'opcoState'
            | 'locationName'
            | 'locationID'
            | 'locationAddress'
            | 'locationCity'
            | 'locationState'
            | 'locationPostalCode'
            | 'error'
          >
        >
      >;
    };
};

export type MassEditCostManagementV2MutationVariables = Exact<{
  input: CostManagementMassEditInput;
}>;

export type MassEditCostManagementV2Mutation = { __typename?: 'Mutation' } & {
  massEditCostManagement: Maybe<
    Array<
      { __typename?: 'CostManagement' } & Pick<
        CostManagement,
        'id' | 'isDisabled' | 'updatedBy' | 'updatedAt'
      > & {
          item: { __typename?: 'Item' } & Pick<
            Item,
            'name' | 'class' | 'department' | 'parentCompany'
          > & { id: Item['extId']; internalId: Item['id'] } & {
              i18n: Maybe<
                { __typename?: 'ItemI18n' } & {
                  name: Maybe<
                    { __typename?: 'I18n' } & Pick<
                      I18n,
                      'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                    >
                  >;
                }
              >;
            };
          itemSources: Maybe<
            Array<
              { __typename?: 'ItemSource' } & Pick<ItemSource, 'id'> & {
                  opco: { __typename?: 'Opco' } & Pick<Opco, 'id'>;
                  packaging: Maybe<
                    Array<
                      { __typename?: 'Packaging' } & Pick<
                        Packaging,
                        | 'id'
                        | 'perChildPackageQty'
                        | 'supplierItemPackagingNumber'
                        | 'eachUPCs'
                        | 'upcCode'
                      > & {
                          packageType: { __typename?: 'PackageType' } & Pick<
                            PackageType,
                            'id' | 'name'
                          >;
                        }
                    >
                  >;
                }
            >
          >;
          supplier: { __typename?: 'Supplier' } & Pick<Supplier, 'name'> & {
              id: Supplier['extID'];
              internalId: Supplier['id'];
            };
          location: Maybe<
            { __typename?: 'Location' } & Pick<
              Location,
              'name' | 'address' | 'city' | 'stateProvinceRegion' | 'postalCode'
            > & { id: Location['extId']; internalId: Location['id'] }
          >;
          opco: Maybe<
            { __typename?: 'Opco' } & Pick<Opco, 'id' | 'stateProvinceRegion'> & {
                name: Opco['pureName'];
              }
          >;
          costDates: Maybe<
            Array<
              { __typename?: 'CostDate' } & Pick<
                CostDate,
                | 'id'
                | 'costManagementId'
                | 'effectiveDate'
                | 'endDate'
                | 'updatedAt'
                | 'updatedByDisplayName'
                | 'status'
                | 'type'
                | 'comments'
                | 'commentedByDisplayName'
                | 'canAutoApprove'
              > & {
                  costPackagings: Maybe<
                    Array<
                      { __typename?: 'CostPackaging' } & Pick<
                        CostPackaging,
                        'costDateId' | 'packageTypeId' | 'value' | 'percentageChange'
                      >
                    >
                  >;
                  currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
                }
            >
          >;
        }
    >
  >;
};

export type CostManagementsV2QueryVariables = Exact<{
  pagination?: Maybe<Pagination>;
  filter: CostManagementFilter;
}>;

export type CostManagementsV2Query = { __typename?: 'Query' } & {
  costManagementRecords: Maybe<
    { __typename?: 'CostManagementResult' } & Pick<CostManagementResult, 'totalCount'> & {
        records: Maybe<
          Array<
            { __typename?: 'CostManagement' } & Pick<
              CostManagement,
              'id' | 'isDisabled' | 'updatedBy' | 'updatedAt'
            > & {
                item: { __typename?: 'Item' } & Pick<
                  Item,
                  'name' | 'class' | 'department' | 'parentCompany'
                > & { id: Item['extId']; internalId: Item['id'] } & {
                    i18n: Maybe<
                      { __typename?: 'ItemI18n' } & {
                        name: Maybe<
                          { __typename?: 'I18n' } & Pick<
                            I18n,
                            'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                          >
                        >;
                      }
                    >;
                  };
                itemSources: Maybe<
                  Array<
                    { __typename?: 'ItemSource' } & Pick<ItemSource, 'id'> & {
                        opco: { __typename?: 'Opco' } & Pick<Opco, 'id'>;
                        packaging: Maybe<
                          Array<
                            { __typename?: 'Packaging' } & Pick<
                              Packaging,
                              | 'id'
                              | 'perChildPackageQty'
                              | 'supplierItemPackagingNumber'
                              | 'eachUPCs'
                              | 'upcCode'
                            > & {
                                packageType: { __typename?: 'PackageType' } & Pick<
                                  PackageType,
                                  'id' | 'name'
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
                supplier: { __typename?: 'Supplier' } & Pick<Supplier, 'name'> & {
                    id: Supplier['extID'];
                    internalId: Supplier['id'];
                  };
                location: Maybe<
                  { __typename?: 'Location' } & Pick<
                    Location,
                    'name' | 'address' | 'city' | 'stateProvinceRegion' | 'postalCode'
                  > & { id: Location['extId']; internalId: Location['id'] }
                >;
                opco: Maybe<
                  { __typename?: 'Opco' } & Pick<Opco, 'id' | 'stateProvinceRegion'> & {
                      name: Opco['pureName'];
                    }
                >;
                costDates: Maybe<
                  Array<
                    { __typename?: 'CostDate' } & Pick<
                      CostDate,
                      | 'id'
                      | 'costManagementId'
                      | 'effectiveDate'
                      | 'endDate'
                      | 'updatedAt'
                      | 'updatedByDisplayName'
                      | 'status'
                      | 'type'
                      | 'comments'
                      | 'commentedByDisplayName'
                      | 'canAutoApprove'
                    > & {
                        costPackagings: Maybe<
                          Array<
                            { __typename?: 'CostPackaging' } & Pick<
                              CostPackaging,
                              'costDateId' | 'packageTypeId' | 'value' | 'percentageChange'
                            >
                          >
                        >;
                        currency: Maybe<
                          { __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>
                        >;
                      }
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type DeleteCostDatesMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteCostDatesMutation = { __typename?: 'Mutation' } & {
  deleteCostDates: Maybe<
    { __typename?: 'StandardMutationResponse' } & Pick<StandardMutationResponse, 'success'>
  >;
};

export type CostDateFragmentV2Fragment = { __typename?: 'CostDate' } & Pick<
  CostDate,
  | 'id'
  | 'costManagementId'
  | 'effectiveDate'
  | 'endDate'
  | 'updatedAt'
  | 'updatedByDisplayName'
  | 'status'
  | 'type'
  | 'comments'
  | 'commentedByDisplayName'
  | 'canAutoApprove'
> & {
    costPackagings: Maybe<
      Array<
        { __typename?: 'CostPackaging' } & Pick<
          CostPackaging,
          'costDateId' | 'packageTypeId' | 'value' | 'percentageChange'
        >
      >
    >;
    currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
  };

export type CostManagementCostDatesFragmentV2Fragment = { __typename?: 'CostManagement' } & {
  costDates: Maybe<
    Array<
      { __typename?: 'CostDate' } & Pick<
        CostDate,
        | 'id'
        | 'costManagementId'
        | 'effectiveDate'
        | 'endDate'
        | 'updatedAt'
        | 'updatedByDisplayName'
        | 'status'
        | 'type'
        | 'comments'
        | 'commentedByDisplayName'
        | 'canAutoApprove'
      > & {
          costPackagings: Maybe<
            Array<
              { __typename?: 'CostPackaging' } & Pick<
                CostPackaging,
                'costDateId' | 'packageTypeId' | 'value' | 'percentageChange'
              >
            >
          >;
          currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
        }
    >
  >;
};

export type CostManagementFragmentV2Fragment = { __typename?: 'CostManagement' } & Pick<
  CostManagement,
  'id' | 'isDisabled' | 'updatedBy' | 'updatedAt'
> & {
    item: { __typename?: 'Item' } & Pick<
      Item,
      'name' | 'class' | 'department' | 'parentCompany'
    > & { id: Item['extId']; internalId: Item['id'] } & {
        i18n: Maybe<
          { __typename?: 'ItemI18n' } & {
            name: Maybe<
              { __typename?: 'I18n' } & Pick<
                I18n,
                'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
              >
            >;
          }
        >;
      };
    itemSources: Maybe<
      Array<
        { __typename?: 'ItemSource' } & Pick<ItemSource, 'id'> & {
            opco: { __typename?: 'Opco' } & Pick<Opco, 'id'>;
            packaging: Maybe<
              Array<
                { __typename?: 'Packaging' } & Pick<
                  Packaging,
                  | 'id'
                  | 'perChildPackageQty'
                  | 'supplierItemPackagingNumber'
                  | 'eachUPCs'
                  | 'upcCode'
                > & {
                    packageType: { __typename?: 'PackageType' } & Pick<PackageType, 'id' | 'name'>;
                  }
              >
            >;
          }
      >
    >;
    supplier: { __typename?: 'Supplier' } & Pick<Supplier, 'name'> & {
        id: Supplier['extID'];
        internalId: Supplier['id'];
      };
    location: Maybe<
      { __typename?: 'Location' } & Pick<
        Location,
        'name' | 'address' | 'city' | 'stateProvinceRegion' | 'postalCode'
      > & { id: Location['extId']; internalId: Location['id'] }
    >;
    opco: Maybe<
      { __typename?: 'Opco' } & Pick<Opco, 'id' | 'stateProvinceRegion'> & {
          name: Opco['pureName'];
        }
    >;
    costDates: Maybe<
      Array<
        { __typename?: 'CostDate' } & Pick<
          CostDate,
          | 'id'
          | 'costManagementId'
          | 'effectiveDate'
          | 'endDate'
          | 'updatedAt'
          | 'updatedByDisplayName'
          | 'status'
          | 'type'
          | 'comments'
          | 'commentedByDisplayName'
          | 'canAutoApprove'
        > & {
            costPackagings: Maybe<
              Array<
                { __typename?: 'CostPackaging' } & Pick<
                  CostPackaging,
                  'costDateId' | 'packageTypeId' | 'value' | 'percentageChange'
                >
              >
            >;
            currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
          }
      >
    >;
  };

export type UpdateCostDatesApprovalStatusMutationVariables = Exact<{
  input: CostDateApprovalStatusInput;
}>;

export type UpdateCostDatesApprovalStatusMutation = { __typename?: 'Mutation' } & {
  updateCostDatesApprovalStatus: Maybe<
    { __typename?: 'StandardMutationResponse' } & Pick<StandardMutationResponse, 'success'>
  >;
};

export type UpdateCostDatesV2MutationVariables = Exact<{
  input?: Maybe<Array<CostDateInput> | CostDateInput>;
}>;

export type UpdateCostDatesV2Mutation = { __typename?: 'Mutation' } & {
  updateCostDates: Maybe<
    Array<
      { __typename?: 'CostDate' } & Pick<
        CostDate,
        | 'id'
        | 'costManagementId'
        | 'effectiveDate'
        | 'endDate'
        | 'updatedAt'
        | 'updatedByDisplayName'
        | 'status'
        | 'type'
        | 'comments'
        | 'commentedByDisplayName'
        | 'canAutoApprove'
      > & {
          costPackagings: Maybe<
            Array<
              { __typename?: 'CostPackaging' } & Pick<
                CostPackaging,
                'costDateId' | 'packageTypeId' | 'value' | 'percentageChange'
              >
            >
          >;
          currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
        }
    >
  >;
};

export type VendorCostManagementUploadMutationVariables = Exact<{
  input: CostManagementUploadInput;
}>;

export type VendorCostManagementUploadMutation = { __typename?: 'Mutation' } & {
  vendorCostManagementUpload: { __typename?: 'CostManagementUploadResult' } & Pick<
    CostManagementUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type DealUploadMutationVariables = Exact<{
  input: DealUploadInput;
}>;

export type DealUploadMutation = { __typename?: 'Mutation' } & {
  dealUpload: { __typename?: 'DealUploadResult' } & {
    header: { __typename?: 'DealUploadHeader' } & Pick<
      DealUploadHeader,
      | 'description'
      | 'type'
      | 'startDate'
      | 'startTimeZone'
      | 'endDate'
      | 'endTimeZone'
      | 'locations'
      | 'supplierNumber'
      | 'currency'
      | 'error'
    >;
    lines: Array<
      { __typename?: 'DealUploadLine' } & Pick<DealUploadLine, 'eachUpc' | 'error'> & {
          scanDeal: Maybe<
            { __typename?: 'DealUploadLineScanDeal' } & Pick<
              DealUploadLineScanDeal,
              'vendorFundingPerUnit'
            >
          >;
        }
    >;
    summary: { __typename?: 'UploadSummary' } & Pick<UploadSummary, 'total' | 'errors' | 'success'>;
  };
};

export type DealHeaderFragment = { __typename?: 'Deal' } & Pick<
  Deal,
  | 'id'
  | 'offerNumber'
  | 'status'
  | 'rescindStatus'
  | 'description'
  | 'startTime'
  | 'startTimeZone'
  | 'endTime'
  | 'endTimeZone'
  | 'approvedAt'
  | 'updatedAt'
  | 'note'
> & {
    parentCompany: { __typename?: 'ParentCompany' } & Pick<ParentCompany, 'name' | 'extId'>;
    createdBy: { __typename?: 'User' } & Pick<User, 'displayName' | 'email'>;
    type: { __typename?: 'DealType' } & Pick<DealType, 'name'>;
    approvedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName'>>;
    updatedBy: { __typename?: 'User' } & Pick<User, 'displayName'>;
    notedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName'>>;
    pricingChannels: Maybe<
      Array<{ __typename?: 'PricingChannel' } & Pick<PricingChannel, 'id' | 'name'>>
    >;
  };

export type DealsQueryVariables = Exact<{
  pagination?: Maybe<Pagination>;
}>;

export type DealsQuery = { __typename?: 'Query' } & {
  deals: Maybe<
    { __typename?: 'DealResult' } & Pick<DealResult, 'totalCount'> & {
        records: Array<
          { __typename?: 'Deal' } & Pick<
            Deal,
            | 'id'
            | 'offerNumber'
            | 'status'
            | 'rescindStatus'
            | 'description'
            | 'startTime'
            | 'startTimeZone'
            | 'endTime'
            | 'endTimeZone'
            | 'approvedAt'
            | 'updatedAt'
            | 'note'
          > & {
              parentCompany: { __typename?: 'ParentCompany' } & Pick<
                ParentCompany,
                'name' | 'extId'
              >;
              createdBy: { __typename?: 'User' } & Pick<User, 'displayName' | 'email'>;
              type: { __typename?: 'DealType' } & Pick<DealType, 'name'>;
              approvedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName'>>;
              updatedBy: { __typename?: 'User' } & Pick<User, 'displayName'>;
              notedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName'>>;
              pricingChannels: Maybe<
                Array<{ __typename?: 'PricingChannel' } & Pick<PricingChannel, 'id' | 'name'>>
              >;
            }
        >;
      }
  >;
};

export type DealDetailsQueryVariables = Exact<{
  filter: DealFilter;
}>;

export type DealDetailsQuery = { __typename?: 'Query' } & {
  deals: Maybe<
    { __typename?: 'DealResult' } & {
      records: Array<
        { __typename?: 'Deal' } & Pick<
          Deal,
          | 'country'
          | 'id'
          | 'offerNumber'
          | 'status'
          | 'rescindStatus'
          | 'description'
          | 'startTime'
          | 'startTimeZone'
          | 'endTime'
          | 'endTimeZone'
          | 'approvedAt'
          | 'updatedAt'
          | 'note'
        > & {
            currency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'code'>>;
            lines: Array<
              { __typename?: 'DealLine' } & Pick<DealLine, 'id'> & {
                  item: { __typename?: 'Item' } & Pick<Item, 'id' | 'upc' | 'extId' | 'name'>;
                  scanDeal: Maybe<
                    { __typename?: 'DealLineScanDeal' } & Pick<
                      DealLineScanDeal,
                      | 'id'
                      | 'vendorFundingPerUnit'
                      | 'gopuffDollarFundingPerUnit'
                      | 'gopuffPercentageFundingPerUnit'
                    >
                  >;
                }
            >;
            locations: Maybe<
              Array<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name' | 'extId'>>
            >;
            locationGroups: Maybe<
              Array<
                { __typename?: 'LocationGroup' } & Pick<LocationGroup, 'id' | 'name'> & {
                    locations: Array<{ __typename?: 'Location' } & Pick<Location, 'extId'>>;
                  }
              >
            >;
            parentCompany: { __typename?: 'ParentCompany' } & Pick<ParentCompany, 'name' | 'extId'>;
            createdBy: { __typename?: 'User' } & Pick<User, 'displayName' | 'email'>;
            type: { __typename?: 'DealType' } & Pick<DealType, 'name'>;
            approvedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName'>>;
            updatedBy: { __typename?: 'User' } & Pick<User, 'displayName'>;
            notedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName'>>;
            pricingChannels: Maybe<
              Array<{ __typename?: 'PricingChannel' } & Pick<PricingChannel, 'id' | 'name'>>
            >;
          }
      >;
    }
  >;
};

export type PricingChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type PricingChannelsQuery = { __typename?: 'Query' } & {
  pricingChannels: Maybe<
    { __typename?: 'PricingChannelResult' } & {
      records: Array<{ __typename?: 'PricingChannel' } & Pick<PricingChannel, 'id' | 'name'>>;
    }
  >;
};

export type UpdateDealsApprovalStatusMutationVariables = Exact<{
  input: DealApprovalStatusInput;
}>;

export type UpdateDealsApprovalStatusMutation = { __typename?: 'Mutation' } & {
  updateDealsApprovalStatus: { __typename?: 'DealResult' } & Pick<DealResult, 'totalCount'>;
};

export type UpdateDealLineScanDealMutationVariables = Exact<{
  input: DealLineScanDealInput;
}>;

export type UpdateDealLineScanDealMutation = { __typename?: 'Mutation' } & {
  updateDealLineScanDeal: { __typename?: 'DealLineScanDealResult' } & Pick<
    DealLineScanDealResult,
    'gopuffDollarFundingPerUnit' | 'gopuffPercentageFundingPerUnit'
  >;
};

export type UpdateDealsMutationVariables = Exact<{
  input: Array<UpdateDealInput> | UpdateDealInput;
}>;

export type UpdateDealsMutation = { __typename?: 'Mutation' } & {
  updateDeals: { __typename?: 'DealResult' } & Pick<DealResult, 'totalCount'>;
};

export type DestinationAssortmentsQueryVariables = Exact<{
  pagination?: Maybe<Pagination>;
  sorting?: Maybe<DestinationAssortmentSort>;
  filter: DestinationAssortmentFilter;
}>;

export type DestinationAssortmentsQuery = { __typename?: 'Query' } & {
  destinationAssortments: Maybe<
    { __typename?: 'DestinationAssortmentResult' } & Pick<
      DestinationAssortmentResult,
      'totalCount'
    > & {
        assortments: Maybe<
          Array<
            { __typename?: 'DestinationAssortment' } & Pick<
              DestinationAssortment,
              | 'id'
              | 'localCost'
              | 'nationalCost'
              | 'selectedPackagingID'
              | 'maxAllocationQuantity'
              | 'minAllocationQuantity'
              | 'assortmentOverrideState'
              | 'updatedBy'
              | 'updatedAt'
            > & {
                item: Maybe<
                  { __typename?: 'Item' } & Pick<Item, 'id' | 'extId' | 'name'> & {
                      i18n: Maybe<
                        { __typename?: 'ItemI18n' } & {
                          name: Maybe<
                            { __typename?: 'I18n' } & Pick<
                              I18n,
                              'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                            >
                          >;
                        }
                      >;
                    }
                >;
                opco: Maybe<
                  { __typename?: 'Opco' } & Pick<Opco, 'id' | 'name' | 'stateProvinceRegion'>
                >;
                localCostCurrency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id'>>;
                nationalCostCurrency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id'>>;
                source: Maybe<
                  { __typename?: 'Supplier' } & Pick<Supplier, 'id' | 'extID' | 'name'>
                >;
                location: Maybe<
                  { __typename?: 'Location' } & Pick<Location, 'id' | 'extId' | 'name'>
                >;
                packaging: Maybe<
                  Array<
                    { __typename?: 'Packaging' } & Pick<Packaging, 'id'> & {
                        packageType: { __typename?: 'PackageType' } & Pick<PackageType, 'name'>;
                      }
                  >
                >;
                velocityCategory: Maybe<
                  { __typename?: 'VelocityCategory' } & Pick<VelocityCategory, 'id'>
                >;
                managedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName'>>;
                costs: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'Cost' } & Pick<Cost, 'currencyId' | 'packageTypeID' | 'value'>
                    >
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type DestinationAssortmentsDownloadQueryVariables = Exact<{
  filter: DestinationAssortmentsDownloadFilter;
}>;

export type DestinationAssortmentsDownloadQuery = { __typename?: 'Query' } & {
  destinationAssortmentsDownload: Maybe<
    { __typename?: 'DestinationAssortmentsDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type DestinationAssortmentsItemOptionsQueryVariables = Exact<{
  pagination: Pagination;
  filter: DestinationAssortmentFilter;
}>;

export type DestinationAssortmentsItemOptionsQuery = { __typename?: 'Query' } & {
  destinationAssortments: Maybe<
    { __typename?: 'DestinationAssortmentResult' } & {
      assortments: Maybe<
        Array<
          { __typename?: 'DestinationAssortment' } & {
            item: Maybe<{ __typename?: 'Item' } & Pick<Item, 'id' | 'name'>>;
          }
        >
      >;
    }
  >;
};

export type DestinationAssortmentsLocationOptionsQueryVariables = Exact<{
  pagination: Pagination;
  filter: DestinationAssortmentFilter;
}>;

export type DestinationAssortmentsLocationOptionsQuery = { __typename?: 'Query' } & {
  destinationAssortments: Maybe<
    { __typename?: 'DestinationAssortmentResult' } & {
      assortments: Maybe<
        Array<
          { __typename?: 'DestinationAssortment' } & {
            location: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>>;
          }
        >
      >;
    }
  >;
};

export type DestinationAssortmentsOpcoOptionsQueryVariables = Exact<{
  pagination: Pagination;
  filter: DestinationAssortmentFilter;
}>;

export type DestinationAssortmentsOpcoOptionsQuery = { __typename?: 'Query' } & {
  destinationAssortments: Maybe<
    { __typename?: 'DestinationAssortmentResult' } & {
      assortments: Maybe<
        Array<
          { __typename?: 'DestinationAssortment' } & {
            opco: Maybe<{ __typename?: 'Opco' } & Pick<Opco, 'id' | 'name'>>;
          }
        >
      >;
    }
  >;
};

export type DestinationAssortmentsOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type DestinationAssortmentsOptionsQuery = { __typename?: 'Query' } & {
  velocityCategories: Array<
    { __typename?: 'VelocityCategory' } & Pick<VelocityCategory, 'id' | 'name'>
  >;
  packageTypes: Array<{ __typename?: 'PackageType' } & Pick<PackageType, 'id' | 'name'>>;
};

export type DestinationAssortmentsSourceOptionsQueryVariables = Exact<{
  pagination: Pagination;
  filter: DestinationAssortmentFilter;
}>;

export type DestinationAssortmentsSourceOptionsQuery = { __typename?: 'Query' } & {
  destinationAssortments: Maybe<
    { __typename?: 'DestinationAssortmentResult' } & {
      assortments: Maybe<
        Array<
          { __typename?: 'DestinationAssortment' } & {
            source: Maybe<{ __typename?: 'Supplier' } & Pick<Supplier, 'id' | 'name'>>;
          }
        >
      >;
    }
  >;
};

export type DestinationAssortmentsUploadMutationVariables = Exact<{
  input: DestinationAssortmentsUploadInput;
}>;

export type DestinationAssortmentsUploadMutation = { __typename?: 'Mutation' } & {
  destinationAssortmentsUpload: { __typename?: 'DestinationAssortmentsUploadResult' } & Pick<
    DestinationAssortmentsUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type LastDestinationAssortmentTimeQueryVariables = Exact<{ [key: string]: never }>;

export type LastDestinationAssortmentTimeQuery = { __typename?: 'Query' } & Pick<
  Query,
  'lastDestinationAssortmentTime'
>;

export type UpdateAssortmentMutationVariables = Exact<{ [key: string]: never }>;

export type UpdateAssortmentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'validateDestinationAssortments'
>;

export type UpdateDestinationAssortmentsMutationVariables = Exact<{
  input: DestinationAssortmentsInput;
}>;

export type UpdateDestinationAssortmentsMutation = { __typename?: 'Mutation' } & {
  updateDestinationAssortments: Maybe<
    { __typename?: 'DestinationAssortmentResult' } & {
      assortments: Maybe<
        Array<
          { __typename?: 'DestinationAssortment' } & Pick<
            DestinationAssortment,
            | 'id'
            | 'maxAllocationQuantity'
            | 'minAllocationQuantity'
            | 'assortmentOverrideState'
            | 'selectedPackagingID'
            | 'localCost'
            | 'nationalCost'
            | 'updatedBy'
            | 'updatedAt'
          > & {
              packaging: Maybe<
                Array<
                  { __typename?: 'Packaging' } & Pick<Packaging, 'id'> & {
                      packageType: { __typename?: 'PackageType' } & Pick<PackageType, 'name'>;
                    }
                >
              >;
              localCostCurrency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id'>>;
              nationalCostCurrency: Maybe<{ __typename?: 'Currency' } & Pick<Currency, 'id'>>;
              velocityCategory: Maybe<
                { __typename?: 'VelocityCategory' } & Pick<VelocityCategory, 'id'>
              >;
              costs: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Cost' } & Pick<Cost, 'currencyId' | 'packageTypeID' | 'value'>
                  >
                >
              >;
            }
        >
      >;
    }
  >;
};

export type ItemQueryVariables = Exact<{
  pagination: Pagination;
  filter: ItemFilter;
}>;

export type ItemQuery = { __typename?: 'Query' } & {
  items: Maybe<
    { __typename?: 'ItemResult' } & Pick<ItemResult, 'totalCount'> & {
        items: Maybe<
          Array<
            { __typename?: 'Item' } & Pick<
              Item,
              | 'id'
              | 'extId'
              | 'name'
              | 'category'
              | 'class'
              | 'brand'
              | 'department'
              | 'parentCompany'
              | 'isAlcohol'
              | 'isTobacco'
              | 'isBom'
              | 'upc'
              | 'pullByDate'
              | 'markdownDays'
              | 'stopBuyingFlag'
              | 'alcoholByVolume'
              | 'guaranteedShelfLife'
              | 'subClass'
              | 'productType'
              | 'captureExpiryMethod'
              | 'acceptableShelfLife'
              | 'markdownItem'
              | 'baggableAtPack'
              | 'binnableAtPack'
              | 'packingConstraint'
              | 'percentageOverageAllowed'
              | 'ignoreBlockOverReceipt'
              | 'requireProduceBag'
              | 'thawToSellEnabled'
              | 'thawReplenishmentMinQty'
              | 'thawReplenishmentMaxQty'
              | 'thawTimeHours'
              | 'thawExpiryDays'
              | 'sellableTemperature'
              | 'storageTemperature'
              | 'updatedAt'
              | 'updatedBy'
              | 'itemCapabilityUpdatedAt'
              | 'itemCapabilityUpdatedBy'
              | 'itemCustomAssortmentUpdatedAt'
              | 'itemCustomAssortmentUpdatedBy'
            > & {
                i18n: Maybe<
                  { __typename?: 'ItemI18n' } & {
                    name: Maybe<
                      { __typename?: 'I18n' } & Pick<
                        I18n,
                        'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                      >
                    >;
                  }
                >;
                customAssortments: Maybe<
                  Array<
                    { __typename?: 'CustomAssortment' } & Pick<
                      CustomAssortment,
                      'id' | 'updatedAt' | 'updatedBy'
                    >
                  >
                >;
                capabilities: Maybe<
                  Array<
                    { __typename?: 'Capability' } & Pick<
                      Capability,
                      'id' | 'updatedAt' | 'updatedBy'
                    >
                  >
                >;
                nationalAssortment: Maybe<
                  { __typename?: 'NationalAssortment' } & Pick<NationalAssortment, 'id' | 'name'>
                >;
                alcoholType: Maybe<{ __typename?: 'AlcoholType' } & Pick<AlcoholType, 'id'>>;
                tobaccoType: Maybe<{ __typename?: 'TobaccoType' } & Pick<TobaccoType, 'id'>>;
                manufacturingType: Maybe<
                  { __typename?: 'ManufacturingType' } & Pick<ManufacturingType, 'id'>
                >;
              }
          >
        >;
      }
  >;
};

export type ItemDownloadQueryVariables = Exact<{
  filter?: Maybe<ItemDownloadFilter>;
}>;

export type ItemDownloadQuery = { __typename?: 'Query' } & {
  itemDownload: Maybe<
    { __typename?: 'ItemDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type ItemOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ItemOptionsQuery = { __typename?: 'Query' } & {
  customAssortments: Array<
    { __typename?: 'CustomAssortment' } & Pick<CustomAssortment, 'id' | 'name'>
  >;
  capabilities: Array<{ __typename?: 'Capability' } & Pick<Capability, 'id' | 'name'>>;
  tobaccoTypes: Array<
    { __typename?: 'TobaccoType' } & Pick<TobaccoType, 'id'> & {
        name: TobaccoType['restrictionCode'];
      }
  >;
  nationalAssortments: Array<
    { __typename?: 'NationalAssortment' } & Pick<NationalAssortment, 'id' | 'name'>
  >;
  alcoholTypes: Array<
    { __typename?: 'AlcoholType' } & Pick<AlcoholType, 'id' | 'name'> & {
        volumes: Array<
          { __typename?: 'AlcoholVolume' } & Pick<AlcoholVolume, 'id' | 'volume' | 'description'>
        >;
      }
  >;
  manufacturingTypes: Array<
    { __typename?: 'ManufacturingType' } & Pick<ManufacturingType, 'id' | 'name'>
  >;
};

export type ItemUploadMutationVariables = Exact<{
  input: ItemUploadInput;
}>;

export type ItemUploadMutation = { __typename?: 'Mutation' } & {
  itemUpload: { __typename?: 'ItemUploadResult' } & Pick<
    ItemUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type UpdateItemMutationVariables = Exact<{
  input: ItemsInput;
}>;

export type UpdateItemMutation = { __typename?: 'Mutation' } & {
  updateItems: Maybe<
    { __typename?: 'ItemResult' } & {
      items: Maybe<
        Array<
          { __typename?: 'Item' } & Pick<
            Item,
            | 'id'
            | 'extId'
            | 'name'
            | 'category'
            | 'class'
            | 'brand'
            | 'department'
            | 'parentCompany'
            | 'isAlcohol'
            | 'isTobacco'
            | 'isBom'
            | 'upc'
            | 'pullByDate'
            | 'markdownDays'
            | 'stopBuyingFlag'
            | 'alcoholByVolume'
            | 'guaranteedShelfLife'
            | 'subClass'
            | 'productType'
            | 'captureExpiryMethod'
            | 'acceptableShelfLife'
            | 'markdownItem'
            | 'baggableAtPack'
            | 'binnableAtPack'
            | 'packingConstraint'
            | 'percentageOverageAllowed'
            | 'ignoreBlockOverReceipt'
            | 'requireProduceBag'
            | 'thawToSellEnabled'
            | 'thawReplenishmentMinQty'
            | 'thawReplenishmentMaxQty'
            | 'thawTimeHours'
            | 'thawExpiryDays'
            | 'sellableTemperature'
            | 'storageTemperature'
            | 'updatedAt'
            | 'updatedBy'
            | 'itemCustomAssortmentUpdatedAt'
            | 'itemCustomAssortmentUpdatedBy'
            | 'itemCapabilityUpdatedAt'
            | 'itemCapabilityUpdatedBy'
          > & {
              customAssortments: Maybe<
                Array<
                  { __typename?: 'CustomAssortment' } & Pick<
                    CustomAssortment,
                    'id' | 'updatedAt' | 'updatedBy'
                  >
                >
              >;
              capabilities: Maybe<
                Array<
                  { __typename?: 'Capability' } & Pick<Capability, 'id' | 'updatedAt' | 'updatedBy'>
                >
              >;
              nationalAssortment: Maybe<
                { __typename?: 'NationalAssortment' } & Pick<NationalAssortment, 'id' | 'name'>
              >;
              alcoholType: Maybe<{ __typename?: 'AlcoholType' } & Pick<AlcoholType, 'id'>>;
              tobaccoType: Maybe<{ __typename?: 'TobaccoType' } & Pick<TobaccoType, 'id'>>;
              manufacturingType: Maybe<
                { __typename?: 'ManufacturingType' } & Pick<ManufacturingType, 'id'>
              >;
            }
        >
      >;
    }
  >;
};

export type GraphJinItemExtraSubClassDefaultQueryVariables = Exact<{
  filter: GraphJinItemExtraSubClassDefaultFilter;
  pagination: Pagination;
}>;

export type GraphJinItemExtraSubClassDefaultQuery = { __typename?: 'Query' } & {
  graphJinItemExtraSubClassDefault: Array<
    { __typename?: 'GraphJinItemExtraSubClassDefault' } & Pick<
      GraphJinItemExtraSubClassDefault,
      | 'id'
      | 'subClass'
      | 'pullByDate'
      | 'markdownDays'
      | 'captureExpiry'
      | 'acceptableShelfLife'
      | 'packingConstraint'
    >
  >;
};

export type GraphJinItemExtraSubClassDefaultUpdateMutationVariables = Exact<{
  update?: Maybe<GraphJinItemExtraSubClassDefaultInsertInput>;
  id?: Maybe<Scalars['ID']>;
}>;

export type GraphJinItemExtraSubClassDefaultUpdateMutation = { __typename?: 'Mutation' } & {
  graphJinItemExtraSubClassDefault: Array<
    { __typename?: 'GraphJinItemExtraSubClassDefault' } & Pick<
      GraphJinItemExtraSubClassDefault,
      | 'id'
      | 'subClass'
      | 'pullByDate'
      | 'markdownDays'
      | 'captureExpiry'
      | 'acceptableShelfLife'
      | 'packingConstraint'
    >
  >;
};

export type GraphJinItemSubClassQueryVariables = Exact<{
  filter: GraphJinItemSubClassFilter;
}>;

export type GraphJinItemSubClassQuery = { __typename?: 'Query' } & {
  graphJinItemSubClass: Array<
    { __typename?: 'GraphJinItemSubClass' } & Pick<GraphJinItemSubClass, 'name'> & {
        itemClass: Maybe<
          { __typename?: 'GraphJinItemClass' } & Pick<GraphJinItemClass, 'name'> & {
              itemDepartment: Maybe<
                { __typename?: 'GraphJinItemDepartment' } & Pick<GraphJinItemDepartment, 'name'>
              >;
            }
        >;
      }
  >;
};

export type ItemSourceQueryVariables = Exact<{
  pagination: Pagination;
  filter: ItemSourceFilter;
}>;

export type ItemSourceQuery = { __typename?: 'Query' } & {
  itemSources: Maybe<
    { __typename?: 'ItemSourceResult' } & Pick<ItemSourceResult, 'totalCount'> & {
        items: Maybe<
          Array<
            { __typename?: 'ItemSource' } & Pick<
              ItemSource,
              | 'id'
              | 'isBreakable'
              | 'breakingFromPackageTypeID'
              | 'breakingToPackageTypeID'
              | 'breakingThreshold'
              | 'isLotSizeEnabled'
              | 'lotSizeMin'
              | 'lotSizeIncrement'
            > & {
                item: { __typename?: 'Item' } & Pick<Item, 'id' | 'extId' | 'name'> & {
                    i18n: Maybe<
                      { __typename?: 'ItemI18n' } & {
                        name: Maybe<
                          { __typename?: 'I18n' } & Pick<
                            I18n,
                            'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                          >
                        >;
                      }
                    >;
                  };
                opco: { __typename?: 'Opco' } & Pick<
                  Opco,
                  'id' | 'name' | 'stateProvinceRegion'
                > & { supplier: Maybe<{ __typename?: 'Supplier' } & Pick<Supplier, 'extID'>> };
                packaging: Maybe<
                  Array<
                    { __typename?: 'Packaging' } & Pick<
                      Packaging,
                      | 'id'
                      | 'declaredHeight'
                      | 'declaredLength'
                      | 'declaredWidth'
                      | 'declaredLengthUnits'
                      | 'declaredWeight'
                      | 'declaredWeightUnits'
                      | 'declaredTi'
                      | 'declaredHi'
                      | 'measuredHeight'
                      | 'measuredLength'
                      | 'measuredWidth'
                      | 'measuredLengthUnits'
                      | 'measuredWeight'
                      | 'measuredVolume'
                      | 'declaredVolume'
                      | 'measuredWeightUnits'
                      | 'measuredTi'
                      | 'measuredHi'
                      | 'perChildPackageQty'
                      | 'eachUPCs'
                      | 'upcCode'
                      | 'supplierNumber'
                      | 'maxAllocationQuantity'
                      | 'roundingThreshold'
                      | 'updatedAt'
                      | 'updatedBy'
                    > & {
                        packageType: { __typename?: 'PackageType' } & Pick<
                          PackageType,
                          'id' | 'name'
                        >;
                      }
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type ItemSourceDownloadQueryVariables = Exact<{
  filter: ItemSourceDownloadFilter;
}>;

export type ItemSourceDownloadQuery = { __typename?: 'Query' } & {
  itemSourceDownload: Maybe<
    { __typename?: 'ItemSourceDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type ItemSourceUploadMutationVariables = Exact<{
  input: ItemSourceUploadInput;
}>;

export type ItemSourceUploadMutation = { __typename?: 'Mutation' } & {
  itemSourceUpload: { __typename?: 'ItemSourceUploadResult' } & Pick<
    ItemSourceUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type UpdateItemSourceMutationVariables = Exact<{
  input: ItemSourcesInput;
}>;

export type UpdateItemSourceMutation = { __typename?: 'Mutation' } & {
  updateItemSources: Maybe<
    { __typename?: 'ItemSourceResult' } & Pick<ItemSourceResult, 'totalCount'> & {
        items: Maybe<
          Array<
            { __typename?: 'ItemSource' } & Pick<
              ItemSource,
              | 'id'
              | 'isBreakable'
              | 'breakingFromPackageTypeID'
              | 'breakingToPackageTypeID'
              | 'breakingThreshold'
              | 'isLotSizeEnabled'
              | 'lotSizeMin'
              | 'lotSizeIncrement'
            > & {
                item: { __typename?: 'Item' } & Pick<Item, 'id' | 'extId' | 'name'>;
                opco: { __typename?: 'Opco' } & Pick<Opco, 'id' | 'name'> & {
                    supplier: Maybe<{ __typename?: 'Supplier' } & Pick<Supplier, 'extID'>>;
                  };
                packaging: Maybe<
                  Array<
                    { __typename?: 'Packaging' } & Pick<
                      Packaging,
                      | 'id'
                      | 'declaredHeight'
                      | 'declaredLength'
                      | 'declaredWidth'
                      | 'declaredLengthUnits'
                      | 'declaredWeight'
                      | 'declaredWeightUnits'
                      | 'declaredTi'
                      | 'declaredHi'
                      | 'measuredHeight'
                      | 'measuredLength'
                      | 'measuredWidth'
                      | 'measuredLengthUnits'
                      | 'measuredWeight'
                      | 'measuredVolume'
                      | 'declaredVolume'
                      | 'measuredWeightUnits'
                      | 'measuredTi'
                      | 'measuredHi'
                      | 'perChildPackageQty'
                      | 'eachUPCs'
                      | 'upcCode'
                      | 'supplierNumber'
                      | 'maxAllocationQuantity'
                      | 'roundingThreshold'
                      | 'updatedBy'
                      | 'updatedAt'
                    > & {
                        packageType: { __typename?: 'PackageType' } & Pick<
                          PackageType,
                          'id' | 'name'
                        >;
                      }
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type LocationQueryVariables = Exact<{
  pagination: Pagination;
  filter: LocationFilter;
}>;

export type LocationQuery = { __typename?: 'Query' } & {
  locations: Maybe<
    { __typename?: 'LocationResult' } & Pick<LocationResult, 'totalCount'> & {
        locations: Maybe<
          Array<
            { __typename?: 'Location' } & Pick<
              Location,
              | 'id'
              | 'name'
              | 'isThirdParty'
              | 'stopBuying'
              | 'address'
              | 'type'
              | 'openFrom'
              | 'openTo'
              | 'extId'
              | 'city'
              | 'stateProvinceRegion'
              | 'postalCode'
              | 'metroRegion'
              | 'districtId'
              | 'regionId'
              | 'ageOutThreshold'
              | 'updatedAt'
              | 'updatedBy'
              | 'locationExtraUpdatedAt'
              | 'locationExtraUpdatedBy'
              | 'locationCapabilitiesUpdatedAt'
              | 'locationCapabilitiesUpdatedBy'
              | 'locationAlcoholCapabilitiesUpdatedAt'
              | 'locationAlcoholCapabilitiesUpdatedBy'
              | 'locationCustomAssortmentsUpdatedAt'
              | 'locationCustomAssortmentsUpdatedBy'
              | 'locationTobaccoCapabilitiesUpdatedAt'
              | 'locationTobaccoCapabilitiesUpdatedBy'
            > & {
                nationalAssortment: Maybe<
                  { __typename?: 'NationalAssortment' } & Pick<NationalAssortment, 'id' | 'name'>
                >;
                allocationPriority: Maybe<
                  { __typename?: 'AllocationPriority' } & Pick<AllocationPriority, 'id' | 'name'>
                >;
                locationCustomAssortments: Maybe<
                  Array<
                    { __typename?: 'CustomAssortment' } & Pick<
                      CustomAssortment,
                      'id' | 'updatedAt' | 'updatedBy'
                    >
                  >
                >;
                locationAlcoholCapabilities: Maybe<
                  Array<
                    { __typename: 'AlcoholType' } & Pick<
                      AlcoholType,
                      'id' | 'name' | 'updatedAt' | 'updatedBy'
                    > & {
                        volumes: Array<
                          { __typename: 'AlcoholVolume' } & Pick<
                            AlcoholVolume,
                            'id' | 'volume' | 'description'
                          >
                        >;
                      }
                  >
                >;
                locationTobaccoCapabilities: Maybe<
                  Array<
                    { __typename?: 'TobaccoType' } & Pick<
                      TobaccoType,
                      'id' | 'updatedAt' | 'updatedBy'
                    >
                  >
                >;
                locationCapabilities: Maybe<
                  Array<
                    { __typename?: 'Capability' } & Pick<
                      Capability,
                      'id' | 'updatedAt' | 'updatedBy'
                    >
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type LocationDownloadQueryVariables = Exact<{
  filter?: Maybe<LocationDownloadFilter>;
}>;

export type LocationDownloadQuery = { __typename?: 'Query' } & {
  locationDownload: Maybe<
    { __typename?: 'LocationDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type LocationOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type LocationOptionsQuery = { __typename?: 'Query' } & {
  nationalAssortments: Array<
    { __typename?: 'NationalAssortment' } & Pick<NationalAssortment, 'id' | 'name'>
  >;
  allocationPriorities: Array<
    { __typename?: 'AllocationPriority' } & Pick<AllocationPriority, 'id' | 'name'>
  >;
  customAssortments: Array<
    { __typename?: 'CustomAssortment' } & Pick<CustomAssortment, 'id' | 'name'>
  >;
  alcoholTypes: Array<
    { __typename?: 'AlcoholType' } & Pick<AlcoholType, 'id' | 'name'> & {
        volumes: Array<
          { __typename?: 'AlcoholVolume' } & Pick<AlcoholVolume, 'id' | 'volume' | 'description'>
        >;
      }
  >;
  tobaccoTypes: Array<
    { __typename?: 'TobaccoType' } & Pick<TobaccoType, 'id'> & {
        name: TobaccoType['restrictionCode'];
      }
  >;
  capabilities: Array<{ __typename?: 'Capability' } & Pick<Capability, 'id' | 'name'>>;
};

export type LocationUploadMutationVariables = Exact<{
  input: LocationUploadInput;
}>;

export type LocationUploadMutation = { __typename?: 'Mutation' } & {
  locationUpload: { __typename?: 'LocationUploadResult' } & Pick<
    LocationUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type UpdateLocationMutationVariables = Exact<{
  input: LocationsInput;
}>;

export type UpdateLocationMutation = { __typename?: 'Mutation' } & {
  updateLocations: Maybe<
    { __typename?: 'LocationResult' } & {
      locations: Maybe<
        Array<
          { __typename?: 'Location' } & Pick<
            Location,
            | 'id'
            | 'name'
            | 'isThirdParty'
            | 'stopBuying'
            | 'address'
            | 'city'
            | 'stateProvinceRegion'
            | 'postalCode'
            | 'type'
            | 'openFrom'
            | 'openTo'
            | 'extId'
            | 'ageOutThreshold'
            | 'updatedAt'
            | 'updatedBy'
            | 'locationExtraUpdatedBy'
            | 'locationExtraUpdatedAt'
            | 'locationCapabilitiesUpdatedAt'
            | 'locationCapabilitiesUpdatedBy'
            | 'locationAlcoholCapabilitiesUpdatedAt'
            | 'locationAlcoholCapabilitiesUpdatedBy'
            | 'locationCustomAssortmentsUpdatedAt'
            | 'locationCustomAssortmentsUpdatedBy'
            | 'locationTobaccoCapabilitiesUpdatedAt'
            | 'locationTobaccoCapabilitiesUpdatedBy'
          > & {
              nationalAssortment: Maybe<
                { __typename?: 'NationalAssortment' } & Pick<NationalAssortment, 'id' | 'name'>
              >;
              allocationPriority: Maybe<
                { __typename?: 'AllocationPriority' } & Pick<AllocationPriority, 'id' | 'name'>
              >;
              locationCustomAssortments: Maybe<
                Array<
                  { __typename?: 'CustomAssortment' } & Pick<
                    CustomAssortment,
                    'id' | 'updatedAt' | 'updatedBy'
                  >
                >
              >;
              locationAlcoholCapabilities: Maybe<
                Array<
                  { __typename: 'AlcoholType' } & Pick<
                    AlcoholType,
                    'id' | 'name' | 'updatedAt' | 'updatedBy'
                  > & {
                      volumes: Array<
                        { __typename: 'AlcoholVolume' } & Pick<
                          AlcoholVolume,
                          'id' | 'volume' | 'description'
                        >
                      >;
                    }
                >
              >;
              locationTobaccoCapabilities: Maybe<
                Array<
                  { __typename?: 'TobaccoType' } & Pick<
                    TobaccoType,
                    'id' | 'updatedAt' | 'updatedBy'
                  >
                >
              >;
              locationCapabilities: Maybe<
                Array<
                  { __typename?: 'Capability' } & Pick<Capability, 'id' | 'updatedAt' | 'updatedBy'>
                >
              >;
            }
        >
      >;
    }
  >;
};

export type GraphJinLeadTimeOverrideQueryVariables = Exact<{
  filter: GraphJinLeadTimeOverrideFilter;
}>;

export type GraphJinLeadTimeOverrideQuery = { __typename?: 'Query' } & {
  graphJinLeadTimeOverride: Array<
    { __typename?: 'GraphJinLeadTimeOverride' } & Pick<
      GraphJinLeadTimeOverride,
      | 'id'
      | 'transportationLaneId'
      | 'weekStartDate'
      | 'monLeadTime'
      | 'tueLeadTime'
      | 'wedLeadTime'
      | 'thuLeadTime'
      | 'friLeadTime'
      | 'satLeadTime'
      | 'sunLeadTime'
    >
  >;
};

export type GraphJinLeadTimeOverrideDeleteMutationVariables = Exact<{
  deleting?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
}>;

export type GraphJinLeadTimeOverrideDeleteMutation = { __typename?: 'Mutation' } & {
  graphJinLeadTimeOverride: Array<
    { __typename?: 'GraphJinLeadTimeOverride' } & Pick<GraphJinLeadTimeOverride, 'id'>
  >;
};

export type GraphJinLeadTimeOverrideInsertMutationVariables = Exact<{
  insert?: Maybe<Array<GraphJinLeadTimeOverrideInsertInput> | GraphJinLeadTimeOverrideInsertInput>;
}>;

export type GraphJinLeadTimeOverrideInsertMutation = { __typename?: 'Mutation' } & {
  graphJinLeadTimeOverride: Array<
    { __typename?: 'GraphJinLeadTimeOverride' } & Pick<
      GraphJinLeadTimeOverride,
      | 'id'
      | 'transportationLaneId'
      | 'weekStartDate'
      | 'monLeadTime'
      | 'tueLeadTime'
      | 'wedLeadTime'
      | 'thuLeadTime'
      | 'friLeadTime'
      | 'satLeadTime'
      | 'sunLeadTime'
    >
  >;
};

export type GraphJinLeadTimeOverrideUpdateMutationVariables = Exact<{
  update?: Maybe<GraphJinLeadTimeOverrideInsertInput>;
  id?: Maybe<Scalars['ID']>;
}>;

export type GraphJinLeadTimeOverrideUpdateMutation = { __typename?: 'Mutation' } & {
  graphJinLeadTimeOverride: Array<
    { __typename?: 'GraphJinLeadTimeOverride' } & Pick<
      GraphJinLeadTimeOverride,
      | 'id'
      | 'transportationLaneId'
      | 'weekStartDate'
      | 'monLeadTime'
      | 'tueLeadTime'
      | 'wedLeadTime'
      | 'thuLeadTime'
      | 'friLeadTime'
      | 'satLeadTime'
      | 'sunLeadTime'
    >
  >;
};

export type SourceDestinationQueryVariables = Exact<{
  pagination: Pagination;
  filter: SourceDestinationFilter;
}>;

export type SourceDestinationQuery = { __typename?: 'Query' } & {
  sourceDestinations: Maybe<
    { __typename?: 'SourceDestinationResult' } & Pick<SourceDestinationResult, 'totalCount'> & {
        destinations: Maybe<
          Array<
            { __typename?: 'SourceDestination' } & Pick<
              SourceDestination,
              | 'id'
              | 'accountNumber'
              | 'isActive'
              | 'monLeadTime'
              | 'tueLeadTime'
              | 'wedLeadTime'
              | 'thuLeadTime'
              | 'friLeadTime'
              | 'satLeadTime'
              | 'sunLeadTime'
              | 'autoRelease'
              | 'gopuffTransport'
              | 'transitType'
              | 'deliveryWindowFrom'
              | 'deliveryWindowTo'
              | 'notActiveSince'
              | 'createdAt'
              | 'updatedAt'
              | 'updatedBy'
              | 'orderFrequency'
              | 'fillKillThreshold'
              | 'minimumOrderValue'
              | 'movCurrencyId'
              | 'minimumOrderQuantity'
              | 'moqOrderingUomId'
              | 'minimumOrderWeight'
              | 'mowSystemOfMeasureId'
              | 'autoOrder'
              | 'valueOveragePercent'
              | 'valueOverageValue'
              | 'valueUnderagePercent'
              | 'valueUnderageValue'
              | 'quantityOveragePercent'
              | 'quantityOverageCount'
              | 'quantityUnderagePercent'
              | 'quantityUnderageCount'
            > & {
                source: Maybe<
                  | ({ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>)
                  | ({ __typename?: 'Opco' } & Pick<Opco, 'id' | 'name' | 'stateProvinceRegion'> & {
                        supplier: Maybe<{ __typename?: 'Supplier' } & Pick<Supplier, 'extID'>>;
                      })
                >;
                location: Maybe<
                  { __typename?: 'Location' } & Pick<Location, 'id' | 'extId' | 'name'>
                >;
                capacities: Maybe<
                  Array<
                    { __typename?: 'ContainerCapacity' } & Pick<
                      ContainerCapacity,
                      'id' | 'containerMin' | 'containerMax'
                    > & { containerUom: { __typename?: 'ContainerUom' } & Pick<ContainerUom, 'id'> }
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type SourceDestinationDownloadQueryVariables = Exact<{
  filter: SourceDestinationDownloadFilter;
}>;

export type SourceDestinationDownloadQuery = { __typename?: 'Query' } & {
  sourceDestinationDownload: Maybe<
    { __typename?: 'SourceDestinationDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type SourceDestinationOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type SourceDestinationOptionsQuery = { __typename?: 'Query' } & {
  containerUom: Array<{ __typename?: 'ContainerUom' } & Pick<ContainerUom, 'id' | 'name'>>;
};

export type SourceDestinationUploadMutationVariables = Exact<{
  input: SourceDestinationUploadInput;
}>;

export type SourceDestinationUploadMutation = { __typename?: 'Mutation' } & {
  sourceDestinationUpload: { __typename?: 'SourceDestinationUploadResult' } & Pick<
    SourceDestinationUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type UpdateSourceDestinationMutationVariables = Exact<{
  input: SourceDestinationsInput;
}>;

export type UpdateSourceDestinationMutation = { __typename?: 'Mutation' } & {
  updateSourceDestinations: Maybe<
    { __typename?: 'SourceDestinationResult' } & {
      destinations: Maybe<
        Array<
          { __typename?: 'SourceDestination' } & Pick<
            SourceDestination,
            | 'id'
            | 'isActive'
            | 'fillKillThreshold'
            | 'monLeadTime'
            | 'tueLeadTime'
            | 'wedLeadTime'
            | 'thuLeadTime'
            | 'friLeadTime'
            | 'satLeadTime'
            | 'sunLeadTime'
            | 'autoRelease'
            | 'gopuffTransport'
            | 'transitType'
            | 'deliveryWindowFrom'
            | 'deliveryWindowTo'
            | 'notActiveSince'
            | 'accountNumber'
            | 'createdAt'
            | 'updatedAt'
            | 'updatedBy'
            | 'orderFrequency'
            | 'minimumOrderValue'
            | 'movCurrencyId'
            | 'minimumOrderQuantity'
            | 'moqOrderingUomId'
            | 'minimumOrderWeight'
            | 'mowSystemOfMeasureId'
            | 'autoOrder'
            | 'valueOveragePercent'
            | 'valueOverageValue'
            | 'valueUnderagePercent'
            | 'valueUnderageValue'
            | 'quantityOveragePercent'
            | 'quantityOverageCount'
            | 'quantityUnderagePercent'
            | 'quantityUnderageCount'
          > & {
              capacities: Maybe<
                Array<
                  { __typename?: 'ContainerCapacity' } & Pick<
                    ContainerCapacity,
                    'id' | 'containerMin' | 'containerMax'
                  > & { containerUom: { __typename?: 'ContainerUom' } & Pick<ContainerUom, 'id'> }
                >
              >;
            }
        >
      >;
    }
  >;
};

export type MassEditSupplierAssortmentsMutationVariables = Exact<{
  input: SupplierAssortmentsMassEditInput;
}>;

export type MassEditSupplierAssortmentsMutation = { __typename?: 'Mutation' } & {
  massEditSupplierAssortments: Maybe<
    { __typename?: 'SupplierAssortmentResult' } & Pick<SupplierAssortmentResult, 'totalCount'> & {
        assortments: Maybe<
          Array<
            { __typename?: 'SupplierAssortment' } & Pick<SupplierAssortment, 'id'> & {
                managedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName' | 'id'>>;
                item: Maybe<
                  { __typename?: 'Item' } & Pick<Item, 'id' | 'extId' | 'name' | 'upc'> & {
                      i18n: Maybe<
                        { __typename?: 'ItemI18n' } & {
                          name: Maybe<
                            { __typename?: 'I18n' } & Pick<
                              I18n,
                              'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                            >
                          >;
                        }
                      >;
                    }
                >;
                opco: Maybe<
                  { __typename?: 'Opco' } & Pick<
                    Opco,
                    'id' | 'ext_id' | 'name' | 'stateProvinceRegion' | 'updatedBy' | 'updatedAt'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type SupplierAssortmentQueryVariables = Exact<{
  pagination: Pagination;
  sorting: SupplierAssortmentSort;
  filter: SupplierAssortmentFilter;
}>;

export type SupplierAssortmentQuery = { __typename?: 'Query' } & {
  supplierAssortments: Maybe<
    { __typename?: 'SupplierAssortmentResult' } & Pick<SupplierAssortmentResult, 'totalCount'> & {
        assortments: Maybe<
          Array<
            { __typename?: 'SupplierAssortment' } & Pick<SupplierAssortment, 'id'> & {
                managedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'displayName' | 'id'>>;
                item: Maybe<
                  { __typename?: 'Item' } & Pick<Item, 'id' | 'extId' | 'name' | 'upc'> & {
                      i18n: Maybe<
                        { __typename?: 'ItemI18n' } & {
                          name: Maybe<
                            { __typename?: 'I18n' } & Pick<
                              I18n,
                              'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                            >
                          >;
                        }
                      >;
                    }
                >;
                opco: Maybe<
                  { __typename?: 'Opco' } & Pick<
                    Opco,
                    'id' | 'ext_id' | 'name' | 'stateProvinceRegion' | 'updatedAt' | 'updatedBy'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type SupplierAssortmentDownloadQueryVariables = Exact<{
  view?: Maybe<SupplierAssortmentDownloadView>;
  filter?: Maybe<SupplierAssortmentDownloadFilter>;
}>;

export type SupplierAssortmentDownloadQuery = { __typename?: 'Query' } & {
  supplierAssortmentDownload: Maybe<
    { __typename?: 'SupplierAssortmentDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type SupplierAssortmentUploadMutationVariables = Exact<{
  input: SupplierAssortmentUploadInput;
}>;

export type SupplierAssortmentUploadMutation = { __typename?: 'Mutation' } & {
  supplierAssortmentUpload: { __typename?: 'SupplierAssortmentUploadResult' } & Pick<
    SupplierAssortmentUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type UpdateSupplierAssortmentMutationVariables = Exact<{
  input: Array<SupplierAssortmentUpdateInput> | SupplierAssortmentUpdateInput;
}>;

export type UpdateSupplierAssortmentMutation = { __typename?: 'Mutation' } & {
  updateSupplierAssortments: Maybe<
    Array<
      { __typename?: 'SupplierAssortment' } & Pick<SupplierAssortment, 'id'> & {
          managedBy: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'displayName'>>;
          item: Maybe<{ __typename?: 'Item' } & Pick<Item, 'id' | 'extId' | 'name' | 'upc'>>;
          opco: Maybe<
            { __typename?: 'Opco' } & Pick<
              Opco,
              'id' | 'ext_id' | 'name' | 'updatedAt' | 'updatedBy'
            >
          >;
        }
    >
  >;
};

export type BuyersQueryVariables = Exact<{ [key: string]: never }>;

export type BuyersQuery = { __typename?: 'Query' } & {
  buyers: Array<{ __typename?: 'Buyer' } & Pick<Buyer, 'email'>>;
};

export type SuppliersQueryVariables = Exact<{
  pagination: Pagination;
  filter: SupplierFilter;
}>;

export type SuppliersQuery = { __typename?: 'Query' } & {
  suppliers: Maybe<
    { __typename?: 'SupplierResult' } & Pick<SupplierResult, 'totalCount'> & {
        suppliers: Maybe<
          Array<
            { __typename?: 'Supplier' } & Pick<Supplier, 'id' | 'extID' | 'name' | 'isActive'> & {
                opcos: Array<
                  { __typename?: 'FullOpco' } & Pick<
                    FullOpco,
                    | 'id'
                    | 'ext_id'
                    | 'name'
                    | 'isActive'
                    | 'country'
                    | 'stateProvinceRegion'
                    | 'city'
                    | 'postalCode'
                    | 'address'
                    | 'packageTypeId'
                    | 'sendToTraverse'
                    | 'buyer'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateOpcoMutationVariables = Exact<{
  input: OpcosInput;
}>;

export type UpdateOpcoMutation = { __typename?: 'Mutation' } & {
  updateOpcos: Maybe<
    { __typename?: 'FullOpcoResult' } & Pick<FullOpcoResult, 'totalCount'> & {
        opcos: Maybe<
          Array<
            { __typename?: 'FullOpco' } & Pick<
              FullOpco,
              | 'id'
              | 'ext_id'
              | 'name'
              | 'isActive'
              | 'country'
              | 'stateProvinceRegion'
              | 'city'
              | 'postalCode'
              | 'address'
              | 'packageTypeId'
              | 'sendToTraverse'
              | 'buyer'
            >
          >
        >;
      }
  >;
};

export type MassEditTransportationLanesMutationVariables = Exact<{
  input: TransportationLanesMassEditInput;
}>;

export type MassEditTransportationLanesMutation = { __typename?: 'Mutation' } & {
  massEditTransportationLanes: Maybe<
    { __typename?: 'TransportationLaneResult' } & Pick<TransportationLaneResult, 'totalCount'> & {
        transportationLanes: Maybe<
          Array<
            { __typename?: 'TransportationLane' } & Pick<
              TransportationLane,
              'id' | 'accountNumber' | 'updatedBy' | 'updatedAt'
            > & {
                source: Maybe<
                  | ({ __typename?: 'Location' } & Pick<
                      Location,
                      'id' | 'name' | 'updatedAt' | 'updatedBy'
                    >)
                  | ({ __typename?: 'Opco' } & Pick<
                      Opco,
                      'id' | 'name' | 'stateProvinceRegion' | 'updatedAt' | 'updatedBy'
                    >)
                >;
                destination: Maybe<
                  { __typename?: 'Location' } & Pick<
                    Location,
                    'id' | 'extId' | 'name' | 'updatedAt' | 'updatedBy'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type TransportationLaneDownloadQueryVariables = Exact<{
  view?: Maybe<TransportationLaneDownloadView>;
  filter?: Maybe<TransportationLaneDownloadFilter>;
}>;

export type TransportationLaneDownloadQuery = { __typename?: 'Query' } & {
  transportationLaneDownload: Maybe<
    { __typename?: 'TransportationLaneDownloadResult' } & {
      file: { __typename?: 'File' } & Pick<File, 'name' | 'content'>;
    }
  >;
};

export type TransportationLaneUploadMutationVariables = Exact<{
  input: TransportationLaneUploadInput;
}>;

export type TransportationLaneUploadMutation = { __typename?: 'Mutation' } & {
  transportationLaneUpload: { __typename?: 'TransportationLaneUploadResult' } & Pick<
    TransportationLaneUploadResult,
    'total' | 'errors' | 'success'
  > & { file: Maybe<{ __typename?: 'File' } & Pick<File, 'name' | 'content'>> };
};

export type TransportationLanesQueryVariables = Exact<{
  pagination: Pagination;
  sorting: TransportationLaneSort;
  filter: TransportationLaneFilter;
}>;

export type TransportationLanesQuery = { __typename?: 'Query' } & {
  transportationLanes: Maybe<
    { __typename?: 'TransportationLaneResult' } & Pick<TransportationLaneResult, 'totalCount'> & {
        transportationLanes: Maybe<
          Array<
            { __typename?: 'TransportationLane' } & Pick<
              TransportationLane,
              'id' | 'accountNumber' | 'updatedBy' | 'updatedAt'
            > & {
                source: Maybe<
                  | ({ __typename?: 'Location' } & Pick<
                      Location,
                      'id' | 'name' | 'updatedBy' | 'updatedAt'
                    >)
                  | ({ __typename?: 'Opco' } & Pick<
                      Opco,
                      'id' | 'name' | 'stateProvinceRegion' | 'updatedBy' | 'updatedAt'
                    >)
                >;
                destination: Maybe<
                  { __typename?: 'Location' } & Pick<
                    Location,
                    'id' | 'extId' | 'name' | 'updatedBy' | 'updatedAt'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type UpdateTransportationLanesAccountIdMutationVariables = Exact<{
  input: Array<TransportationLaneUpdateInput> | TransportationLaneUpdateInput;
}>;

export type UpdateTransportationLanesAccountIdMutation = { __typename?: 'Mutation' } & {
  updateTransportationLanesAccountId: Maybe<
    Array<
      { __typename?: 'TransportationLane' } & Pick<TransportationLane, 'id' | 'accountNumber'> & {
          source: Maybe<
            | ({ __typename?: 'Location' } & Pick<
                Location,
                'id' | 'name' | 'updatedAt' | 'updatedBy'
              >)
            | ({ __typename?: 'Opco' } & Pick<Opco, 'id' | 'name' | 'updatedAt' | 'updatedBy'>)
          >;
          destination: Maybe<
            { __typename?: 'Location' } & Pick<
              Location,
              'id' | 'extId' | 'name' | 'updatedAt' | 'updatedBy'
            >
          >;
        }
    >
  >;
};

export type DeleteDealsMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteDealsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteDeals'>;

export type RescindDealsMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RescindDealsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'rescindDeals'>;

export type AlcoholTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AlcoholTypesQuery = { __typename?: 'Query' } & {
  alcoholTypes: Array<
    { __typename?: 'AlcoholType' } & Pick<AlcoholType, 'id' | 'name'> & {
        volumes: Array<{ __typename?: 'AlcoholVolume' } & Pick<AlcoholVolume, 'id' | 'volume'>>;
      }
  >;
};

export type CapabilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type CapabilitiesQuery = { __typename?: 'Query' } & {
  capabilities: Array<{ __typename?: 'Capability' } & Pick<Capability, 'id' | 'name'>>;
};

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = { __typename?: 'Query' } & {
  currencies: Array<{ __typename?: 'Currency' } & Pick<Currency, 'id' | 'code'>>;
};

export type CustomAssortmentsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomAssortmentsQuery = { __typename?: 'Query' } & {
  customAssortments: Array<
    { __typename?: 'CustomAssortment' } & Pick<CustomAssortment, 'id' | 'name'>
  >;
};

export type ItemsOptionsQueryVariables = Exact<{
  pagination: Pagination;
  filter: ItemFilter;
}>;

export type ItemsOptionsQuery = { __typename?: 'Query' } & {
  items: Maybe<
    { __typename?: 'ItemResult' } & {
      items: Maybe<
        Array<
          { __typename?: 'Item' } & Pick<Item, 'id' | 'name'> & {
              i18n: Maybe<
                { __typename?: 'ItemI18n' } & {
                  name: Maybe<
                    { __typename?: 'I18n' } & Pick<
                      I18n,
                      'enUS' | 'enGB' | 'enCA' | 'frFR' | 'frCA' | 'deDE' | 'esES'
                    >
                  >;
                }
              >;
            }
        >
      >;
    }
  >;
};

export type LocationAutocompleteOptionsQueryVariables = Exact<{
  pagination: Pagination;
  filter: LocationFilter;
}>;

export type LocationAutocompleteOptionsQuery = { __typename?: 'Query' } & {
  locations: Maybe<
    { __typename?: 'LocationResult' } & {
      locations: Maybe<Array<{ __typename?: 'Location' } & Pick<Location, 'name' | 'id'>>>;
    }
  >;
};

export type NationalAssortmentsQueryVariables = Exact<{ [key: string]: never }>;

export type NationalAssortmentsQuery = { __typename?: 'Query' } & {
  nationalAssortments: Array<
    { __typename?: 'NationalAssortment' } & Pick<NationalAssortment, 'id' | 'name'>
  >;
};

export type OpcosQueryVariables = Exact<{
  pagination: Pagination;
  filter: OpcoFilter;
}>;

export type OpcosQuery = { __typename?: 'Query' } & {
  opcos: Maybe<
    { __typename?: 'OpcoResult' } & {
      opcos: Maybe<
        Array<{ __typename?: 'Opco' } & Pick<Opco, 'name' | 'id' | 'stateProvinceRegion' | 'buyer'>>
      >;
    }
  >;
};

export type PackageTypesQueryVariables = Exact<{ [key: string]: never }>;

export type PackageTypesQuery = { __typename?: 'Query' } & {
  packageTypes: Array<{ __typename?: 'PackageType' } & Pick<PackageType, 'id' | 'name'>>;
};

export type SupplierAutocompleteOptionsQueryVariables = Exact<{
  pagination: Pagination;
  filter: SupplierFilter;
}>;

export type SupplierAutocompleteOptionsQuery = { __typename?: 'Query' } & {
  suppliers: Maybe<
    { __typename?: 'SupplierResult' } & {
      suppliers: Maybe<Array<{ __typename?: 'Supplier' } & Pick<Supplier, 'id' | 'name'>>>;
    }
  >;
};

export type SystemOfMeasuresQueryVariables = Exact<{ [key: string]: never }>;

export type SystemOfMeasuresQuery = { __typename?: 'Query' } & {
  systemOfMeasures: Array<
    { __typename?: 'SystemOfMeasure' } & Pick<SystemOfMeasure, 'id' | 'name'>
  >;
};

export type SystemStatusQueryVariables = Exact<{ [key: string]: never }>;

export type SystemStatusQuery = { __typename?: 'Query' } & Pick<Query, 'getSystemStatus'>;

export type TobaccoTypesQueryVariables = Exact<{ [key: string]: never }>;

export type TobaccoTypesQuery = { __typename?: 'Query' } & {
  tobaccoTypes: Array<{ __typename?: 'TobaccoType' } & Pick<TobaccoType, 'id' | 'name'>>;
};

export type UnprocessedRecordsResultQueryVariables = Exact<{ [key: string]: never }>;

export type UnprocessedRecordsResultQuery = { __typename?: 'Query' } & {
  unprocessedRecords: { __typename?: 'UnprocessedRecordsResult' } & Pick<
    UnprocessedRecordsResult,
    | 'itemsCount'
    | 'locationsCount'
    | 'supplierAssortmentsOpcosCount'
    | 'supplierAssortmentItemsCount'
    | 'transportationLaneSourcesCount'
    | 'transportationLaneDestinationsCount'
    | 'destinationAssortmentsCount'
    | 'itemSourcesCount'
    | 'sourceDestinationsCount'
  >;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = { __typename?: 'Query' } & {
  users: Array<{ __typename?: 'User' } & Pick<User, 'displayName' | 'email' | 'id'>>;
};

export const CostDateFragmentFragmentDoc = gql`
  fragment CostDateFragment on CostManagement {
    costDates {
      id
      costManagementId
      effectiveDate
      costPackagings {
        costDateId
        packageTypeId
        value
      }
      currency {
        id
        code
      }
      updatedAt
      updatedBy
      status
    }
  }
`;
export const CostDateFragmentV2FragmentDoc = gql`
  fragment CostDateFragmentV2 on CostDate {
    id
    costManagementId
    effectiveDate
    endDate
    costPackagings {
      costDateId
      packageTypeId
      value
      percentageChange
    }
    currency {
      id
      code
    }
    updatedAt
    updatedByDisplayName
    status
    type
    comments
    commentedByDisplayName
    canAutoApprove
  }
`;
export const CostManagementCostDatesFragmentV2FragmentDoc = gql`
  fragment CostManagementCostDatesFragmentV2 on CostManagement {
    costDates {
      ...CostDateFragmentV2
    }
  }
  ${CostDateFragmentV2FragmentDoc}
`;
export const CostManagementFragmentV2FragmentDoc = gql`
  fragment CostManagementFragmentV2 on CostManagement {
    id
    item {
      id: extId
      internalId: id
      name
      class
      department
      parentCompany
      i18n {
        name {
          enUS
          enGB
          enCA
          frFR
          frCA
          deDE
          esES
        }
      }
    }
    itemSources {
      id
      opco {
        id
      }
      packaging {
        id
        perChildPackageQty
        supplierItemPackagingNumber
        packageType {
          id
          name
        }
        eachUPCs
        upcCode
      }
    }
    supplier {
      id: extID
      internalId: id
      name
    }
    location {
      id: extId
      internalId: id
      name
      address
      city
      stateProvinceRegion
      postalCode
    }
    opco {
      id
      name: pureName
      stateProvinceRegion
    }
    isDisabled
    ...CostManagementCostDatesFragmentV2
    updatedBy
    updatedAt
  }
  ${CostManagementCostDatesFragmentV2FragmentDoc}
`;
export const DealHeaderFragmentDoc = gql`
  fragment DealHeader on Deal {
    id
    offerNumber
    status
    rescindStatus
    parentCompany {
      name
      extId
    }
    createdBy {
      displayName
      email
    }
    description
    type {
      name
    }
    startTime
    startTimeZone
    endTime
    endTimeZone
    approvedAt
    approvedBy {
      displayName
    }
    updatedAt
    updatedBy {
      displayName
    }
    note
    notedBy {
      displayName
    }
    pricingChannels {
      id
      name
    }
  }
`;
export const BomDocument = gql`
  query BOM($bom: ID!) {
    bomItems(bom: $bom) {
      id
      item {
        extId
        name
        category
        class
        brand
      }
    }
  }
`;

/**
 * __useBomQuery__
 *
 * To run a query within a React component, call `useBomQuery` and pass it any options that fit your needs.
 * When your component renders, `useBomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBomQuery({
 *   variables: {
 *      bom: // value for 'bom'
 *   },
 * });
 */
export function useBomQuery(baseOptions: Apollo.QueryHookOptions<BomQuery, BomQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BomQuery, BomQueryVariables>(BomDocument, options);
}
export function useBomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BomQuery, BomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BomQuery, BomQueryVariables>(BomDocument, options);
}
export type BomQueryHookResult = ReturnType<typeof useBomQuery>;
export type BomLazyQueryHookResult = ReturnType<typeof useBomLazyQuery>;
export type BomQueryResult = Apollo.QueryResult<BomQuery, BomQueryVariables>;
export const ParentItemDocument = gql`
  query ParentItem($pagination: Pagination!, $filter: ItemFilter!) {
    items(pagination: $pagination, filter: $filter) {
      items {
        name
      }
    }
  }
`;

/**
 * __useParentItemQuery__
 *
 * To run a query within a React component, call `useParentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentItemQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useParentItemQuery(
  baseOptions: Apollo.QueryHookOptions<ParentItemQuery, ParentItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ParentItemQuery, ParentItemQueryVariables>(ParentItemDocument, options);
}
export function useParentItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ParentItemQuery, ParentItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ParentItemQuery, ParentItemQueryVariables>(
    ParentItemDocument,
    options,
  );
}
export type ParentItemQueryHookResult = ReturnType<typeof useParentItemQuery>;
export type ParentItemLazyQueryHookResult = ReturnType<typeof useParentItemLazyQuery>;
export type ParentItemQueryResult = Apollo.QueryResult<ParentItemQuery, ParentItemQueryVariables>;
export const CostManagementDownloadDocument = gql`
  query CostManagementDownload($filter: CostManagementFilter!) {
    costManagementDownload(filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useCostManagementDownloadQuery__
 *
 * To run a query within a React component, call `useCostManagementDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostManagementDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostManagementDownloadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostManagementDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostManagementDownloadQuery,
    CostManagementDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostManagementDownloadQuery, CostManagementDownloadQueryVariables>(
    CostManagementDownloadDocument,
    options,
  );
}
export function useCostManagementDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostManagementDownloadQuery,
    CostManagementDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostManagementDownloadQuery, CostManagementDownloadQueryVariables>(
    CostManagementDownloadDocument,
    options,
  );
}
export type CostManagementDownloadQueryHookResult = ReturnType<
  typeof useCostManagementDownloadQuery
>;
export type CostManagementDownloadLazyQueryHookResult = ReturnType<
  typeof useCostManagementDownloadLazyQuery
>;
export type CostManagementDownloadQueryResult = Apollo.QueryResult<
  CostManagementDownloadQuery,
  CostManagementDownloadQueryVariables
>;
export const MassEditCostManagementDocument = gql`
  mutation MassEditCostManagement($input: CostManagementMassEditInput!) {
    massEditCostManagement(input: $input) {
      id
      item {
        id: extId
        internalId: id
        name
      }
      supplier {
        id: extID
        internalId: id
        name
      }
      location {
        id: extId
        internalId: id
        name
      }
      opco {
        id
        name: pureName
        stateProvinceRegion
      }
      isDisabled
      costDates {
        id
        costManagementId
        effectiveDate
        costPackagings {
          costDateId
          packageTypeId
          value
        }
        currency {
          id
          code
        }
        updatedAt
        updatedBy
        status
      }
    }
  }
`;
export type MassEditCostManagementMutationFn = Apollo.MutationFunction<
  MassEditCostManagementMutation,
  MassEditCostManagementMutationVariables
>;

/**
 * __useMassEditCostManagementMutation__
 *
 * To run a mutation, you first call `useMassEditCostManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassEditCostManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massEditCostManagementMutation, { data, loading, error }] = useMassEditCostManagementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassEditCostManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MassEditCostManagementMutation,
    MassEditCostManagementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MassEditCostManagementMutation,
    MassEditCostManagementMutationVariables
  >(MassEditCostManagementDocument, options);
}
export type MassEditCostManagementMutationHookResult = ReturnType<
  typeof useMassEditCostManagementMutation
>;
export type MassEditCostManagementMutationResult =
  Apollo.MutationResult<MassEditCostManagementMutation>;
export type MassEditCostManagementMutationOptions = Apollo.BaseMutationOptions<
  MassEditCostManagementMutation,
  MassEditCostManagementMutationVariables
>;
export const CostManagementUploadDocument = gql`
  mutation CostManagementUpload($input: CostManagementUploadInput!) {
    costManagementUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type CostManagementUploadMutationFn = Apollo.MutationFunction<
  CostManagementUploadMutation,
  CostManagementUploadMutationVariables
>;

/**
 * __useCostManagementUploadMutation__
 *
 * To run a mutation, you first call `useCostManagementUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostManagementUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costManagementUploadMutation, { data, loading, error }] = useCostManagementUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCostManagementUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CostManagementUploadMutation,
    CostManagementUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CostManagementUploadMutation, CostManagementUploadMutationVariables>(
    CostManagementUploadDocument,
    options,
  );
}
export type CostManagementUploadMutationHookResult = ReturnType<
  typeof useCostManagementUploadMutation
>;
export type CostManagementUploadMutationResult =
  Apollo.MutationResult<CostManagementUploadMutation>;
export type CostManagementUploadMutationOptions = Apollo.BaseMutationOptions<
  CostManagementUploadMutation,
  CostManagementUploadMutationVariables
>;
export const CostManagementsDocument = gql`
  query CostManagements($pagination: Pagination, $filter: CostManagementFilter!) {
    costManagementRecords(pagination: $pagination, filter: $filter) {
      records {
        id
        item {
          id: extId
          internalId: id
          name
          class
          department
          i18n {
            name {
              enUS
              enGB
              enCA
              frFR
              frCA
              deDE
              esES
            }
          }
        }
        supplier {
          id: extID
          internalId: id
          name
        }
        location {
          id: extId
          internalId: id
          name
        }
        opco {
          id
          name: pureName
          stateProvinceRegion
        }
        isDisabled
        costDates {
          id
          costManagementId
          effectiveDate
          costPackagings {
            costDateId
            packageTypeId
            value
          }
          currency {
            id
            code
          }
          updatedAt
          updatedBy
          status
        }
        updatedBy
        updatedAt
      }
      totalCount
    }
  }
`;

/**
 * __useCostManagementsQuery__
 *
 * To run a query within a React component, call `useCostManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostManagementsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<CostManagementsQuery, CostManagementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostManagementsQuery, CostManagementsQueryVariables>(
    CostManagementsDocument,
    options,
  );
}
export function useCostManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostManagementsQuery, CostManagementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostManagementsQuery, CostManagementsQueryVariables>(
    CostManagementsDocument,
    options,
  );
}
export type CostManagementsQueryHookResult = ReturnType<typeof useCostManagementsQuery>;
export type CostManagementsLazyQueryHookResult = ReturnType<typeof useCostManagementsLazyQuery>;
export type CostManagementsQueryResult = Apollo.QueryResult<
  CostManagementsQuery,
  CostManagementsQueryVariables
>;
export const UpdateCostDatesDocument = gql`
  mutation UpdateCostDates($input: [CostDateInput!]) {
    updateCostDates(input: $input) {
      id
      effectiveDate
      costManagementId
      costPackagings {
        costDateId
        packageTypeId
        value
      }
      currency {
        id
        code
      }
      updatedAt
      updatedBy
      status
    }
  }
`;
export type UpdateCostDatesMutationFn = Apollo.MutationFunction<
  UpdateCostDatesMutation,
  UpdateCostDatesMutationVariables
>;

/**
 * __useUpdateCostDatesMutation__
 *
 * To run a mutation, you first call `useUpdateCostDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostDatesMutation, { data, loading, error }] = useUpdateCostDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostDatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostDatesMutation,
    UpdateCostDatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCostDatesMutation, UpdateCostDatesMutationVariables>(
    UpdateCostDatesDocument,
    options,
  );
}
export type UpdateCostDatesMutationHookResult = ReturnType<typeof useUpdateCostDatesMutation>;
export type UpdateCostDatesMutationResult = Apollo.MutationResult<UpdateCostDatesMutation>;
export type UpdateCostDatesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostDatesMutation,
  UpdateCostDatesMutationVariables
>;
export const CostManagementActionsUploadDocument = gql`
  mutation CostManagementActionsUpload($input: CostManagementUploadInput!) {
    costManagementActionsUpload(input: $input) {
      rows {
        itemDepartment
        itemClass
        itemExtID
        itemName
        caseCount
        eachUpc
        caseUpc
        eachVin
        caseVin
        status
        eachCostValidation
        caseCostValidation
        canAutoApprove
        notes
        isDisabled
        effectiveDate
        eachCost
        caseCost
        currency
        supplierName
        supplierExtID
        opcoName
        opcoID
        opcoState
        locationName
        locationID
        locationAddress
        locationCity
        locationState
        locationPostalCode
        error
      }
      total
      errors
      success
    }
  }
`;
export type CostManagementActionsUploadMutationFn = Apollo.MutationFunction<
  CostManagementActionsUploadMutation,
  CostManagementActionsUploadMutationVariables
>;

/**
 * __useCostManagementActionsUploadMutation__
 *
 * To run a mutation, you first call `useCostManagementActionsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostManagementActionsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costManagementActionsUploadMutation, { data, loading, error }] = useCostManagementActionsUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCostManagementActionsUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CostManagementActionsUploadMutation,
    CostManagementActionsUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CostManagementActionsUploadMutation,
    CostManagementActionsUploadMutationVariables
  >(CostManagementActionsUploadDocument, options);
}
export type CostManagementActionsUploadMutationHookResult = ReturnType<
  typeof useCostManagementActionsUploadMutation
>;
export type CostManagementActionsUploadMutationResult =
  Apollo.MutationResult<CostManagementActionsUploadMutation>;
export type CostManagementActionsUploadMutationOptions = Apollo.BaseMutationOptions<
  CostManagementActionsUploadMutation,
  CostManagementActionsUploadMutationVariables
>;
export const MassEditCostManagementV2Document = gql`
  mutation MassEditCostManagementV2($input: CostManagementMassEditInput!) {
    massEditCostManagement(input: $input) {
      ...CostManagementFragmentV2
    }
  }
  ${CostManagementFragmentV2FragmentDoc}
`;
export type MassEditCostManagementV2MutationFn = Apollo.MutationFunction<
  MassEditCostManagementV2Mutation,
  MassEditCostManagementV2MutationVariables
>;

/**
 * __useMassEditCostManagementV2Mutation__
 *
 * To run a mutation, you first call `useMassEditCostManagementV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassEditCostManagementV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massEditCostManagementV2Mutation, { data, loading, error }] = useMassEditCostManagementV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassEditCostManagementV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    MassEditCostManagementV2Mutation,
    MassEditCostManagementV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MassEditCostManagementV2Mutation,
    MassEditCostManagementV2MutationVariables
  >(MassEditCostManagementV2Document, options);
}
export type MassEditCostManagementV2MutationHookResult = ReturnType<
  typeof useMassEditCostManagementV2Mutation
>;
export type MassEditCostManagementV2MutationResult =
  Apollo.MutationResult<MassEditCostManagementV2Mutation>;
export type MassEditCostManagementV2MutationOptions = Apollo.BaseMutationOptions<
  MassEditCostManagementV2Mutation,
  MassEditCostManagementV2MutationVariables
>;
export const CostManagementsV2Document = gql`
  query CostManagementsV2($pagination: Pagination, $filter: CostManagementFilter!) {
    costManagementRecords(pagination: $pagination, filter: $filter) {
      records {
        ...CostManagementFragmentV2
      }
      totalCount
    }
  }
  ${CostManagementFragmentV2FragmentDoc}
`;

/**
 * __useCostManagementsV2Query__
 *
 * To run a query within a React component, call `useCostManagementsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCostManagementsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostManagementsV2Query({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostManagementsV2Query(
  baseOptions: Apollo.QueryHookOptions<CostManagementsV2Query, CostManagementsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostManagementsV2Query, CostManagementsV2QueryVariables>(
    CostManagementsV2Document,
    options,
  );
}
export function useCostManagementsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostManagementsV2Query,
    CostManagementsV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostManagementsV2Query, CostManagementsV2QueryVariables>(
    CostManagementsV2Document,
    options,
  );
}
export type CostManagementsV2QueryHookResult = ReturnType<typeof useCostManagementsV2Query>;
export type CostManagementsV2LazyQueryHookResult = ReturnType<typeof useCostManagementsV2LazyQuery>;
export type CostManagementsV2QueryResult = Apollo.QueryResult<
  CostManagementsV2Query,
  CostManagementsV2QueryVariables
>;
export const DeleteCostDatesDocument = gql`
  mutation DeleteCostDates($input: [ID!]!) {
    deleteCostDates(input: $input) {
      success
    }
  }
`;
export type DeleteCostDatesMutationFn = Apollo.MutationFunction<
  DeleteCostDatesMutation,
  DeleteCostDatesMutationVariables
>;

/**
 * __useDeleteCostDatesMutation__
 *
 * To run a mutation, you first call `useDeleteCostDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostDatesMutation, { data, loading, error }] = useDeleteCostDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostDatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCostDatesMutation,
    DeleteCostDatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCostDatesMutation, DeleteCostDatesMutationVariables>(
    DeleteCostDatesDocument,
    options,
  );
}
export type DeleteCostDatesMutationHookResult = ReturnType<typeof useDeleteCostDatesMutation>;
export type DeleteCostDatesMutationResult = Apollo.MutationResult<DeleteCostDatesMutation>;
export type DeleteCostDatesMutationOptions = Apollo.BaseMutationOptions<
  DeleteCostDatesMutation,
  DeleteCostDatesMutationVariables
>;
export const UpdateCostDatesApprovalStatusDocument = gql`
  mutation UpdateCostDatesApprovalStatus($input: CostDateApprovalStatusInput!) {
    updateCostDatesApprovalStatus(input: $input) {
      success
    }
  }
`;
export type UpdateCostDatesApprovalStatusMutationFn = Apollo.MutationFunction<
  UpdateCostDatesApprovalStatusMutation,
  UpdateCostDatesApprovalStatusMutationVariables
>;

/**
 * __useUpdateCostDatesApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCostDatesApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostDatesApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostDatesApprovalStatusMutation, { data, loading, error }] = useUpdateCostDatesApprovalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostDatesApprovalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostDatesApprovalStatusMutation,
    UpdateCostDatesApprovalStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCostDatesApprovalStatusMutation,
    UpdateCostDatesApprovalStatusMutationVariables
  >(UpdateCostDatesApprovalStatusDocument, options);
}
export type UpdateCostDatesApprovalStatusMutationHookResult = ReturnType<
  typeof useUpdateCostDatesApprovalStatusMutation
>;
export type UpdateCostDatesApprovalStatusMutationResult =
  Apollo.MutationResult<UpdateCostDatesApprovalStatusMutation>;
export type UpdateCostDatesApprovalStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostDatesApprovalStatusMutation,
  UpdateCostDatesApprovalStatusMutationVariables
>;
export const UpdateCostDatesV2Document = gql`
  mutation UpdateCostDatesV2($input: [CostDateInput!]) {
    updateCostDates(input: $input) {
      ...CostDateFragmentV2
    }
  }
  ${CostDateFragmentV2FragmentDoc}
`;
export type UpdateCostDatesV2MutationFn = Apollo.MutationFunction<
  UpdateCostDatesV2Mutation,
  UpdateCostDatesV2MutationVariables
>;

/**
 * __useUpdateCostDatesV2Mutation__
 *
 * To run a mutation, you first call `useUpdateCostDatesV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostDatesV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostDatesV2Mutation, { data, loading, error }] = useUpdateCostDatesV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostDatesV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostDatesV2Mutation,
    UpdateCostDatesV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCostDatesV2Mutation, UpdateCostDatesV2MutationVariables>(
    UpdateCostDatesV2Document,
    options,
  );
}
export type UpdateCostDatesV2MutationHookResult = ReturnType<typeof useUpdateCostDatesV2Mutation>;
export type UpdateCostDatesV2MutationResult = Apollo.MutationResult<UpdateCostDatesV2Mutation>;
export type UpdateCostDatesV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateCostDatesV2Mutation,
  UpdateCostDatesV2MutationVariables
>;
export const VendorCostManagementUploadDocument = gql`
  mutation VendorCostManagementUpload($input: CostManagementUploadInput!) {
    vendorCostManagementUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type VendorCostManagementUploadMutationFn = Apollo.MutationFunction<
  VendorCostManagementUploadMutation,
  VendorCostManagementUploadMutationVariables
>;

/**
 * __useVendorCostManagementUploadMutation__
 *
 * To run a mutation, you first call `useVendorCostManagementUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorCostManagementUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorCostManagementUploadMutation, { data, loading, error }] = useVendorCostManagementUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorCostManagementUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VendorCostManagementUploadMutation,
    VendorCostManagementUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VendorCostManagementUploadMutation,
    VendorCostManagementUploadMutationVariables
  >(VendorCostManagementUploadDocument, options);
}
export type VendorCostManagementUploadMutationHookResult = ReturnType<
  typeof useVendorCostManagementUploadMutation
>;
export type VendorCostManagementUploadMutationResult =
  Apollo.MutationResult<VendorCostManagementUploadMutation>;
export type VendorCostManagementUploadMutationOptions = Apollo.BaseMutationOptions<
  VendorCostManagementUploadMutation,
  VendorCostManagementUploadMutationVariables
>;
export const DealUploadDocument = gql`
  mutation DealUpload($input: DealUploadInput!) {
    dealUpload(input: $input) {
      header {
        description
        type
        startDate
        startTimeZone
        endDate
        endTimeZone
        locations
        supplierNumber
        currency
        error
      }
      lines {
        eachUpc
        error
        scanDeal {
          vendorFundingPerUnit
        }
      }
      summary {
        total
        errors
        success
      }
    }
  }
`;
export type DealUploadMutationFn = Apollo.MutationFunction<
  DealUploadMutation,
  DealUploadMutationVariables
>;

/**
 * __useDealUploadMutation__
 *
 * To run a mutation, you first call `useDealUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealUploadMutation, { data, loading, error }] = useDealUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDealUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<DealUploadMutation, DealUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DealUploadMutation, DealUploadMutationVariables>(
    DealUploadDocument,
    options,
  );
}
export type DealUploadMutationHookResult = ReturnType<typeof useDealUploadMutation>;
export type DealUploadMutationResult = Apollo.MutationResult<DealUploadMutation>;
export type DealUploadMutationOptions = Apollo.BaseMutationOptions<
  DealUploadMutation,
  DealUploadMutationVariables
>;
export const DealsDocument = gql`
  query Deals($pagination: Pagination) {
    deals(pagination: $pagination) {
      totalCount
      records {
        ...DealHeader
      }
    }
  }
  ${DealHeaderFragmentDoc}
`;

/**
 * __useDealsQuery__
 *
 * To run a query within a React component, call `useDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<DealsQuery, DealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
}
export function useDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealsQuery, DealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
}
export type DealsQueryHookResult = ReturnType<typeof useDealsQuery>;
export type DealsLazyQueryHookResult = ReturnType<typeof useDealsLazyQuery>;
export type DealsQueryResult = Apollo.QueryResult<DealsQuery, DealsQueryVariables>;
export const DealDetailsDocument = gql`
  query DealDetails($filter: DealFilter!) {
    deals(filter: $filter) {
      records {
        ...DealHeader
        country
        currency {
          code
        }
        lines {
          id
          item {
            id
            upc
            extId
            name
          }
          scanDeal {
            id
            vendorFundingPerUnit
            gopuffDollarFundingPerUnit
            gopuffPercentageFundingPerUnit
          }
        }
        locations {
          id
          name
          extId
        }
        locationGroups {
          id
          name
          locations {
            extId
          }
        }
      }
    }
  }
  ${DealHeaderFragmentDoc}
`;

/**
 * __useDealDetailsQuery__
 *
 * To run a query within a React component, call `useDealDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDealDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<DealDetailsQuery, DealDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealDetailsQuery, DealDetailsQueryVariables>(DealDetailsDocument, options);
}
export function useDealDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealDetailsQuery, DealDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealDetailsQuery, DealDetailsQueryVariables>(
    DealDetailsDocument,
    options,
  );
}
export type DealDetailsQueryHookResult = ReturnType<typeof useDealDetailsQuery>;
export type DealDetailsLazyQueryHookResult = ReturnType<typeof useDealDetailsLazyQuery>;
export type DealDetailsQueryResult = Apollo.QueryResult<
  DealDetailsQuery,
  DealDetailsQueryVariables
>;
export const PricingChannelsDocument = gql`
  query PricingChannels {
    pricingChannels {
      records {
        id
        name
      }
    }
  }
`;

/**
 * __usePricingChannelsQuery__
 *
 * To run a query within a React component, call `usePricingChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<PricingChannelsQuery, PricingChannelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingChannelsQuery, PricingChannelsQueryVariables>(
    PricingChannelsDocument,
    options,
  );
}
export function usePricingChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingChannelsQuery, PricingChannelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingChannelsQuery, PricingChannelsQueryVariables>(
    PricingChannelsDocument,
    options,
  );
}
export type PricingChannelsQueryHookResult = ReturnType<typeof usePricingChannelsQuery>;
export type PricingChannelsLazyQueryHookResult = ReturnType<typeof usePricingChannelsLazyQuery>;
export type PricingChannelsQueryResult = Apollo.QueryResult<
  PricingChannelsQuery,
  PricingChannelsQueryVariables
>;
export const UpdateDealsApprovalStatusDocument = gql`
  mutation UpdateDealsApprovalStatus($input: DealApprovalStatusInput!) {
    updateDealsApprovalStatus(input: $input) {
      totalCount
    }
  }
`;
export type UpdateDealsApprovalStatusMutationFn = Apollo.MutationFunction<
  UpdateDealsApprovalStatusMutation,
  UpdateDealsApprovalStatusMutationVariables
>;

/**
 * __useUpdateDealsApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateDealsApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealsApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealsApprovalStatusMutation, { data, loading, error }] = useUpdateDealsApprovalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDealsApprovalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDealsApprovalStatusMutation,
    UpdateDealsApprovalStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDealsApprovalStatusMutation,
    UpdateDealsApprovalStatusMutationVariables
  >(UpdateDealsApprovalStatusDocument, options);
}
export type UpdateDealsApprovalStatusMutationHookResult = ReturnType<
  typeof useUpdateDealsApprovalStatusMutation
>;
export type UpdateDealsApprovalStatusMutationResult =
  Apollo.MutationResult<UpdateDealsApprovalStatusMutation>;
export type UpdateDealsApprovalStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateDealsApprovalStatusMutation,
  UpdateDealsApprovalStatusMutationVariables
>;
export const UpdateDealLineScanDealDocument = gql`
  mutation UpdateDealLineScanDeal($input: DealLineScanDealInput!) {
    updateDealLineScanDeal(input: $input) {
      gopuffDollarFundingPerUnit
      gopuffPercentageFundingPerUnit
    }
  }
`;
export type UpdateDealLineScanDealMutationFn = Apollo.MutationFunction<
  UpdateDealLineScanDealMutation,
  UpdateDealLineScanDealMutationVariables
>;

/**
 * __useUpdateDealLineScanDealMutation__
 *
 * To run a mutation, you first call `useUpdateDealLineScanDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealLineScanDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealLineScanDealMutation, { data, loading, error }] = useUpdateDealLineScanDealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDealLineScanDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDealLineScanDealMutation,
    UpdateDealLineScanDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDealLineScanDealMutation,
    UpdateDealLineScanDealMutationVariables
  >(UpdateDealLineScanDealDocument, options);
}
export type UpdateDealLineScanDealMutationHookResult = ReturnType<
  typeof useUpdateDealLineScanDealMutation
>;
export type UpdateDealLineScanDealMutationResult =
  Apollo.MutationResult<UpdateDealLineScanDealMutation>;
export type UpdateDealLineScanDealMutationOptions = Apollo.BaseMutationOptions<
  UpdateDealLineScanDealMutation,
  UpdateDealLineScanDealMutationVariables
>;
export const UpdateDealsDocument = gql`
  mutation UpdateDeals($input: [UpdateDealInput!]!) {
    updateDeals(input: $input) {
      totalCount
    }
  }
`;
export type UpdateDealsMutationFn = Apollo.MutationFunction<
  UpdateDealsMutation,
  UpdateDealsMutationVariables
>;

/**
 * __useUpdateDealsMutation__
 *
 * To run a mutation, you first call `useUpdateDealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealsMutation, { data, loading, error }] = useUpdateDealsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDealsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDealsMutation, UpdateDealsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDealsMutation, UpdateDealsMutationVariables>(
    UpdateDealsDocument,
    options,
  );
}
export type UpdateDealsMutationHookResult = ReturnType<typeof useUpdateDealsMutation>;
export type UpdateDealsMutationResult = Apollo.MutationResult<UpdateDealsMutation>;
export type UpdateDealsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDealsMutation,
  UpdateDealsMutationVariables
>;
export const DestinationAssortmentsDocument = gql`
  query DestinationAssortments(
    $pagination: Pagination
    $sorting: DestinationAssortmentSort
    $filter: DestinationAssortmentFilter!
  ) {
    destinationAssortments(pagination: $pagination, sorting: $sorting, filter: $filter) {
      assortments {
        id
        item {
          id
          extId
          name
          i18n {
            name {
              enUS
              enGB
              enCA
              frFR
              frCA
              deDE
              esES
            }
          }
        }
        opco {
          id
          name
          stateProvinceRegion
        }
        localCost
        localCostCurrency {
          id
        }
        nationalCost
        nationalCostCurrency {
          id
        }
        source {
          id
          extID
          name
        }
        location {
          id
          extId
          name
        }
        selectedPackagingID
        packaging {
          id
          packageType {
            name
          }
        }
        maxAllocationQuantity
        minAllocationQuantity
        assortmentOverrideState
        velocityCategory {
          id
        }
        managedBy {
          displayName
        }
        costs {
          currencyId
          packageTypeID
          value
        }
        updatedBy
        updatedAt
      }
      totalCount
    }
  }
`;

/**
 * __useDestinationAssortmentsQuery__
 *
 * To run a query within a React component, call `useDestinationAssortmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationAssortmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDestinationAssortmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DestinationAssortmentsQuery,
    DestinationAssortmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DestinationAssortmentsQuery, DestinationAssortmentsQueryVariables>(
    DestinationAssortmentsDocument,
    options,
  );
}
export function useDestinationAssortmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DestinationAssortmentsQuery,
    DestinationAssortmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DestinationAssortmentsQuery, DestinationAssortmentsQueryVariables>(
    DestinationAssortmentsDocument,
    options,
  );
}
export type DestinationAssortmentsQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsQuery
>;
export type DestinationAssortmentsLazyQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsLazyQuery
>;
export type DestinationAssortmentsQueryResult = Apollo.QueryResult<
  DestinationAssortmentsQuery,
  DestinationAssortmentsQueryVariables
>;
export const DestinationAssortmentsDownloadDocument = gql`
  query DestinationAssortmentsDownload($filter: DestinationAssortmentsDownloadFilter!) {
    destinationAssortmentsDownload(filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useDestinationAssortmentsDownloadQuery__
 *
 * To run a query within a React component, call `useDestinationAssortmentsDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationAssortmentsDownloadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDestinationAssortmentsDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<
    DestinationAssortmentsDownloadQuery,
    DestinationAssortmentsDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DestinationAssortmentsDownloadQuery,
    DestinationAssortmentsDownloadQueryVariables
  >(DestinationAssortmentsDownloadDocument, options);
}
export function useDestinationAssortmentsDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DestinationAssortmentsDownloadQuery,
    DestinationAssortmentsDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DestinationAssortmentsDownloadQuery,
    DestinationAssortmentsDownloadQueryVariables
  >(DestinationAssortmentsDownloadDocument, options);
}
export type DestinationAssortmentsDownloadQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsDownloadQuery
>;
export type DestinationAssortmentsDownloadLazyQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsDownloadLazyQuery
>;
export type DestinationAssortmentsDownloadQueryResult = Apollo.QueryResult<
  DestinationAssortmentsDownloadQuery,
  DestinationAssortmentsDownloadQueryVariables
>;
export const DestinationAssortmentsItemOptionsDocument = gql`
  query DestinationAssortmentsItemOptions(
    $pagination: Pagination!
    $filter: DestinationAssortmentFilter!
  ) {
    destinationAssortments(pagination: $pagination, filter: $filter) {
      assortments {
        item {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useDestinationAssortmentsItemOptionsQuery__
 *
 * To run a query within a React component, call `useDestinationAssortmentsItemOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsItemOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationAssortmentsItemOptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDestinationAssortmentsItemOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DestinationAssortmentsItemOptionsQuery,
    DestinationAssortmentsItemOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DestinationAssortmentsItemOptionsQuery,
    DestinationAssortmentsItemOptionsQueryVariables
  >(DestinationAssortmentsItemOptionsDocument, options);
}
export function useDestinationAssortmentsItemOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DestinationAssortmentsItemOptionsQuery,
    DestinationAssortmentsItemOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DestinationAssortmentsItemOptionsQuery,
    DestinationAssortmentsItemOptionsQueryVariables
  >(DestinationAssortmentsItemOptionsDocument, options);
}
export type DestinationAssortmentsItemOptionsQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsItemOptionsQuery
>;
export type DestinationAssortmentsItemOptionsLazyQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsItemOptionsLazyQuery
>;
export type DestinationAssortmentsItemOptionsQueryResult = Apollo.QueryResult<
  DestinationAssortmentsItemOptionsQuery,
  DestinationAssortmentsItemOptionsQueryVariables
>;
export const DestinationAssortmentsLocationOptionsDocument = gql`
  query DestinationAssortmentsLocationOptions(
    $pagination: Pagination!
    $filter: DestinationAssortmentFilter!
  ) {
    destinationAssortments(pagination: $pagination, filter: $filter) {
      assortments {
        location {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useDestinationAssortmentsLocationOptionsQuery__
 *
 * To run a query within a React component, call `useDestinationAssortmentsLocationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsLocationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationAssortmentsLocationOptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDestinationAssortmentsLocationOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DestinationAssortmentsLocationOptionsQuery,
    DestinationAssortmentsLocationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DestinationAssortmentsLocationOptionsQuery,
    DestinationAssortmentsLocationOptionsQueryVariables
  >(DestinationAssortmentsLocationOptionsDocument, options);
}
export function useDestinationAssortmentsLocationOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DestinationAssortmentsLocationOptionsQuery,
    DestinationAssortmentsLocationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DestinationAssortmentsLocationOptionsQuery,
    DestinationAssortmentsLocationOptionsQueryVariables
  >(DestinationAssortmentsLocationOptionsDocument, options);
}
export type DestinationAssortmentsLocationOptionsQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsLocationOptionsQuery
>;
export type DestinationAssortmentsLocationOptionsLazyQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsLocationOptionsLazyQuery
>;
export type DestinationAssortmentsLocationOptionsQueryResult = Apollo.QueryResult<
  DestinationAssortmentsLocationOptionsQuery,
  DestinationAssortmentsLocationOptionsQueryVariables
>;
export const DestinationAssortmentsOpcoOptionsDocument = gql`
  query DestinationAssortmentsOpcoOptions(
    $pagination: Pagination!
    $filter: DestinationAssortmentFilter!
  ) {
    destinationAssortments(pagination: $pagination, filter: $filter) {
      assortments {
        opco {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useDestinationAssortmentsOpcoOptionsQuery__
 *
 * To run a query within a React component, call `useDestinationAssortmentsOpcoOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsOpcoOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationAssortmentsOpcoOptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDestinationAssortmentsOpcoOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DestinationAssortmentsOpcoOptionsQuery,
    DestinationAssortmentsOpcoOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DestinationAssortmentsOpcoOptionsQuery,
    DestinationAssortmentsOpcoOptionsQueryVariables
  >(DestinationAssortmentsOpcoOptionsDocument, options);
}
export function useDestinationAssortmentsOpcoOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DestinationAssortmentsOpcoOptionsQuery,
    DestinationAssortmentsOpcoOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DestinationAssortmentsOpcoOptionsQuery,
    DestinationAssortmentsOpcoOptionsQueryVariables
  >(DestinationAssortmentsOpcoOptionsDocument, options);
}
export type DestinationAssortmentsOpcoOptionsQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsOpcoOptionsQuery
>;
export type DestinationAssortmentsOpcoOptionsLazyQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsOpcoOptionsLazyQuery
>;
export type DestinationAssortmentsOpcoOptionsQueryResult = Apollo.QueryResult<
  DestinationAssortmentsOpcoOptionsQuery,
  DestinationAssortmentsOpcoOptionsQueryVariables
>;
export const DestinationAssortmentsOptionsDocument = gql`
  query DestinationAssortmentsOptions {
    velocityCategories {
      id
      name
    }
    packageTypes {
      id
      name
    }
  }
`;

/**
 * __useDestinationAssortmentsOptionsQuery__
 *
 * To run a query within a React component, call `useDestinationAssortmentsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationAssortmentsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDestinationAssortmentsOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DestinationAssortmentsOptionsQuery,
    DestinationAssortmentsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DestinationAssortmentsOptionsQuery,
    DestinationAssortmentsOptionsQueryVariables
  >(DestinationAssortmentsOptionsDocument, options);
}
export function useDestinationAssortmentsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DestinationAssortmentsOptionsQuery,
    DestinationAssortmentsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DestinationAssortmentsOptionsQuery,
    DestinationAssortmentsOptionsQueryVariables
  >(DestinationAssortmentsOptionsDocument, options);
}
export type DestinationAssortmentsOptionsQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsOptionsQuery
>;
export type DestinationAssortmentsOptionsLazyQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsOptionsLazyQuery
>;
export type DestinationAssortmentsOptionsQueryResult = Apollo.QueryResult<
  DestinationAssortmentsOptionsQuery,
  DestinationAssortmentsOptionsQueryVariables
>;
export const DestinationAssortmentsSourceOptionsDocument = gql`
  query DestinationAssortmentsSourceOptions(
    $pagination: Pagination!
    $filter: DestinationAssortmentFilter!
  ) {
    destinationAssortments(pagination: $pagination, filter: $filter) {
      assortments {
        source {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useDestinationAssortmentsSourceOptionsQuery__
 *
 * To run a query within a React component, call `useDestinationAssortmentsSourceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsSourceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationAssortmentsSourceOptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDestinationAssortmentsSourceOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DestinationAssortmentsSourceOptionsQuery,
    DestinationAssortmentsSourceOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DestinationAssortmentsSourceOptionsQuery,
    DestinationAssortmentsSourceOptionsQueryVariables
  >(DestinationAssortmentsSourceOptionsDocument, options);
}
export function useDestinationAssortmentsSourceOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DestinationAssortmentsSourceOptionsQuery,
    DestinationAssortmentsSourceOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DestinationAssortmentsSourceOptionsQuery,
    DestinationAssortmentsSourceOptionsQueryVariables
  >(DestinationAssortmentsSourceOptionsDocument, options);
}
export type DestinationAssortmentsSourceOptionsQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsSourceOptionsQuery
>;
export type DestinationAssortmentsSourceOptionsLazyQueryHookResult = ReturnType<
  typeof useDestinationAssortmentsSourceOptionsLazyQuery
>;
export type DestinationAssortmentsSourceOptionsQueryResult = Apollo.QueryResult<
  DestinationAssortmentsSourceOptionsQuery,
  DestinationAssortmentsSourceOptionsQueryVariables
>;
export const DestinationAssortmentsUploadDocument = gql`
  mutation DestinationAssortmentsUpload($input: DestinationAssortmentsUploadInput!) {
    destinationAssortmentsUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type DestinationAssortmentsUploadMutationFn = Apollo.MutationFunction<
  DestinationAssortmentsUploadMutation,
  DestinationAssortmentsUploadMutationVariables
>;

/**
 * __useDestinationAssortmentsUploadMutation__
 *
 * To run a mutation, you first call `useDestinationAssortmentsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestinationAssortmentsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destinationAssortmentsUploadMutation, { data, loading, error }] = useDestinationAssortmentsUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestinationAssortmentsUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DestinationAssortmentsUploadMutation,
    DestinationAssortmentsUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DestinationAssortmentsUploadMutation,
    DestinationAssortmentsUploadMutationVariables
  >(DestinationAssortmentsUploadDocument, options);
}
export type DestinationAssortmentsUploadMutationHookResult = ReturnType<
  typeof useDestinationAssortmentsUploadMutation
>;
export type DestinationAssortmentsUploadMutationResult =
  Apollo.MutationResult<DestinationAssortmentsUploadMutation>;
export type DestinationAssortmentsUploadMutationOptions = Apollo.BaseMutationOptions<
  DestinationAssortmentsUploadMutation,
  DestinationAssortmentsUploadMutationVariables
>;
export const LastDestinationAssortmentTimeDocument = gql`
  query LastDestinationAssortmentTime {
    lastDestinationAssortmentTime
  }
`;

/**
 * __useLastDestinationAssortmentTimeQuery__
 *
 * To run a query within a React component, call `useLastDestinationAssortmentTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastDestinationAssortmentTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastDestinationAssortmentTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastDestinationAssortmentTimeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LastDestinationAssortmentTimeQuery,
    LastDestinationAssortmentTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LastDestinationAssortmentTimeQuery,
    LastDestinationAssortmentTimeQueryVariables
  >(LastDestinationAssortmentTimeDocument, options);
}
export function useLastDestinationAssortmentTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LastDestinationAssortmentTimeQuery,
    LastDestinationAssortmentTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LastDestinationAssortmentTimeQuery,
    LastDestinationAssortmentTimeQueryVariables
  >(LastDestinationAssortmentTimeDocument, options);
}
export type LastDestinationAssortmentTimeQueryHookResult = ReturnType<
  typeof useLastDestinationAssortmentTimeQuery
>;
export type LastDestinationAssortmentTimeLazyQueryHookResult = ReturnType<
  typeof useLastDestinationAssortmentTimeLazyQuery
>;
export type LastDestinationAssortmentTimeQueryResult = Apollo.QueryResult<
  LastDestinationAssortmentTimeQuery,
  LastDestinationAssortmentTimeQueryVariables
>;
export const UpdateAssortmentDocument = gql`
  mutation UpdateAssortment {
    validateDestinationAssortments
  }
`;
export type UpdateAssortmentMutationFn = Apollo.MutationFunction<
  UpdateAssortmentMutation,
  UpdateAssortmentMutationVariables
>;

/**
 * __useUpdateAssortmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssortmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssortmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssortmentMutation, { data, loading, error }] = useUpdateAssortmentMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateAssortmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssortmentMutation,
    UpdateAssortmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssortmentMutation, UpdateAssortmentMutationVariables>(
    UpdateAssortmentDocument,
    options,
  );
}
export type UpdateAssortmentMutationHookResult = ReturnType<typeof useUpdateAssortmentMutation>;
export type UpdateAssortmentMutationResult = Apollo.MutationResult<UpdateAssortmentMutation>;
export type UpdateAssortmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssortmentMutation,
  UpdateAssortmentMutationVariables
>;
export const UpdateDestinationAssortmentsDocument = gql`
  mutation UpdateDestinationAssortments($input: DestinationAssortmentsInput!) {
    updateDestinationAssortments(input: $input) {
      assortments {
        id
        maxAllocationQuantity
        minAllocationQuantity
        assortmentOverrideState
        selectedPackagingID
        packaging {
          id
          packageType {
            name
          }
        }
        localCost
        localCostCurrency {
          id
        }
        nationalCost
        nationalCostCurrency {
          id
        }
        velocityCategory {
          id
        }
        costs {
          currencyId
          packageTypeID
          value
        }
        updatedBy
        updatedAt
      }
    }
  }
`;
export type UpdateDestinationAssortmentsMutationFn = Apollo.MutationFunction<
  UpdateDestinationAssortmentsMutation,
  UpdateDestinationAssortmentsMutationVariables
>;

/**
 * __useUpdateDestinationAssortmentsMutation__
 *
 * To run a mutation, you first call `useUpdateDestinationAssortmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDestinationAssortmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDestinationAssortmentsMutation, { data, loading, error }] = useUpdateDestinationAssortmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDestinationAssortmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDestinationAssortmentsMutation,
    UpdateDestinationAssortmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDestinationAssortmentsMutation,
    UpdateDestinationAssortmentsMutationVariables
  >(UpdateDestinationAssortmentsDocument, options);
}
export type UpdateDestinationAssortmentsMutationHookResult = ReturnType<
  typeof useUpdateDestinationAssortmentsMutation
>;
export type UpdateDestinationAssortmentsMutationResult =
  Apollo.MutationResult<UpdateDestinationAssortmentsMutation>;
export type UpdateDestinationAssortmentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDestinationAssortmentsMutation,
  UpdateDestinationAssortmentsMutationVariables
>;
export const ItemDocument = gql`
  query Item($pagination: Pagination!, $filter: ItemFilter!) {
    items(pagination: $pagination, filter: $filter) {
      items {
        id
        extId
        name
        category
        class
        brand
        department
        parentCompany
        i18n {
          name {
            enUS
            enGB
            enCA
            frFR
            frCA
            deDE
            esES
          }
        }
        isAlcohol
        isTobacco
        isBom
        upc
        pullByDate
        markdownDays
        customAssortments {
          id
          updatedAt
          updatedBy
        }
        capabilities {
          id
          updatedAt
          updatedBy
        }
        stopBuyingFlag
        nationalAssortment {
          id
          name
        }
        alcoholType {
          id
        }
        alcoholByVolume
        tobaccoType {
          id
        }
        guaranteedShelfLife
        manufacturingType {
          id
        }
        subClass
        productType
        captureExpiryMethod
        acceptableShelfLife
        markdownItem
        baggableAtPack
        binnableAtPack
        packingConstraint
        percentageOverageAllowed
        ignoreBlockOverReceipt
        requireProduceBag
        thawToSellEnabled
        thawReplenishmentMinQty
        thawReplenishmentMaxQty
        thawTimeHours
        thawExpiryDays
        sellableTemperature
        storageTemperature
        updatedAt
        updatedBy
        itemCapabilityUpdatedAt
        itemCapabilityUpdatedBy
        itemCustomAssortmentUpdatedAt
        itemCustomAssortmentUpdatedBy
      }
      totalCount
    }
  }
`;

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<ItemQuery, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
}
export function useItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemQuery, ItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
}
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemQueryResult = Apollo.QueryResult<ItemQuery, ItemQueryVariables>;
export const ItemDownloadDocument = gql`
  query ItemDownload($filter: ItemDownloadFilter) {
    itemDownload(filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useItemDownloadQuery__
 *
 * To run a query within a React component, call `useItemDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemDownloadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemDownloadQuery(
  baseOptions?: Apollo.QueryHookOptions<ItemDownloadQuery, ItemDownloadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemDownloadQuery, ItemDownloadQueryVariables>(
    ItemDownloadDocument,
    options,
  );
}
export function useItemDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemDownloadQuery, ItemDownloadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemDownloadQuery, ItemDownloadQueryVariables>(
    ItemDownloadDocument,
    options,
  );
}
export type ItemDownloadQueryHookResult = ReturnType<typeof useItemDownloadQuery>;
export type ItemDownloadLazyQueryHookResult = ReturnType<typeof useItemDownloadLazyQuery>;
export type ItemDownloadQueryResult = Apollo.QueryResult<
  ItemDownloadQuery,
  ItemDownloadQueryVariables
>;
export const ItemOptionsDocument = gql`
  query ItemOptions {
    customAssortments {
      id
      name
    }
    capabilities {
      id
      name
    }
    tobaccoTypes {
      id
      name: restrictionCode
    }
    nationalAssortments {
      id
      name
    }
    alcoholTypes {
      id
      name
      volumes {
        id
        volume
        description
      }
    }
    manufacturingTypes {
      id
      name
    }
  }
`;

/**
 * __useItemOptionsQuery__
 *
 * To run a query within a React component, call `useItemOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ItemOptionsQuery, ItemOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemOptionsQuery, ItemOptionsQueryVariables>(ItemOptionsDocument, options);
}
export function useItemOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemOptionsQuery, ItemOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemOptionsQuery, ItemOptionsQueryVariables>(
    ItemOptionsDocument,
    options,
  );
}
export type ItemOptionsQueryHookResult = ReturnType<typeof useItemOptionsQuery>;
export type ItemOptionsLazyQueryHookResult = ReturnType<typeof useItemOptionsLazyQuery>;
export type ItemOptionsQueryResult = Apollo.QueryResult<
  ItemOptionsQuery,
  ItemOptionsQueryVariables
>;
export const ItemUploadDocument = gql`
  mutation ItemUpload($input: ItemUploadInput!) {
    itemUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type ItemUploadMutationFn = Apollo.MutationFunction<
  ItemUploadMutation,
  ItemUploadMutationVariables
>;

/**
 * __useItemUploadMutation__
 *
 * To run a mutation, you first call `useItemUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemUploadMutation, { data, loading, error }] = useItemUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<ItemUploadMutation, ItemUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ItemUploadMutation, ItemUploadMutationVariables>(
    ItemUploadDocument,
    options,
  );
}
export type ItemUploadMutationHookResult = ReturnType<typeof useItemUploadMutation>;
export type ItemUploadMutationResult = Apollo.MutationResult<ItemUploadMutation>;
export type ItemUploadMutationOptions = Apollo.BaseMutationOptions<
  ItemUploadMutation,
  ItemUploadMutationVariables
>;
export const UpdateItemDocument = gql`
  mutation UpdateItem($input: ItemsInput!) {
    updateItems(input: $input) {
      items {
        id
        extId
        name
        category
        class
        brand
        department
        parentCompany
        isAlcohol
        isTobacco
        isBom
        upc
        pullByDate
        markdownDays
        customAssortments {
          id
          updatedAt
          updatedBy
        }
        capabilities {
          id
          updatedAt
          updatedBy
        }
        stopBuyingFlag
        nationalAssortment {
          id
          name
        }
        alcoholType {
          id
        }
        alcoholByVolume
        tobaccoType {
          id
        }
        guaranteedShelfLife
        manufacturingType {
          id
        }
        subClass
        productType
        captureExpiryMethod
        acceptableShelfLife
        markdownItem
        baggableAtPack
        binnableAtPack
        packingConstraint
        percentageOverageAllowed
        ignoreBlockOverReceipt
        requireProduceBag
        thawToSellEnabled
        thawReplenishmentMinQty
        thawReplenishmentMaxQty
        thawTimeHours
        thawExpiryDays
        sellableTemperature
        storageTemperature
        updatedAt
        updatedBy
        itemCustomAssortmentUpdatedAt
        itemCustomAssortmentUpdatedBy
        itemCapabilityUpdatedAt
        itemCapabilityUpdatedBy
      }
    }
  }
`;
export type UpdateItemMutationFn = Apollo.MutationFunction<
  UpdateItemMutation,
  UpdateItemMutationVariables
>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(
    UpdateItemDocument,
    options,
  );
}
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemMutation,
  UpdateItemMutationVariables
>;
export const GraphJinItemExtraSubClassDefaultDocument = gql`
  query GraphJinItemExtraSubClassDefault(
    $filter: GraphJinItemExtraSubClassDefaultFilter!
    $pagination: Pagination!
  ) {
    graphJinItemExtraSubClassDefault(filter: $filter, pagination: $pagination) {
      id
      subClass
      pullByDate
      markdownDays
      captureExpiry
      acceptableShelfLife
      packingConstraint
    }
  }
`;

/**
 * __useGraphJinItemExtraSubClassDefaultQuery__
 *
 * To run a query within a React component, call `useGraphJinItemExtraSubClassDefaultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphJinItemExtraSubClassDefaultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphJinItemExtraSubClassDefaultQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGraphJinItemExtraSubClassDefaultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphJinItemExtraSubClassDefaultQuery,
    GraphJinItemExtraSubClassDefaultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GraphJinItemExtraSubClassDefaultQuery,
    GraphJinItemExtraSubClassDefaultQueryVariables
  >(GraphJinItemExtraSubClassDefaultDocument, options);
}
export function useGraphJinItemExtraSubClassDefaultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphJinItemExtraSubClassDefaultQuery,
    GraphJinItemExtraSubClassDefaultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraphJinItemExtraSubClassDefaultQuery,
    GraphJinItemExtraSubClassDefaultQueryVariables
  >(GraphJinItemExtraSubClassDefaultDocument, options);
}
export type GraphJinItemExtraSubClassDefaultQueryHookResult = ReturnType<
  typeof useGraphJinItemExtraSubClassDefaultQuery
>;
export type GraphJinItemExtraSubClassDefaultLazyQueryHookResult = ReturnType<
  typeof useGraphJinItemExtraSubClassDefaultLazyQuery
>;
export type GraphJinItemExtraSubClassDefaultQueryResult = Apollo.QueryResult<
  GraphJinItemExtraSubClassDefaultQuery,
  GraphJinItemExtraSubClassDefaultQueryVariables
>;
export const GraphJinItemExtraSubClassDefaultUpdateDocument = gql`
  mutation GraphJinItemExtraSubClassDefaultUpdate(
    $update: GraphJinItemExtraSubClassDefaultInsertInput
    $id: ID
  ) {
    graphJinItemExtraSubClassDefault(update: $update, id: $id) {
      id
      subClass
      pullByDate
      markdownDays
      captureExpiry
      acceptableShelfLife
      packingConstraint
    }
  }
`;
export type GraphJinItemExtraSubClassDefaultUpdateMutationFn = Apollo.MutationFunction<
  GraphJinItemExtraSubClassDefaultUpdateMutation,
  GraphJinItemExtraSubClassDefaultUpdateMutationVariables
>;

/**
 * __useGraphJinItemExtraSubClassDefaultUpdateMutation__
 *
 * To run a mutation, you first call `useGraphJinItemExtraSubClassDefaultUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphJinItemExtraSubClassDefaultUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphJinItemExtraSubClassDefaultUpdateMutation, { data, loading, error }] = useGraphJinItemExtraSubClassDefaultUpdateMutation({
 *   variables: {
 *      update: // value for 'update'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGraphJinItemExtraSubClassDefaultUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GraphJinItemExtraSubClassDefaultUpdateMutation,
    GraphJinItemExtraSubClassDefaultUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GraphJinItemExtraSubClassDefaultUpdateMutation,
    GraphJinItemExtraSubClassDefaultUpdateMutationVariables
  >(GraphJinItemExtraSubClassDefaultUpdateDocument, options);
}
export type GraphJinItemExtraSubClassDefaultUpdateMutationHookResult = ReturnType<
  typeof useGraphJinItemExtraSubClassDefaultUpdateMutation
>;
export type GraphJinItemExtraSubClassDefaultUpdateMutationResult =
  Apollo.MutationResult<GraphJinItemExtraSubClassDefaultUpdateMutation>;
export type GraphJinItemExtraSubClassDefaultUpdateMutationOptions = Apollo.BaseMutationOptions<
  GraphJinItemExtraSubClassDefaultUpdateMutation,
  GraphJinItemExtraSubClassDefaultUpdateMutationVariables
>;
export const GraphJinItemSubClassDocument = gql`
  query GraphJinItemSubClass($filter: GraphJinItemSubClassFilter!) {
    graphJinItemSubClass(filter: $filter) {
      name
      itemClass {
        name
        itemDepartment {
          name
        }
      }
    }
  }
`;

/**
 * __useGraphJinItemSubClassQuery__
 *
 * To run a query within a React component, call `useGraphJinItemSubClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphJinItemSubClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphJinItemSubClassQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGraphJinItemSubClassQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphJinItemSubClassQuery,
    GraphJinItemSubClassQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphJinItemSubClassQuery, GraphJinItemSubClassQueryVariables>(
    GraphJinItemSubClassDocument,
    options,
  );
}
export function useGraphJinItemSubClassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphJinItemSubClassQuery,
    GraphJinItemSubClassQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphJinItemSubClassQuery, GraphJinItemSubClassQueryVariables>(
    GraphJinItemSubClassDocument,
    options,
  );
}
export type GraphJinItemSubClassQueryHookResult = ReturnType<typeof useGraphJinItemSubClassQuery>;
export type GraphJinItemSubClassLazyQueryHookResult = ReturnType<
  typeof useGraphJinItemSubClassLazyQuery
>;
export type GraphJinItemSubClassQueryResult = Apollo.QueryResult<
  GraphJinItemSubClassQuery,
  GraphJinItemSubClassQueryVariables
>;
export const ItemSourceDocument = gql`
  query ItemSource($pagination: Pagination!, $filter: ItemSourceFilter!) {
    itemSources(pagination: $pagination, filter: $filter) {
      items {
        id
        isBreakable
        breakingFromPackageTypeID
        breakingToPackageTypeID
        breakingThreshold
        isLotSizeEnabled
        lotSizeMin
        lotSizeIncrement
        item {
          id
          extId
          name
          i18n {
            name {
              enUS
              enGB
              enCA
              frFR
              frCA
              deDE
              esES
            }
          }
        }
        opco {
          id
          name
          stateProvinceRegion
          supplier {
            extID
          }
        }
        packaging {
          id
          declaredHeight
          declaredLength
          declaredWidth
          declaredLengthUnits
          declaredWeight
          declaredWeightUnits
          declaredTi
          declaredHi
          measuredHeight
          measuredLength
          measuredWidth
          measuredLengthUnits
          measuredWeight
          measuredVolume
          declaredVolume
          measuredWeightUnits
          measuredTi
          measuredHi
          perChildPackageQty
          packageType {
            id
            name
          }
          eachUPCs
          upcCode
          supplierNumber
          maxAllocationQuantity
          roundingThreshold
          updatedAt
          updatedBy
        }
      }
      totalCount
    }
  }
`;

/**
 * __useItemSourceQuery__
 *
 * To run a query within a React component, call `useItemSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemSourceQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemSourceQuery(
  baseOptions: Apollo.QueryHookOptions<ItemSourceQuery, ItemSourceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemSourceQuery, ItemSourceQueryVariables>(ItemSourceDocument, options);
}
export function useItemSourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemSourceQuery, ItemSourceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemSourceQuery, ItemSourceQueryVariables>(
    ItemSourceDocument,
    options,
  );
}
export type ItemSourceQueryHookResult = ReturnType<typeof useItemSourceQuery>;
export type ItemSourceLazyQueryHookResult = ReturnType<typeof useItemSourceLazyQuery>;
export type ItemSourceQueryResult = Apollo.QueryResult<ItemSourceQuery, ItemSourceQueryVariables>;
export const ItemSourceDownloadDocument = gql`
  query ItemSourceDownload($filter: ItemSourceDownloadFilter!) {
    itemSourceDownload(filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useItemSourceDownloadQuery__
 *
 * To run a query within a React component, call `useItemSourceDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemSourceDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemSourceDownloadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemSourceDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<ItemSourceDownloadQuery, ItemSourceDownloadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemSourceDownloadQuery, ItemSourceDownloadQueryVariables>(
    ItemSourceDownloadDocument,
    options,
  );
}
export function useItemSourceDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ItemSourceDownloadQuery,
    ItemSourceDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemSourceDownloadQuery, ItemSourceDownloadQueryVariables>(
    ItemSourceDownloadDocument,
    options,
  );
}
export type ItemSourceDownloadQueryHookResult = ReturnType<typeof useItemSourceDownloadQuery>;
export type ItemSourceDownloadLazyQueryHookResult = ReturnType<
  typeof useItemSourceDownloadLazyQuery
>;
export type ItemSourceDownloadQueryResult = Apollo.QueryResult<
  ItemSourceDownloadQuery,
  ItemSourceDownloadQueryVariables
>;
export const ItemSourceUploadDocument = gql`
  mutation ItemSourceUpload($input: ItemSourceUploadInput!) {
    itemSourceUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type ItemSourceUploadMutationFn = Apollo.MutationFunction<
  ItemSourceUploadMutation,
  ItemSourceUploadMutationVariables
>;

/**
 * __useItemSourceUploadMutation__
 *
 * To run a mutation, you first call `useItemSourceUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemSourceUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemSourceUploadMutation, { data, loading, error }] = useItemSourceUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemSourceUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ItemSourceUploadMutation,
    ItemSourceUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ItemSourceUploadMutation, ItemSourceUploadMutationVariables>(
    ItemSourceUploadDocument,
    options,
  );
}
export type ItemSourceUploadMutationHookResult = ReturnType<typeof useItemSourceUploadMutation>;
export type ItemSourceUploadMutationResult = Apollo.MutationResult<ItemSourceUploadMutation>;
export type ItemSourceUploadMutationOptions = Apollo.BaseMutationOptions<
  ItemSourceUploadMutation,
  ItemSourceUploadMutationVariables
>;
export const UpdateItemSourceDocument = gql`
  mutation UpdateItemSource($input: ItemSourcesInput!) {
    updateItemSources(input: $input) {
      items {
        id
        item {
          id
          extId
          name
        }
        opco {
          id
          name
          supplier {
            extID
          }
        }
        packaging {
          id
          declaredHeight
          declaredLength
          declaredWidth
          declaredLengthUnits
          declaredWeight
          declaredWeightUnits
          declaredTi
          declaredHi
          measuredHeight
          measuredLength
          measuredWidth
          measuredLengthUnits
          measuredWeight
          measuredVolume
          declaredVolume
          measuredWeightUnits
          measuredTi
          measuredHi
          perChildPackageQty
          packageType {
            id
            name
          }
          eachUPCs
          upcCode
          supplierNumber
          maxAllocationQuantity
          roundingThreshold
          updatedBy
          updatedAt
        }
        isBreakable
        breakingFromPackageTypeID
        breakingToPackageTypeID
        breakingThreshold
        isLotSizeEnabled
        lotSizeMin
        lotSizeIncrement
      }
      totalCount
    }
  }
`;
export type UpdateItemSourceMutationFn = Apollo.MutationFunction<
  UpdateItemSourceMutation,
  UpdateItemSourceMutationVariables
>;

/**
 * __useUpdateItemSourceMutation__
 *
 * To run a mutation, you first call `useUpdateItemSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemSourceMutation, { data, loading, error }] = useUpdateItemSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItemSourceMutation,
    UpdateItemSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateItemSourceMutation, UpdateItemSourceMutationVariables>(
    UpdateItemSourceDocument,
    options,
  );
}
export type UpdateItemSourceMutationHookResult = ReturnType<typeof useUpdateItemSourceMutation>;
export type UpdateItemSourceMutationResult = Apollo.MutationResult<UpdateItemSourceMutation>;
export type UpdateItemSourceMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemSourceMutation,
  UpdateItemSourceMutationVariables
>;
export const LocationDocument = gql`
  query Location($pagination: Pagination!, $filter: LocationFilter!) {
    locations(pagination: $pagination, filter: $filter) {
      locations {
        id
        name
        isThirdParty
        stopBuying
        address
        type
        openFrom
        openTo
        extId
        city
        stateProvinceRegion
        postalCode
        metroRegion
        districtId
        regionId
        ageOutThreshold
        updatedAt
        updatedBy
        locationExtraUpdatedAt
        locationExtraUpdatedBy
        locationCapabilitiesUpdatedAt
        locationCapabilitiesUpdatedBy
        locationAlcoholCapabilitiesUpdatedAt
        locationAlcoholCapabilitiesUpdatedBy
        locationCustomAssortmentsUpdatedAt
        locationCustomAssortmentsUpdatedBy
        locationTobaccoCapabilitiesUpdatedAt
        locationTobaccoCapabilitiesUpdatedBy
        nationalAssortment {
          id
          name
        }
        allocationPriority {
          id
          name
        }
        locationCustomAssortments {
          id
          updatedAt
          updatedBy
        }
        locationAlcoholCapabilities {
          id
          name
          updatedAt
          updatedBy
          __typename @skip(if: true)
          volumes {
            id
            volume
            description
            __typename @skip(if: true)
          }
        }
        locationTobaccoCapabilities {
          id
          updatedAt
          updatedBy
        }
        locationCapabilities {
          id
          updatedAt
          updatedBy
        }
      }
      totalCount
    }
  }
`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationQuery(
  baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
}
export function useLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>;
export const LocationDownloadDocument = gql`
  query LocationDownload($filter: LocationDownloadFilter) {
    locationDownload(filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useLocationDownloadQuery__
 *
 * To run a query within a React component, call `useLocationDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDownloadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationDownloadQuery(
  baseOptions?: Apollo.QueryHookOptions<LocationDownloadQuery, LocationDownloadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationDownloadQuery, LocationDownloadQueryVariables>(
    LocationDownloadDocument,
    options,
  );
}
export function useLocationDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationDownloadQuery, LocationDownloadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationDownloadQuery, LocationDownloadQueryVariables>(
    LocationDownloadDocument,
    options,
  );
}
export type LocationDownloadQueryHookResult = ReturnType<typeof useLocationDownloadQuery>;
export type LocationDownloadLazyQueryHookResult = ReturnType<typeof useLocationDownloadLazyQuery>;
export type LocationDownloadQueryResult = Apollo.QueryResult<
  LocationDownloadQuery,
  LocationDownloadQueryVariables
>;
export const LocationOptionsDocument = gql`
  query LocationOptions {
    nationalAssortments {
      id
      name
    }
    allocationPriorities {
      id
      name
    }
    customAssortments {
      id
      name
    }
    alcoholTypes {
      id
      name
      volumes {
        id
        volume
        description
      }
    }
    tobaccoTypes {
      id
      name: restrictionCode
    }
    capabilities {
      id
      name
    }
  }
`;

/**
 * __useLocationOptionsQuery__
 *
 * To run a query within a React component, call `useLocationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<LocationOptionsQuery, LocationOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationOptionsQuery, LocationOptionsQueryVariables>(
    LocationOptionsDocument,
    options,
  );
}
export function useLocationOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationOptionsQuery, LocationOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationOptionsQuery, LocationOptionsQueryVariables>(
    LocationOptionsDocument,
    options,
  );
}
export type LocationOptionsQueryHookResult = ReturnType<typeof useLocationOptionsQuery>;
export type LocationOptionsLazyQueryHookResult = ReturnType<typeof useLocationOptionsLazyQuery>;
export type LocationOptionsQueryResult = Apollo.QueryResult<
  LocationOptionsQuery,
  LocationOptionsQueryVariables
>;
export const LocationUploadDocument = gql`
  mutation LocationUpload($input: LocationUploadInput!) {
    locationUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type LocationUploadMutationFn = Apollo.MutationFunction<
  LocationUploadMutation,
  LocationUploadMutationVariables
>;

/**
 * __useLocationUploadMutation__
 *
 * To run a mutation, you first call `useLocationUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationUploadMutation, { data, loading, error }] = useLocationUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<LocationUploadMutation, LocationUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LocationUploadMutation, LocationUploadMutationVariables>(
    LocationUploadDocument,
    options,
  );
}
export type LocationUploadMutationHookResult = ReturnType<typeof useLocationUploadMutation>;
export type LocationUploadMutationResult = Apollo.MutationResult<LocationUploadMutation>;
export type LocationUploadMutationOptions = Apollo.BaseMutationOptions<
  LocationUploadMutation,
  LocationUploadMutationVariables
>;
export const UpdateLocationDocument = gql`
  mutation UpdateLocation($input: LocationsInput!) {
    updateLocations(input: $input) {
      locations {
        id
        name
        isThirdParty
        stopBuying
        address
        city
        stateProvinceRegion
        postalCode
        type
        openFrom
        openTo
        extId
        ageOutThreshold
        updatedAt
        updatedBy
        locationExtraUpdatedBy
        locationExtraUpdatedAt
        locationCapabilitiesUpdatedAt
        locationCapabilitiesUpdatedBy
        locationAlcoholCapabilitiesUpdatedAt
        locationAlcoholCapabilitiesUpdatedBy
        locationCustomAssortmentsUpdatedAt
        locationCustomAssortmentsUpdatedBy
        locationTobaccoCapabilitiesUpdatedAt
        locationTobaccoCapabilitiesUpdatedBy
        nationalAssortment {
          id
          name
        }
        allocationPriority {
          id
          name
        }
        locationCustomAssortments {
          id
          updatedAt
          updatedBy
        }
        locationAlcoholCapabilities {
          id
          name
          updatedAt
          updatedBy
          __typename @skip(if: true)
          volumes {
            id
            volume
            description
            __typename @skip(if: true)
          }
        }
        locationTobaccoCapabilities {
          id
          updatedAt
          updatedBy
        }
        locationCapabilities {
          id
          updatedAt
          updatedBy
        }
      }
    }
  }
`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(
    UpdateLocationDocument,
    options,
  );
}
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
export const GraphJinLeadTimeOverrideDocument = gql`
  query GraphJinLeadTimeOverride($filter: GraphJinLeadTimeOverrideFilter!) {
    graphJinLeadTimeOverride(filter: $filter) {
      id
      transportationLaneId
      weekStartDate
      monLeadTime
      tueLeadTime
      wedLeadTime
      thuLeadTime
      friLeadTime
      satLeadTime
      sunLeadTime
    }
  }
`;

/**
 * __useGraphJinLeadTimeOverrideQuery__
 *
 * To run a query within a React component, call `useGraphJinLeadTimeOverrideQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphJinLeadTimeOverrideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphJinLeadTimeOverrideQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGraphJinLeadTimeOverrideQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphJinLeadTimeOverrideQuery,
    GraphJinLeadTimeOverrideQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphJinLeadTimeOverrideQuery, GraphJinLeadTimeOverrideQueryVariables>(
    GraphJinLeadTimeOverrideDocument,
    options,
  );
}
export function useGraphJinLeadTimeOverrideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphJinLeadTimeOverrideQuery,
    GraphJinLeadTimeOverrideQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphJinLeadTimeOverrideQuery, GraphJinLeadTimeOverrideQueryVariables>(
    GraphJinLeadTimeOverrideDocument,
    options,
  );
}
export type GraphJinLeadTimeOverrideQueryHookResult = ReturnType<
  typeof useGraphJinLeadTimeOverrideQuery
>;
export type GraphJinLeadTimeOverrideLazyQueryHookResult = ReturnType<
  typeof useGraphJinLeadTimeOverrideLazyQuery
>;
export type GraphJinLeadTimeOverrideQueryResult = Apollo.QueryResult<
  GraphJinLeadTimeOverrideQuery,
  GraphJinLeadTimeOverrideQueryVariables
>;
export const GraphJinLeadTimeOverrideDeleteDocument = gql`
  mutation GraphJinLeadTimeOverrideDelete($deleting: Boolean, $id: ID) {
    graphJinLeadTimeOverride(deleting: $deleting, id: $id) {
      id
    }
  }
`;
export type GraphJinLeadTimeOverrideDeleteMutationFn = Apollo.MutationFunction<
  GraphJinLeadTimeOverrideDeleteMutation,
  GraphJinLeadTimeOverrideDeleteMutationVariables
>;

/**
 * __useGraphJinLeadTimeOverrideDeleteMutation__
 *
 * To run a mutation, you first call `useGraphJinLeadTimeOverrideDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphJinLeadTimeOverrideDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphJinLeadTimeOverrideDeleteMutation, { data, loading, error }] = useGraphJinLeadTimeOverrideDeleteMutation({
 *   variables: {
 *      deleting: // value for 'deleting'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGraphJinLeadTimeOverrideDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GraphJinLeadTimeOverrideDeleteMutation,
    GraphJinLeadTimeOverrideDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GraphJinLeadTimeOverrideDeleteMutation,
    GraphJinLeadTimeOverrideDeleteMutationVariables
  >(GraphJinLeadTimeOverrideDeleteDocument, options);
}
export type GraphJinLeadTimeOverrideDeleteMutationHookResult = ReturnType<
  typeof useGraphJinLeadTimeOverrideDeleteMutation
>;
export type GraphJinLeadTimeOverrideDeleteMutationResult =
  Apollo.MutationResult<GraphJinLeadTimeOverrideDeleteMutation>;
export type GraphJinLeadTimeOverrideDeleteMutationOptions = Apollo.BaseMutationOptions<
  GraphJinLeadTimeOverrideDeleteMutation,
  GraphJinLeadTimeOverrideDeleteMutationVariables
>;
export const GraphJinLeadTimeOverrideInsertDocument = gql`
  mutation GraphJinLeadTimeOverrideInsert($insert: [GraphJinLeadTimeOverrideInsertInput!]) {
    graphJinLeadTimeOverride(insert: $insert) {
      id
      transportationLaneId
      weekStartDate
      monLeadTime
      tueLeadTime
      wedLeadTime
      thuLeadTime
      friLeadTime
      satLeadTime
      sunLeadTime
    }
  }
`;
export type GraphJinLeadTimeOverrideInsertMutationFn = Apollo.MutationFunction<
  GraphJinLeadTimeOverrideInsertMutation,
  GraphJinLeadTimeOverrideInsertMutationVariables
>;

/**
 * __useGraphJinLeadTimeOverrideInsertMutation__
 *
 * To run a mutation, you first call `useGraphJinLeadTimeOverrideInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphJinLeadTimeOverrideInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphJinLeadTimeOverrideInsertMutation, { data, loading, error }] = useGraphJinLeadTimeOverrideInsertMutation({
 *   variables: {
 *      insert: // value for 'insert'
 *   },
 * });
 */
export function useGraphJinLeadTimeOverrideInsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GraphJinLeadTimeOverrideInsertMutation,
    GraphJinLeadTimeOverrideInsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GraphJinLeadTimeOverrideInsertMutation,
    GraphJinLeadTimeOverrideInsertMutationVariables
  >(GraphJinLeadTimeOverrideInsertDocument, options);
}
export type GraphJinLeadTimeOverrideInsertMutationHookResult = ReturnType<
  typeof useGraphJinLeadTimeOverrideInsertMutation
>;
export type GraphJinLeadTimeOverrideInsertMutationResult =
  Apollo.MutationResult<GraphJinLeadTimeOverrideInsertMutation>;
export type GraphJinLeadTimeOverrideInsertMutationOptions = Apollo.BaseMutationOptions<
  GraphJinLeadTimeOverrideInsertMutation,
  GraphJinLeadTimeOverrideInsertMutationVariables
>;
export const GraphJinLeadTimeOverrideUpdateDocument = gql`
  mutation GraphJinLeadTimeOverrideUpdate($update: GraphJinLeadTimeOverrideInsertInput, $id: ID) {
    graphJinLeadTimeOverride(update: $update, id: $id) {
      id
      transportationLaneId
      weekStartDate
      monLeadTime
      tueLeadTime
      wedLeadTime
      thuLeadTime
      friLeadTime
      satLeadTime
      sunLeadTime
    }
  }
`;
export type GraphJinLeadTimeOverrideUpdateMutationFn = Apollo.MutationFunction<
  GraphJinLeadTimeOverrideUpdateMutation,
  GraphJinLeadTimeOverrideUpdateMutationVariables
>;

/**
 * __useGraphJinLeadTimeOverrideUpdateMutation__
 *
 * To run a mutation, you first call `useGraphJinLeadTimeOverrideUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphJinLeadTimeOverrideUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphJinLeadTimeOverrideUpdateMutation, { data, loading, error }] = useGraphJinLeadTimeOverrideUpdateMutation({
 *   variables: {
 *      update: // value for 'update'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGraphJinLeadTimeOverrideUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GraphJinLeadTimeOverrideUpdateMutation,
    GraphJinLeadTimeOverrideUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GraphJinLeadTimeOverrideUpdateMutation,
    GraphJinLeadTimeOverrideUpdateMutationVariables
  >(GraphJinLeadTimeOverrideUpdateDocument, options);
}
export type GraphJinLeadTimeOverrideUpdateMutationHookResult = ReturnType<
  typeof useGraphJinLeadTimeOverrideUpdateMutation
>;
export type GraphJinLeadTimeOverrideUpdateMutationResult =
  Apollo.MutationResult<GraphJinLeadTimeOverrideUpdateMutation>;
export type GraphJinLeadTimeOverrideUpdateMutationOptions = Apollo.BaseMutationOptions<
  GraphJinLeadTimeOverrideUpdateMutation,
  GraphJinLeadTimeOverrideUpdateMutationVariables
>;
export const SourceDestinationDocument = gql`
  query SourceDestination($pagination: Pagination!, $filter: SourceDestinationFilter!) {
    sourceDestinations(pagination: $pagination, filter: $filter) {
      destinations {
        id
        accountNumber
        source {
          ... on Location {
            id
            name
          }
          ... on Opco {
            id
            name
            stateProvinceRegion
            supplier {
              extID
            }
          }
        }
        location {
          id
          extId
          name
        }
        isActive
        monLeadTime
        tueLeadTime
        wedLeadTime
        thuLeadTime
        friLeadTime
        satLeadTime
        sunLeadTime
        autoRelease
        capacities {
          id
          containerMin
          containerMax
          containerUom {
            id
          }
        }
        gopuffTransport
        transitType
        deliveryWindowFrom
        deliveryWindowTo
        notActiveSince
        createdAt
        updatedAt
        updatedBy
        orderFrequency
        fillKillThreshold
        minimumOrderValue
        movCurrencyId
        minimumOrderQuantity
        moqOrderingUomId
        minimumOrderWeight
        mowSystemOfMeasureId
        autoOrder
        valueOveragePercent
        valueOverageValue
        valueUnderagePercent
        valueUnderageValue
        quantityOveragePercent
        quantityOverageCount
        quantityUnderagePercent
        quantityUnderageCount
      }
      totalCount
    }
  }
`;

/**
 * __useSourceDestinationQuery__
 *
 * To run a query within a React component, call `useSourceDestinationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceDestinationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceDestinationQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSourceDestinationQuery(
  baseOptions: Apollo.QueryHookOptions<SourceDestinationQuery, SourceDestinationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SourceDestinationQuery, SourceDestinationQueryVariables>(
    SourceDestinationDocument,
    options,
  );
}
export function useSourceDestinationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SourceDestinationQuery,
    SourceDestinationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SourceDestinationQuery, SourceDestinationQueryVariables>(
    SourceDestinationDocument,
    options,
  );
}
export type SourceDestinationQueryHookResult = ReturnType<typeof useSourceDestinationQuery>;
export type SourceDestinationLazyQueryHookResult = ReturnType<typeof useSourceDestinationLazyQuery>;
export type SourceDestinationQueryResult = Apollo.QueryResult<
  SourceDestinationQuery,
  SourceDestinationQueryVariables
>;
export const SourceDestinationDownloadDocument = gql`
  query SourceDestinationDownload($filter: SourceDestinationDownloadFilter!) {
    sourceDestinationDownload(filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useSourceDestinationDownloadQuery__
 *
 * To run a query within a React component, call `useSourceDestinationDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceDestinationDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceDestinationDownloadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSourceDestinationDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<
    SourceDestinationDownloadQuery,
    SourceDestinationDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SourceDestinationDownloadQuery, SourceDestinationDownloadQueryVariables>(
    SourceDestinationDownloadDocument,
    options,
  );
}
export function useSourceDestinationDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SourceDestinationDownloadQuery,
    SourceDestinationDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SourceDestinationDownloadQuery,
    SourceDestinationDownloadQueryVariables
  >(SourceDestinationDownloadDocument, options);
}
export type SourceDestinationDownloadQueryHookResult = ReturnType<
  typeof useSourceDestinationDownloadQuery
>;
export type SourceDestinationDownloadLazyQueryHookResult = ReturnType<
  typeof useSourceDestinationDownloadLazyQuery
>;
export type SourceDestinationDownloadQueryResult = Apollo.QueryResult<
  SourceDestinationDownloadQuery,
  SourceDestinationDownloadQueryVariables
>;
export const SourceDestinationOptionsDocument = gql`
  query SourceDestinationOptions {
    containerUom {
      id
      name
    }
  }
`;

/**
 * __useSourceDestinationOptionsQuery__
 *
 * To run a query within a React component, call `useSourceDestinationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceDestinationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceDestinationOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSourceDestinationOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SourceDestinationOptionsQuery,
    SourceDestinationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SourceDestinationOptionsQuery, SourceDestinationOptionsQueryVariables>(
    SourceDestinationOptionsDocument,
    options,
  );
}
export function useSourceDestinationOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SourceDestinationOptionsQuery,
    SourceDestinationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SourceDestinationOptionsQuery, SourceDestinationOptionsQueryVariables>(
    SourceDestinationOptionsDocument,
    options,
  );
}
export type SourceDestinationOptionsQueryHookResult = ReturnType<
  typeof useSourceDestinationOptionsQuery
>;
export type SourceDestinationOptionsLazyQueryHookResult = ReturnType<
  typeof useSourceDestinationOptionsLazyQuery
>;
export type SourceDestinationOptionsQueryResult = Apollo.QueryResult<
  SourceDestinationOptionsQuery,
  SourceDestinationOptionsQueryVariables
>;
export const SourceDestinationUploadDocument = gql`
  mutation SourceDestinationUpload($input: SourceDestinationUploadInput!) {
    sourceDestinationUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type SourceDestinationUploadMutationFn = Apollo.MutationFunction<
  SourceDestinationUploadMutation,
  SourceDestinationUploadMutationVariables
>;

/**
 * __useSourceDestinationUploadMutation__
 *
 * To run a mutation, you first call `useSourceDestinationUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourceDestinationUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourceDestinationUploadMutation, { data, loading, error }] = useSourceDestinationUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSourceDestinationUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SourceDestinationUploadMutation,
    SourceDestinationUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SourceDestinationUploadMutation,
    SourceDestinationUploadMutationVariables
  >(SourceDestinationUploadDocument, options);
}
export type SourceDestinationUploadMutationHookResult = ReturnType<
  typeof useSourceDestinationUploadMutation
>;
export type SourceDestinationUploadMutationResult =
  Apollo.MutationResult<SourceDestinationUploadMutation>;
export type SourceDestinationUploadMutationOptions = Apollo.BaseMutationOptions<
  SourceDestinationUploadMutation,
  SourceDestinationUploadMutationVariables
>;
export const UpdateSourceDestinationDocument = gql`
  mutation UpdateSourceDestination($input: SourceDestinationsInput!) {
    updateSourceDestinations(input: $input) {
      destinations {
        id
        isActive
        fillKillThreshold
        monLeadTime
        tueLeadTime
        wedLeadTime
        thuLeadTime
        friLeadTime
        satLeadTime
        sunLeadTime
        autoRelease
        capacities {
          id
          containerMin
          containerMax
          containerUom {
            id
          }
        }
        gopuffTransport
        transitType
        deliveryWindowFrom
        deliveryWindowTo
        notActiveSince
        accountNumber
        createdAt
        updatedAt
        updatedBy
        orderFrequency
        minimumOrderValue
        movCurrencyId
        minimumOrderQuantity
        moqOrderingUomId
        minimumOrderWeight
        mowSystemOfMeasureId
        autoOrder
        valueOveragePercent
        valueOverageValue
        valueUnderagePercent
        valueUnderageValue
        quantityOveragePercent
        quantityOverageCount
        quantityUnderagePercent
        quantityUnderageCount
      }
    }
  }
`;
export type UpdateSourceDestinationMutationFn = Apollo.MutationFunction<
  UpdateSourceDestinationMutation,
  UpdateSourceDestinationMutationVariables
>;

/**
 * __useUpdateSourceDestinationMutation__
 *
 * To run a mutation, you first call `useUpdateSourceDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourceDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourceDestinationMutation, { data, loading, error }] = useUpdateSourceDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSourceDestinationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSourceDestinationMutation,
    UpdateSourceDestinationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSourceDestinationMutation,
    UpdateSourceDestinationMutationVariables
  >(UpdateSourceDestinationDocument, options);
}
export type UpdateSourceDestinationMutationHookResult = ReturnType<
  typeof useUpdateSourceDestinationMutation
>;
export type UpdateSourceDestinationMutationResult =
  Apollo.MutationResult<UpdateSourceDestinationMutation>;
export type UpdateSourceDestinationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSourceDestinationMutation,
  UpdateSourceDestinationMutationVariables
>;
export const MassEditSupplierAssortmentsDocument = gql`
  mutation MassEditSupplierAssortments($input: SupplierAssortmentsMassEditInput!) {
    massEditSupplierAssortments(input: $input) {
      totalCount
      assortments {
        id
        managedBy {
          displayName
          id
        }
        item {
          id
          extId
          name
          i18n {
            name {
              enUS
              enGB
              enCA
              frFR
              frCA
              deDE
              esES
            }
          }
          upc
        }
        opco {
          id
          ext_id
          name
          stateProvinceRegion
          updatedBy
          updatedAt
        }
      }
    }
  }
`;
export type MassEditSupplierAssortmentsMutationFn = Apollo.MutationFunction<
  MassEditSupplierAssortmentsMutation,
  MassEditSupplierAssortmentsMutationVariables
>;

/**
 * __useMassEditSupplierAssortmentsMutation__
 *
 * To run a mutation, you first call `useMassEditSupplierAssortmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassEditSupplierAssortmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massEditSupplierAssortmentsMutation, { data, loading, error }] = useMassEditSupplierAssortmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassEditSupplierAssortmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MassEditSupplierAssortmentsMutation,
    MassEditSupplierAssortmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MassEditSupplierAssortmentsMutation,
    MassEditSupplierAssortmentsMutationVariables
  >(MassEditSupplierAssortmentsDocument, options);
}
export type MassEditSupplierAssortmentsMutationHookResult = ReturnType<
  typeof useMassEditSupplierAssortmentsMutation
>;
export type MassEditSupplierAssortmentsMutationResult =
  Apollo.MutationResult<MassEditSupplierAssortmentsMutation>;
export type MassEditSupplierAssortmentsMutationOptions = Apollo.BaseMutationOptions<
  MassEditSupplierAssortmentsMutation,
  MassEditSupplierAssortmentsMutationVariables
>;
export const SupplierAssortmentDocument = gql`
  query SupplierAssortment(
    $pagination: Pagination!
    $sorting: SupplierAssortmentSort!
    $filter: SupplierAssortmentFilter!
  ) {
    supplierAssortments(pagination: $pagination, sorting: $sorting, filter: $filter) {
      totalCount
      assortments {
        id
        managedBy {
          displayName
          id
        }
        item {
          id
          extId
          name
          i18n {
            name {
              enUS
              enGB
              enCA
              frFR
              frCA
              deDE
              esES
            }
          }
          upc
        }
        opco {
          id
          ext_id
          name
          stateProvinceRegion
          updatedAt
          updatedBy
        }
      }
    }
  }
`;

/**
 * __useSupplierAssortmentQuery__
 *
 * To run a query within a React component, call `useSupplierAssortmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierAssortmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierAssortmentQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSupplierAssortmentQuery(
  baseOptions: Apollo.QueryHookOptions<SupplierAssortmentQuery, SupplierAssortmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupplierAssortmentQuery, SupplierAssortmentQueryVariables>(
    SupplierAssortmentDocument,
    options,
  );
}
export function useSupplierAssortmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierAssortmentQuery,
    SupplierAssortmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupplierAssortmentQuery, SupplierAssortmentQueryVariables>(
    SupplierAssortmentDocument,
    options,
  );
}
export type SupplierAssortmentQueryHookResult = ReturnType<typeof useSupplierAssortmentQuery>;
export type SupplierAssortmentLazyQueryHookResult = ReturnType<
  typeof useSupplierAssortmentLazyQuery
>;
export type SupplierAssortmentQueryResult = Apollo.QueryResult<
  SupplierAssortmentQuery,
  SupplierAssortmentQueryVariables
>;
export const SupplierAssortmentDownloadDocument = gql`
  query SupplierAssortmentDownload(
    $view: SupplierAssortmentDownloadView
    $filter: SupplierAssortmentDownloadFilter
  ) {
    supplierAssortmentDownload(view: $view, filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useSupplierAssortmentDownloadQuery__
 *
 * To run a query within a React component, call `useSupplierAssortmentDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierAssortmentDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierAssortmentDownloadQuery({
 *   variables: {
 *      view: // value for 'view'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSupplierAssortmentDownloadQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SupplierAssortmentDownloadQuery,
    SupplierAssortmentDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupplierAssortmentDownloadQuery, SupplierAssortmentDownloadQueryVariables>(
    SupplierAssortmentDownloadDocument,
    options,
  );
}
export function useSupplierAssortmentDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierAssortmentDownloadQuery,
    SupplierAssortmentDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SupplierAssortmentDownloadQuery,
    SupplierAssortmentDownloadQueryVariables
  >(SupplierAssortmentDownloadDocument, options);
}
export type SupplierAssortmentDownloadQueryHookResult = ReturnType<
  typeof useSupplierAssortmentDownloadQuery
>;
export type SupplierAssortmentDownloadLazyQueryHookResult = ReturnType<
  typeof useSupplierAssortmentDownloadLazyQuery
>;
export type SupplierAssortmentDownloadQueryResult = Apollo.QueryResult<
  SupplierAssortmentDownloadQuery,
  SupplierAssortmentDownloadQueryVariables
>;
export const SupplierAssortmentUploadDocument = gql`
  mutation SupplierAssortmentUpload($input: SupplierAssortmentUploadInput!) {
    supplierAssortmentUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type SupplierAssortmentUploadMutationFn = Apollo.MutationFunction<
  SupplierAssortmentUploadMutation,
  SupplierAssortmentUploadMutationVariables
>;

/**
 * __useSupplierAssortmentUploadMutation__
 *
 * To run a mutation, you first call `useSupplierAssortmentUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierAssortmentUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierAssortmentUploadMutation, { data, loading, error }] = useSupplierAssortmentUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierAssortmentUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SupplierAssortmentUploadMutation,
    SupplierAssortmentUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SupplierAssortmentUploadMutation,
    SupplierAssortmentUploadMutationVariables
  >(SupplierAssortmentUploadDocument, options);
}
export type SupplierAssortmentUploadMutationHookResult = ReturnType<
  typeof useSupplierAssortmentUploadMutation
>;
export type SupplierAssortmentUploadMutationResult =
  Apollo.MutationResult<SupplierAssortmentUploadMutation>;
export type SupplierAssortmentUploadMutationOptions = Apollo.BaseMutationOptions<
  SupplierAssortmentUploadMutation,
  SupplierAssortmentUploadMutationVariables
>;
export const UpdateSupplierAssortmentDocument = gql`
  mutation UpdateSupplierAssortment($input: [SupplierAssortmentUpdateInput!]!) {
    updateSupplierAssortments(input: $input) {
      id
      managedBy {
        id
        displayName
      }
      item {
        id
        extId
        name
        upc
      }
      opco {
        id
        ext_id
        name
        updatedAt
        updatedBy
      }
    }
  }
`;
export type UpdateSupplierAssortmentMutationFn = Apollo.MutationFunction<
  UpdateSupplierAssortmentMutation,
  UpdateSupplierAssortmentMutationVariables
>;

/**
 * __useUpdateSupplierAssortmentMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierAssortmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierAssortmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierAssortmentMutation, { data, loading, error }] = useUpdateSupplierAssortmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierAssortmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSupplierAssortmentMutation,
    UpdateSupplierAssortmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSupplierAssortmentMutation,
    UpdateSupplierAssortmentMutationVariables
  >(UpdateSupplierAssortmentDocument, options);
}
export type UpdateSupplierAssortmentMutationHookResult = ReturnType<
  typeof useUpdateSupplierAssortmentMutation
>;
export type UpdateSupplierAssortmentMutationResult =
  Apollo.MutationResult<UpdateSupplierAssortmentMutation>;
export type UpdateSupplierAssortmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupplierAssortmentMutation,
  UpdateSupplierAssortmentMutationVariables
>;
export const BuyersDocument = gql`
  query Buyers {
    buyers {
      email
    }
  }
`;

/**
 * __useBuyersQuery__
 *
 * To run a query within a React component, call `useBuyersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBuyersQuery(
  baseOptions?: Apollo.QueryHookOptions<BuyersQuery, BuyersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuyersQuery, BuyersQueryVariables>(BuyersDocument, options);
}
export function useBuyersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuyersQuery, BuyersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuyersQuery, BuyersQueryVariables>(BuyersDocument, options);
}
export type BuyersQueryHookResult = ReturnType<typeof useBuyersQuery>;
export type BuyersLazyQueryHookResult = ReturnType<typeof useBuyersLazyQuery>;
export type BuyersQueryResult = Apollo.QueryResult<BuyersQuery, BuyersQueryVariables>;
export const SuppliersDocument = gql`
  query Suppliers($pagination: Pagination!, $filter: SupplierFilter!) {
    suppliers(pagination: $pagination, filter: $filter) {
      totalCount
      suppliers {
        id
        extID
        name
        isActive
        opcos {
          id
          ext_id
          name
          isActive
          country
          stateProvinceRegion
          city
          postalCode
          address
          packageTypeId
          sendToTraverse
          buyer
        }
      }
    }
  }
`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSuppliersQuery(
  baseOptions: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
}
export function useSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const UpdateOpcoDocument = gql`
  mutation UpdateOpco($input: OpcosInput!) {
    updateOpcos(input: $input) {
      totalCount
      opcos {
        id
        ext_id
        name
        isActive
        country
        stateProvinceRegion
        city
        postalCode
        address
        packageTypeId
        sendToTraverse
        buyer
      }
    }
  }
`;
export type UpdateOpcoMutationFn = Apollo.MutationFunction<
  UpdateOpcoMutation,
  UpdateOpcoMutationVariables
>;

/**
 * __useUpdateOpcoMutation__
 *
 * To run a mutation, you first call `useUpdateOpcoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpcoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpcoMutation, { data, loading, error }] = useUpdateOpcoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpcoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOpcoMutation, UpdateOpcoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOpcoMutation, UpdateOpcoMutationVariables>(
    UpdateOpcoDocument,
    options,
  );
}
export type UpdateOpcoMutationHookResult = ReturnType<typeof useUpdateOpcoMutation>;
export type UpdateOpcoMutationResult = Apollo.MutationResult<UpdateOpcoMutation>;
export type UpdateOpcoMutationOptions = Apollo.BaseMutationOptions<
  UpdateOpcoMutation,
  UpdateOpcoMutationVariables
>;
export const MassEditTransportationLanesDocument = gql`
  mutation MassEditTransportationLanes($input: TransportationLanesMassEditInput!) {
    massEditTransportationLanes(input: $input) {
      transportationLanes {
        id
        source {
          ... on Location {
            id
            name
            updatedAt
            updatedBy
          }
          ... on Opco {
            id
            name
            stateProvinceRegion
            updatedAt
            updatedBy
          }
        }
        destination {
          id
          extId
          name
          updatedAt
          updatedBy
        }
        accountNumber
        updatedBy
        updatedAt
      }
      totalCount
    }
  }
`;
export type MassEditTransportationLanesMutationFn = Apollo.MutationFunction<
  MassEditTransportationLanesMutation,
  MassEditTransportationLanesMutationVariables
>;

/**
 * __useMassEditTransportationLanesMutation__
 *
 * To run a mutation, you first call `useMassEditTransportationLanesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassEditTransportationLanesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massEditTransportationLanesMutation, { data, loading, error }] = useMassEditTransportationLanesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMassEditTransportationLanesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MassEditTransportationLanesMutation,
    MassEditTransportationLanesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MassEditTransportationLanesMutation,
    MassEditTransportationLanesMutationVariables
  >(MassEditTransportationLanesDocument, options);
}
export type MassEditTransportationLanesMutationHookResult = ReturnType<
  typeof useMassEditTransportationLanesMutation
>;
export type MassEditTransportationLanesMutationResult =
  Apollo.MutationResult<MassEditTransportationLanesMutation>;
export type MassEditTransportationLanesMutationOptions = Apollo.BaseMutationOptions<
  MassEditTransportationLanesMutation,
  MassEditTransportationLanesMutationVariables
>;
export const TransportationLaneDownloadDocument = gql`
  query TransportationLaneDownload(
    $view: TransportationLaneDownloadView
    $filter: TransportationLaneDownloadFilter
  ) {
    transportationLaneDownload(view: $view, filter: $filter) {
      file {
        name
        content
      }
    }
  }
`;

/**
 * __useTransportationLaneDownloadQuery__
 *
 * To run a query within a React component, call `useTransportationLaneDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransportationLaneDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransportationLaneDownloadQuery({
 *   variables: {
 *      view: // value for 'view'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTransportationLaneDownloadQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransportationLaneDownloadQuery,
    TransportationLaneDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransportationLaneDownloadQuery, TransportationLaneDownloadQueryVariables>(
    TransportationLaneDownloadDocument,
    options,
  );
}
export function useTransportationLaneDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransportationLaneDownloadQuery,
    TransportationLaneDownloadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransportationLaneDownloadQuery,
    TransportationLaneDownloadQueryVariables
  >(TransportationLaneDownloadDocument, options);
}
export type TransportationLaneDownloadQueryHookResult = ReturnType<
  typeof useTransportationLaneDownloadQuery
>;
export type TransportationLaneDownloadLazyQueryHookResult = ReturnType<
  typeof useTransportationLaneDownloadLazyQuery
>;
export type TransportationLaneDownloadQueryResult = Apollo.QueryResult<
  TransportationLaneDownloadQuery,
  TransportationLaneDownloadQueryVariables
>;
export const TransportationLaneUploadDocument = gql`
  mutation TransportationLaneUpload($input: TransportationLaneUploadInput!) {
    transportationLaneUpload(input: $input) {
      file {
        name
        content
      }
      total
      errors
      success
    }
  }
`;
export type TransportationLaneUploadMutationFn = Apollo.MutationFunction<
  TransportationLaneUploadMutation,
  TransportationLaneUploadMutationVariables
>;

/**
 * __useTransportationLaneUploadMutation__
 *
 * To run a mutation, you first call `useTransportationLaneUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransportationLaneUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transportationLaneUploadMutation, { data, loading, error }] = useTransportationLaneUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransportationLaneUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransportationLaneUploadMutation,
    TransportationLaneUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransportationLaneUploadMutation,
    TransportationLaneUploadMutationVariables
  >(TransportationLaneUploadDocument, options);
}
export type TransportationLaneUploadMutationHookResult = ReturnType<
  typeof useTransportationLaneUploadMutation
>;
export type TransportationLaneUploadMutationResult =
  Apollo.MutationResult<TransportationLaneUploadMutation>;
export type TransportationLaneUploadMutationOptions = Apollo.BaseMutationOptions<
  TransportationLaneUploadMutation,
  TransportationLaneUploadMutationVariables
>;
export const TransportationLanesDocument = gql`
  query TransportationLanes(
    $pagination: Pagination!
    $sorting: TransportationLaneSort!
    $filter: TransportationLaneFilter!
  ) {
    transportationLanes(pagination: $pagination, sorting: $sorting, filter: $filter) {
      totalCount
      transportationLanes {
        id
        accountNumber
        updatedBy
        updatedAt
        source {
          ... on Location {
            id
            name
            updatedBy
            updatedAt
          }
          ... on Opco {
            id
            name
            stateProvinceRegion
            updatedBy
            updatedAt
          }
        }
        destination {
          id
          extId
          name
          updatedBy
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useTransportationLanesQuery__
 *
 * To run a query within a React component, call `useTransportationLanesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransportationLanesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransportationLanesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTransportationLanesQuery(
  baseOptions: Apollo.QueryHookOptions<TransportationLanesQuery, TransportationLanesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransportationLanesQuery, TransportationLanesQueryVariables>(
    TransportationLanesDocument,
    options,
  );
}
export function useTransportationLanesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransportationLanesQuery,
    TransportationLanesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransportationLanesQuery, TransportationLanesQueryVariables>(
    TransportationLanesDocument,
    options,
  );
}
export type TransportationLanesQueryHookResult = ReturnType<typeof useTransportationLanesQuery>;
export type TransportationLanesLazyQueryHookResult = ReturnType<
  typeof useTransportationLanesLazyQuery
>;
export type TransportationLanesQueryResult = Apollo.QueryResult<
  TransportationLanesQuery,
  TransportationLanesQueryVariables
>;
export const UpdateTransportationLanesAccountIdDocument = gql`
  mutation UpdateTransportationLanesAccountId($input: [TransportationLaneUpdateInput!]!) {
    updateTransportationLanesAccountId(input: $input) {
      id
      source {
        ... on Location {
          id
          name
          updatedAt
          updatedBy
        }
        ... on Opco {
          id
          name
          updatedAt
          updatedBy
        }
      }
      destination {
        id
        extId
        name
        updatedAt
        updatedBy
      }
      accountNumber
    }
  }
`;
export type UpdateTransportationLanesAccountIdMutationFn = Apollo.MutationFunction<
  UpdateTransportationLanesAccountIdMutation,
  UpdateTransportationLanesAccountIdMutationVariables
>;

/**
 * __useUpdateTransportationLanesAccountIdMutation__
 *
 * To run a mutation, you first call `useUpdateTransportationLanesAccountIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransportationLanesAccountIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransportationLanesAccountIdMutation, { data, loading, error }] = useUpdateTransportationLanesAccountIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransportationLanesAccountIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransportationLanesAccountIdMutation,
    UpdateTransportationLanesAccountIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTransportationLanesAccountIdMutation,
    UpdateTransportationLanesAccountIdMutationVariables
  >(UpdateTransportationLanesAccountIdDocument, options);
}
export type UpdateTransportationLanesAccountIdMutationHookResult = ReturnType<
  typeof useUpdateTransportationLanesAccountIdMutation
>;
export type UpdateTransportationLanesAccountIdMutationResult =
  Apollo.MutationResult<UpdateTransportationLanesAccountIdMutation>;
export type UpdateTransportationLanesAccountIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTransportationLanesAccountIdMutation,
  UpdateTransportationLanesAccountIdMutationVariables
>;
export const DeleteDealsDocument = gql`
  mutation DeleteDeals($input: [ID!]!) {
    deleteDeals(input: $input)
  }
`;
export type DeleteDealsMutationFn = Apollo.MutationFunction<
  DeleteDealsMutation,
  DeleteDealsMutationVariables
>;

/**
 * __useDeleteDealsMutation__
 *
 * To run a mutation, you first call `useDeleteDealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealsMutation, { data, loading, error }] = useDeleteDealsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDealsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDealsMutation, DeleteDealsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDealsMutation, DeleteDealsMutationVariables>(
    DeleteDealsDocument,
    options,
  );
}
export type DeleteDealsMutationHookResult = ReturnType<typeof useDeleteDealsMutation>;
export type DeleteDealsMutationResult = Apollo.MutationResult<DeleteDealsMutation>;
export type DeleteDealsMutationOptions = Apollo.BaseMutationOptions<
  DeleteDealsMutation,
  DeleteDealsMutationVariables
>;
export const RescindDealsDocument = gql`
  mutation RescindDeals($input: [ID!]!) {
    rescindDeals(input: $input)
  }
`;
export type RescindDealsMutationFn = Apollo.MutationFunction<
  RescindDealsMutation,
  RescindDealsMutationVariables
>;

/**
 * __useRescindDealsMutation__
 *
 * To run a mutation, you first call `useRescindDealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescindDealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescindDealsMutation, { data, loading, error }] = useRescindDealsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescindDealsMutation(
  baseOptions?: Apollo.MutationHookOptions<RescindDealsMutation, RescindDealsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RescindDealsMutation, RescindDealsMutationVariables>(
    RescindDealsDocument,
    options,
  );
}
export type RescindDealsMutationHookResult = ReturnType<typeof useRescindDealsMutation>;
export type RescindDealsMutationResult = Apollo.MutationResult<RescindDealsMutation>;
export type RescindDealsMutationOptions = Apollo.BaseMutationOptions<
  RescindDealsMutation,
  RescindDealsMutationVariables
>;
export const AlcoholTypesDocument = gql`
  query AlcoholTypes {
    alcoholTypes {
      id
      name
      volumes {
        id
        volume
      }
    }
  }
`;

/**
 * __useAlcoholTypesQuery__
 *
 * To run a query within a React component, call `useAlcoholTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlcoholTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlcoholTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAlcoholTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<AlcoholTypesQuery, AlcoholTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AlcoholTypesQuery, AlcoholTypesQueryVariables>(
    AlcoholTypesDocument,
    options,
  );
}
export function useAlcoholTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AlcoholTypesQuery, AlcoholTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AlcoholTypesQuery, AlcoholTypesQueryVariables>(
    AlcoholTypesDocument,
    options,
  );
}
export type AlcoholTypesQueryHookResult = ReturnType<typeof useAlcoholTypesQuery>;
export type AlcoholTypesLazyQueryHookResult = ReturnType<typeof useAlcoholTypesLazyQuery>;
export type AlcoholTypesQueryResult = Apollo.QueryResult<
  AlcoholTypesQuery,
  AlcoholTypesQueryVariables
>;
export const CapabilitiesDocument = gql`
  query Capabilities {
    capabilities {
      id
      name
    }
  }
`;

/**
 * __useCapabilitiesQuery__
 *
 * To run a query within a React component, call `useCapabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCapabilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<CapabilitiesQuery, CapabilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CapabilitiesQuery, CapabilitiesQueryVariables>(
    CapabilitiesDocument,
    options,
  );
}
export function useCapabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CapabilitiesQuery, CapabilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CapabilitiesQuery, CapabilitiesQueryVariables>(
    CapabilitiesDocument,
    options,
  );
}
export type CapabilitiesQueryHookResult = ReturnType<typeof useCapabilitiesQuery>;
export type CapabilitiesLazyQueryHookResult = ReturnType<typeof useCapabilitiesLazyQuery>;
export type CapabilitiesQueryResult = Apollo.QueryResult<
  CapabilitiesQuery,
  CapabilitiesQueryVariables
>;
export const CurrenciesDocument = gql`
  query Currencies {
    currencies {
      id
      code
    }
  }
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
}
export function useCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(
    CurrenciesDocument,
    options,
  );
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const CustomAssortmentsDocument = gql`
  query CustomAssortments {
    customAssortments {
      id
      name
    }
  }
`;

/**
 * __useCustomAssortmentsQuery__
 *
 * To run a query within a React component, call `useCustomAssortmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomAssortmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomAssortmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomAssortmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomAssortmentsQuery, CustomAssortmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomAssortmentsQuery, CustomAssortmentsQueryVariables>(
    CustomAssortmentsDocument,
    options,
  );
}
export function useCustomAssortmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomAssortmentsQuery,
    CustomAssortmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomAssortmentsQuery, CustomAssortmentsQueryVariables>(
    CustomAssortmentsDocument,
    options,
  );
}
export type CustomAssortmentsQueryHookResult = ReturnType<typeof useCustomAssortmentsQuery>;
export type CustomAssortmentsLazyQueryHookResult = ReturnType<typeof useCustomAssortmentsLazyQuery>;
export type CustomAssortmentsQueryResult = Apollo.QueryResult<
  CustomAssortmentsQuery,
  CustomAssortmentsQueryVariables
>;
export const ItemsOptionsDocument = gql`
  query ItemsOptions($pagination: Pagination!, $filter: ItemFilter!) {
    items(pagination: $pagination, filter: $filter) {
      items {
        id
        name
        i18n {
          name {
            enUS
            enGB
            enCA
            frFR
            frCA
            deDE
            esES
          }
        }
      }
    }
  }
`;

/**
 * __useItemsOptionsQuery__
 *
 * To run a query within a React component, call `useItemsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsOptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemsOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<ItemsOptionsQuery, ItemsOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemsOptionsQuery, ItemsOptionsQueryVariables>(
    ItemsOptionsDocument,
    options,
  );
}
export function useItemsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemsOptionsQuery, ItemsOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemsOptionsQuery, ItemsOptionsQueryVariables>(
    ItemsOptionsDocument,
    options,
  );
}
export type ItemsOptionsQueryHookResult = ReturnType<typeof useItemsOptionsQuery>;
export type ItemsOptionsLazyQueryHookResult = ReturnType<typeof useItemsOptionsLazyQuery>;
export type ItemsOptionsQueryResult = Apollo.QueryResult<
  ItemsOptionsQuery,
  ItemsOptionsQueryVariables
>;
export const LocationAutocompleteOptionsDocument = gql`
  query LocationAutocompleteOptions($pagination: Pagination!, $filter: LocationFilter!) {
    locations(pagination: $pagination, filter: $filter) {
      locations {
        name
        id
      }
    }
  }
`;

/**
 * __useLocationAutocompleteOptionsQuery__
 *
 * To run a query within a React component, call `useLocationAutocompleteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationAutocompleteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationAutocompleteOptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationAutocompleteOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LocationAutocompleteOptionsQuery,
    LocationAutocompleteOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LocationAutocompleteOptionsQuery,
    LocationAutocompleteOptionsQueryVariables
  >(LocationAutocompleteOptionsDocument, options);
}
export function useLocationAutocompleteOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationAutocompleteOptionsQuery,
    LocationAutocompleteOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LocationAutocompleteOptionsQuery,
    LocationAutocompleteOptionsQueryVariables
  >(LocationAutocompleteOptionsDocument, options);
}
export type LocationAutocompleteOptionsQueryHookResult = ReturnType<
  typeof useLocationAutocompleteOptionsQuery
>;
export type LocationAutocompleteOptionsLazyQueryHookResult = ReturnType<
  typeof useLocationAutocompleteOptionsLazyQuery
>;
export type LocationAutocompleteOptionsQueryResult = Apollo.QueryResult<
  LocationAutocompleteOptionsQuery,
  LocationAutocompleteOptionsQueryVariables
>;
export const NationalAssortmentsDocument = gql`
  query NationalAssortments {
    nationalAssortments {
      id
      name
    }
  }
`;

/**
 * __useNationalAssortmentsQuery__
 *
 * To run a query within a React component, call `useNationalAssortmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalAssortmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalAssortmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNationalAssortmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NationalAssortmentsQuery,
    NationalAssortmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NationalAssortmentsQuery, NationalAssortmentsQueryVariables>(
    NationalAssortmentsDocument,
    options,
  );
}
export function useNationalAssortmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NationalAssortmentsQuery,
    NationalAssortmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NationalAssortmentsQuery, NationalAssortmentsQueryVariables>(
    NationalAssortmentsDocument,
    options,
  );
}
export type NationalAssortmentsQueryHookResult = ReturnType<typeof useNationalAssortmentsQuery>;
export type NationalAssortmentsLazyQueryHookResult = ReturnType<
  typeof useNationalAssortmentsLazyQuery
>;
export type NationalAssortmentsQueryResult = Apollo.QueryResult<
  NationalAssortmentsQuery,
  NationalAssortmentsQueryVariables
>;
export const OpcosDocument = gql`
  query Opcos($pagination: Pagination!, $filter: OpcoFilter!) {
    opcos(pagination: $pagination, filter: $filter) {
      opcos {
        name
        id
        stateProvinceRegion
        buyer
      }
    }
  }
`;

/**
 * __useOpcosQuery__
 *
 * To run a query within a React component, call `useOpcosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpcosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpcosQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOpcosQuery(
  baseOptions: Apollo.QueryHookOptions<OpcosQuery, OpcosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpcosQuery, OpcosQueryVariables>(OpcosDocument, options);
}
export function useOpcosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpcosQuery, OpcosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpcosQuery, OpcosQueryVariables>(OpcosDocument, options);
}
export type OpcosQueryHookResult = ReturnType<typeof useOpcosQuery>;
export type OpcosLazyQueryHookResult = ReturnType<typeof useOpcosLazyQuery>;
export type OpcosQueryResult = Apollo.QueryResult<OpcosQuery, OpcosQueryVariables>;
export const PackageTypesDocument = gql`
  query PackageTypes {
    packageTypes {
      id
      name
    }
  }
`;

/**
 * __usePackageTypesQuery__
 *
 * To run a query within a React component, call `usePackageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePackageTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<PackageTypesQuery, PackageTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PackageTypesQuery, PackageTypesQueryVariables>(
    PackageTypesDocument,
    options,
  );
}
export function usePackageTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PackageTypesQuery, PackageTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PackageTypesQuery, PackageTypesQueryVariables>(
    PackageTypesDocument,
    options,
  );
}
export type PackageTypesQueryHookResult = ReturnType<typeof usePackageTypesQuery>;
export type PackageTypesLazyQueryHookResult = ReturnType<typeof usePackageTypesLazyQuery>;
export type PackageTypesQueryResult = Apollo.QueryResult<
  PackageTypesQuery,
  PackageTypesQueryVariables
>;
export const SupplierAutocompleteOptionsDocument = gql`
  query SupplierAutocompleteOptions($pagination: Pagination!, $filter: SupplierFilter!) {
    suppliers(pagination: $pagination, filter: $filter) {
      suppliers {
        id
        name
      }
    }
  }
`;

/**
 * __useSupplierAutocompleteOptionsQuery__
 *
 * To run a query within a React component, call `useSupplierAutocompleteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierAutocompleteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierAutocompleteOptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSupplierAutocompleteOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SupplierAutocompleteOptionsQuery,
    SupplierAutocompleteOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SupplierAutocompleteOptionsQuery,
    SupplierAutocompleteOptionsQueryVariables
  >(SupplierAutocompleteOptionsDocument, options);
}
export function useSupplierAutocompleteOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierAutocompleteOptionsQuery,
    SupplierAutocompleteOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SupplierAutocompleteOptionsQuery,
    SupplierAutocompleteOptionsQueryVariables
  >(SupplierAutocompleteOptionsDocument, options);
}
export type SupplierAutocompleteOptionsQueryHookResult = ReturnType<
  typeof useSupplierAutocompleteOptionsQuery
>;
export type SupplierAutocompleteOptionsLazyQueryHookResult = ReturnType<
  typeof useSupplierAutocompleteOptionsLazyQuery
>;
export type SupplierAutocompleteOptionsQueryResult = Apollo.QueryResult<
  SupplierAutocompleteOptionsQuery,
  SupplierAutocompleteOptionsQueryVariables
>;
export const SystemOfMeasuresDocument = gql`
  query SystemOfMeasures {
    systemOfMeasures {
      id
      name
    }
  }
`;

/**
 * __useSystemOfMeasuresQuery__
 *
 * To run a query within a React component, call `useSystemOfMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemOfMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemOfMeasuresQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemOfMeasuresQuery(
  baseOptions?: Apollo.QueryHookOptions<SystemOfMeasuresQuery, SystemOfMeasuresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemOfMeasuresQuery, SystemOfMeasuresQueryVariables>(
    SystemOfMeasuresDocument,
    options,
  );
}
export function useSystemOfMeasuresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SystemOfMeasuresQuery, SystemOfMeasuresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SystemOfMeasuresQuery, SystemOfMeasuresQueryVariables>(
    SystemOfMeasuresDocument,
    options,
  );
}
export type SystemOfMeasuresQueryHookResult = ReturnType<typeof useSystemOfMeasuresQuery>;
export type SystemOfMeasuresLazyQueryHookResult = ReturnType<typeof useSystemOfMeasuresLazyQuery>;
export type SystemOfMeasuresQueryResult = Apollo.QueryResult<
  SystemOfMeasuresQuery,
  SystemOfMeasuresQueryVariables
>;
export const SystemStatusDocument = gql`
  query SystemStatus {
    getSystemStatus
  }
`;

/**
 * __useSystemStatusQuery__
 *
 * To run a query within a React component, call `useSystemStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<SystemStatusQuery, SystemStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemStatusQuery, SystemStatusQueryVariables>(
    SystemStatusDocument,
    options,
  );
}
export function useSystemStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SystemStatusQuery, SystemStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SystemStatusQuery, SystemStatusQueryVariables>(
    SystemStatusDocument,
    options,
  );
}
export type SystemStatusQueryHookResult = ReturnType<typeof useSystemStatusQuery>;
export type SystemStatusLazyQueryHookResult = ReturnType<typeof useSystemStatusLazyQuery>;
export type SystemStatusQueryResult = Apollo.QueryResult<
  SystemStatusQuery,
  SystemStatusQueryVariables
>;
export const TobaccoTypesDocument = gql`
  query TobaccoTypes {
    tobaccoTypes {
      id
      name
    }
  }
`;

/**
 * __useTobaccoTypesQuery__
 *
 * To run a query within a React component, call `useTobaccoTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTobaccoTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTobaccoTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTobaccoTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<TobaccoTypesQuery, TobaccoTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TobaccoTypesQuery, TobaccoTypesQueryVariables>(
    TobaccoTypesDocument,
    options,
  );
}
export function useTobaccoTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TobaccoTypesQuery, TobaccoTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TobaccoTypesQuery, TobaccoTypesQueryVariables>(
    TobaccoTypesDocument,
    options,
  );
}
export type TobaccoTypesQueryHookResult = ReturnType<typeof useTobaccoTypesQuery>;
export type TobaccoTypesLazyQueryHookResult = ReturnType<typeof useTobaccoTypesLazyQuery>;
export type TobaccoTypesQueryResult = Apollo.QueryResult<
  TobaccoTypesQuery,
  TobaccoTypesQueryVariables
>;
export const UnprocessedRecordsResultDocument = gql`
  query UnprocessedRecordsResult {
    unprocessedRecords {
      itemsCount
      locationsCount
      supplierAssortmentsOpcosCount
      supplierAssortmentItemsCount
      transportationLaneSourcesCount
      transportationLaneDestinationsCount
      destinationAssortmentsCount
      itemSourcesCount
      sourceDestinationsCount
    }
  }
`;

/**
 * __useUnprocessedRecordsResultQuery__
 *
 * To run a query within a React component, call `useUnprocessedRecordsResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnprocessedRecordsResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnprocessedRecordsResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnprocessedRecordsResultQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnprocessedRecordsResultQuery,
    UnprocessedRecordsResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnprocessedRecordsResultQuery, UnprocessedRecordsResultQueryVariables>(
    UnprocessedRecordsResultDocument,
    options,
  );
}
export function useUnprocessedRecordsResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnprocessedRecordsResultQuery,
    UnprocessedRecordsResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnprocessedRecordsResultQuery, UnprocessedRecordsResultQueryVariables>(
    UnprocessedRecordsResultDocument,
    options,
  );
}
export type UnprocessedRecordsResultQueryHookResult = ReturnType<
  typeof useUnprocessedRecordsResultQuery
>;
export type UnprocessedRecordsResultLazyQueryHookResult = ReturnType<
  typeof useUnprocessedRecordsResultLazyQuery
>;
export type UnprocessedRecordsResultQueryResult = Apollo.QueryResult<
  UnprocessedRecordsResultQuery,
  UnprocessedRecordsResultQueryVariables
>;
export const UsersDocument = gql`
  query Users {
    users {
      displayName
      email
      id
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
