import { useMemo } from 'react';

import {
  FactoryCellInput,
  FactoryCellSelect,
  FactoryCellSelectBinary,
} from 'shared/components/DataGrid';
import { inputTypes } from 'shared/components/Input/constants';
import { isReadOnlyRole } from 'shared/utils';
import { PackingConstraintType } from 'shared/types';
import { packingConstraintOptions } from 'pages/Item/constants';

import { constants } from '../constants';
import { ItemExtraSubClassDefaultRow, UseColumnsArgs, UseColumnsResult } from '../types';

export const useColumns = ({ role }: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const isReadOnly = isReadOnlyRole(role);
    const isReadOnlyFunc = () => isReadOnly;

    return [
      {
        Header: constants.download.columns.headers.department.displayName,
        accessor: 'department',
      },
      {
        Header: constants.download.columns.headers.class.displayName,
        accessor: 'class',
      },
      {
        Header: constants.download.columns.headers.subClass.displayName,
        accessor: 'subClass',
      },
      {
        Header: constants.download.columns.headers.pullByDate.displayName,
        accessor: 'pullByDate',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: constants.download.columns.headers.markdownDays.displayName,
        accessor: 'markdownDays',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: constants.download.columns.headers.captureExpiry.displayName,
        accessor: 'captureExpiry',
        Cell: FactoryCellSelectBinary({ isDisabled: isReadOnlyFunc }),
      },
      {
        Header: constants.download.columns.headers.acceptableShelfLife.displayName,
        accessor: 'acceptableShelfLife',
        Cell: FactoryCellInput({ type: inputTypes.Number, isDisabled: isReadOnlyFunc }),
      },
      {
        Header: constants.download.columns.headers.packingConstraint.displayName,
        accessor: 'packingConstraint',
        Cell: FactoryCellSelect<ItemExtraSubClassDefaultRow, string, PackingConstraintType>({
          options: packingConstraintOptions.map(option => ({
            label: option.label,
            value: option.label,
          })),
          isDisabled: isReadOnlyFunc,
        }),
      },
    ];
  }, [role]);
